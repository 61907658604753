@mixin animatableUnderline($color: var(--color-neutral-10)) {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color;
    width: 0;
    height: 1px;
    transition: width ease-in-out 0.2s, border-color ease-in-out 0.1s;
  }
}

@mixin linkUnderline($color: var(--color-neutral-10)) {
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-thickness: 1px;
  // position: relative;
  // display: inline-block;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   background: $color;
  //   width: 100%;
  //   height: 1px;
  //   transition: width ease-in-out 0.2s, border-color ease-in-out 0.1s;
  // }
}

@mixin hideVisually() {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.icon-row {
  position: relative;
  @include get-grid;

  &--has-background {
    background-color: var(--color-neutral-20);
    padding: get-space(m) get-space(s) get-space(s);

    @include mq(l) {
      padding: get-space(l) 0 get-space(s);
    }
  }

  &__headline {
    grid-column: 1 / span 12;
    margin-bottom: get-space(xs);

    @include mq(l) {
      grid-column: 3 / span 8;
      text-align: center;
      margin-bottom: get-space(s);
    }
  }

  &__icons {
    position: relative;
    grid-column: 1 / span 12;
    display: flex;
    flex-wrap: wrap;

    @include mq(l) {
      justify-content: center;
    }
  }

  &__icon {
    padding: get-space(xxs) get-space(xs) get-space(xs) 0;

    @include mq(l) {
      padding: get-space(s);
      margin: 0 get-space(xs);
    }
  }

  &__image {
    img {
      width: 20px;
      height: 20px;
    }
  }
}

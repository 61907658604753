.contact {
  &--has-grid {
    @include get-grid;
  }

  &--has-grid &__headline {
    margin-bottom: get-space(s);
    grid-column: 1 / span 12;
  }

  &__headline--align-center {
    @include mq(s) {
      text-align: center;
    }
  }

  &--has-grid &__wrapper {
    grid-column: 1 / span 12;
    @include grid-flex();

    @include mq(s) {
      justify-content: center;
    }
  }

  &--has-grid &__contact {
    margin-bottom: get-space(m);

    // Susy grid width
    @include use-susy-span(
      'width',
      (
        s: 6 narrow,
        xl: 4 narrow
      ),
      (12)
    );

    @include get-grid(
      (
        default: 12,
        s: 6,
        xl: 4
      )
    );

    @include mq(s) {
      grid-template-rows: auto 1fr;
      margin-bottom: get-space(m);
    }

    @include mq(xl) {
      padding-right: get-space(s);
    }

    // desktop layout
    &:nth-child(3n + 1) {
      @include use-susy-gutter('margin-right', (xl));
    }

    &:nth-child(3n + 2) {
      @include use-susy-gutter('margin-right', (xl));
    }

    // &:nth-child(3n + 3) {
    // }

    // small-desktop styles
    &:nth-child(2n + 1) {
      &:not(:last-child) {
        @include mq(s) {
          @include mq($until: xl) {
            padding-right: get-space(s);
          }
        }

        @include mq($until: xl) {
          @include use-susy-gutter('margin-right', (m));
        }
      }
    }
  }

  &__image {
    position: relative;
    z-index: 1;
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-bottom: get-space(s);
    }
  }

  &__image-placeholder {
    display: none;

    @include mq(s) {
      display: block;
    }
  }

  &--has-grid &__image {
    grid-column: 1 / span 5;
    margin-bottom: 0;

    @include mq(s) {
      grid-column: 1 / span 2;
    }

    @include mq(l) {
      grid-column: 1 / span 2;
      margin-right: get-space(xs);
      margin-bottom: get-space(l);
    }

    @include mq(xl) {
      margin-right: calc(#{get-space(l)} + #{get-space(s)});
      grid-column: 1 / span 2;
    }
  }

  &__info {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--color-neutral-20);
    padding: get-space(s) get-space(s) 32px get-space(s);

    @include mq(l) {
      padding: get-space(s) get-space(s) 32px get-space(s);
    }
  }

  &--has-grid &__info {
    margin-top: calc(-1 * #{get-space(m)});
    padding-top: calc(#{get-space(m)} + #{get-space(s)});
    grid-column: 1 / span 12;
    min-height: 185px;
    height: fit-content;

    @include mq(s) {
      padding-top: calc(#{get-space(m)} + #{get-space(xs)});
      grid-column: 2 / span 5;
    }

    @include mq(l) {
      margin-top: get-space(m);
      padding-top: get-space(s);
      margin-left: calc(-1 * #{get-space(l)});
      padding-left: calc(#{get-space(m) + get-space(xxs)});
      grid-column: 3 / span 4;
    }

    @include mq(xl) {
      margin-left: calc(-1 * #{get-space(xxl)} - #{get-space(s)});
      padding-left: calc(#{get-space(m)} + #{get-space(s)});
      grid-column: 3 / span 2;
    }
  }

  &--has-grid &__image--placeholder + &__info {
    @include mq($until: s) {
      margin-top: 0;
      padding: get-space(s);
    }
  }

  // mobile styles for info
  &--has-grid &__contact:nth-child(2n + 1) &__info {
    @include mq($until: s) {
      margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
      padding-right: get-space(l);
    }
  }

  &--has-grid &__contact:nth-child(2n + 2) &__info {
    @include mq($until: s) {
      margin-left: calc(-1 * #{map-get($containerPadding, 'default')});
      padding-left: get-space(l);
    }
  }

  &__title--has-vita {
    padding-right: get-space(xs);

    @include mq(s) {
      padding-right: get-space(m);
    }
  }

  &__upper:not(:last-child) {
    margin-bottom: get-space(xs);
  }

  &__vita-btn {
    padding: 0;
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: get-space(xs);
    top: get-space(xs);

    svg {
      width: get-space('m');
      height: get-space('m');
    }
  }

  &__p {
    margin-top: get-space(xs);
  }

  &__phone {
    display: block;
  }

  &__phone + &__email {
    margin-top: 3px;
  }
}

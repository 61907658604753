.list {
  @include get-grid;

  &--has-background {
    padding: get-space(l) 0;
    background-color: var(--color-neutral-20);

    @include mq(l) {
      padding: get-space(xl) 0;
    }

    &.list--has-image {
      @include mq($until: l) {
        padding-top: 0;
      }
    }
  }

  &__image {
    margin-bottom: get-space(s);
    grid-column: 1 / span 12;

    @include mq(l) {
      margin-bottom: 0;
      grid-column: 1 / span 6;
      grid-row: 1 / span 3;
    }
  }

  &__image--landscape {
    margin-top: get-space(s);
    position: relative;
    right: map-get($containerPadding, 'default');

    @include mq(l) {
      right: 0;
      margin: 0 50px;
    }
  }

  &__image--portrait {
    margin-top: calc(-1 * #{get-space(s)});
    @include get-grid(
      (
        default: 12,
        l: 6
      )
    );

    @include mq(l) {
      margin-top: 0;
    }

    .image {
      grid-column: 3 / span 8;
      @include mq(l) {
        grid-column: 2 / span 4;
      }
    }
  }

  &__image--transparent {
    margin-top: calc(-1 * #{get-space(m)});
    margin-right: get-space(xs);
    margin-left: get-space(xs);

    @include mq(l) {
      margin-top: calc(-1 * #{get-space(l)});
      margin-right: get-space(s);
      margin-left: get-space(s);
    }
  }

  &__headline {
    margin-bottom: get-space(s);
    grid-column: 2 / span 10;

    @include mq(l) {
      text-align: center;
      margin-bottom: get-space(l);
      grid-column: 3 / span 8;
    }
  }

  &--has-image &__headline {
    @include mq(l) {
      margin-bottom: get-space(m);
      text-align: left;
      grid-column: 7 / span 5;
    }
  }

  &__content {
    grid-column: 2 / span 10;
  }

  &--has-image &__content {
    @include mq(l) {
      grid-column: 7 / span 4;
    }
  }

  &__ul,
  &__ol {
    @include mq(l) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &--has-image &__ul,
  &--has-image &__ol {
    @include mq(l) {
      display: block;
    }
  }

  &__item {
    position: relative;

    @include mq(l) {
      width: 33%;
      padding: 0 get-space(xs);
      flex-shrink: 0;
    }
  }

  &--has-image &__item {
    @include mq(l) {
      width: auto;
      padding: 0;
    }
  }

  &__ul &__item {
    display: flex;
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-bottom: get-space(s);
    }
  }

  &__ul &__item-text {
    // to align with icon

    margin-top: 2px;

    @include mq(l) {
      margin-top: 3px;
    }
  }

  &__ol &__item {
    margin-bottom: get-space(s);
    counter-increment: item;

    &::before {
      display: block;
      @include text-type-headline-2;

      font-weight: 700;
      color: var(--color-primary);
      content: counters(item, '') '.';
      margin-bottom: get-space(xxs);
    }

    ol li {
      &:before {
        content: counter(item) '.';
      }
    }
  }

  &__ul &__item,
  &__ol &__item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // align number left on desktop with image
  &--has-image &__ol &__item {
    .list__item-text {
      @include mq(l) {
        padding-top: 10px;
        margin-left: get-space(l);
      }
    }

    &::before {
      @include mq(l) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__check-icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-right: get-space(xs);
    color: var(--color-primary);

    @include mq(l) {
      width: 36px;
      height: 36px;
    }
  }

  &__item-headline {
    margin-bottom: get-space(xxs);
  }

  &__item-copy.richtext {
    font-weight: 400;
  }

  &__cta {
    margin-top: get-space(m);
    display: flex;

    @include mq(l) {
      margin-top: get-space(s);
      justify-content: center;
    }
  }

  &--has-image &__cta {
    @include mq(l) {
      margin-top: get-space(l);
      justify-content: left;
    }
  }
}

.video {
  position: relative;
  z-index: get-z-index('video');
  background-color: var(--color-neutral-20);

  @media print {
    display: none;
  }

  &__poster {
    left: 0;
    position: absolute;
    top: 0;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0.6;
      background: var(--color-neutral);
      z-index: get-z-index('poster', 'video');
      pointer-events: none;
    }

    &-image[style*='--ratio'] {
      left: 0;
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  &__embed_container,
  &__poster,
  &__button {
    padding-top: 56.26%;
    width: 100%;
  }

  &__iframe,
  &__player {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__embed_container {
    position: relative;
    z-index: get-z-index('embed-container', 'video');
  }

  &__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    z-index: get-z-index('button', 'video');

    &[hidden] {
      display: none;
    }

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      height: 40px;
      width: 28px;
    }

    &-text {
      color: var(--color-neutral-10);
      margin-top: get-space(xs);
      padding: 0 get-space(m);

      @include mq(m) {
        padding: 0 get-space(xl);
        margin-top: get-space(s);
      }
    }
  }

  &--is-playing &__button,
  &--is-playing &__poster {
    display: none;
  }
}

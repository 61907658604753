$metaDesktopHeight: 45px;

.navigation {
  position: relative;
  z-index: get-z-index('navigation');

  &__grid {
    @include get-grid;
  }
  // ---------- Links ----------
  &__link {
    line-height: 1;
    transition: color ease-in-out 0.1s;

    &--active {
      color: var(--color-primary);
    }

    &:hover,
    &:focus {
      color: var(--color-primary);
    }

    &:active {
      color: var(--color-accent-1);
    }
  }

  &__link--disabled.text {
    color: var(--color-neutral-40);
  }

  &__list > &__subitem {
    margin-top: get-space(m);
  }

  &__list > &__subitem ~ &__subitem {
    margin-top: 0;
  }

  // ---------- Menu Flyout ----------
  &__menu {
    position: fixed;
    z-index: get-z-index('menu', 'navigation');
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    transform: translateY(0);
    overflow-x: hidden;
    overflow-y: scroll;
    background: var(--color-neutral-10);
    height: 100vh;
    max-height: 100vh;
    transition: all 0.7s ease-in-out;
    -webkit-overflow-scrolling: touch;
    background-color: var(--color-neutral-10);

    &[aria-expanded='false'] {
      transform: translateY(-100%);
    }
  }

  &__overline {
    margin-bottom: 0;
    margin-left: 0;
    display: none;

    @include mq(m) {
      display: none;
      margin-left: 0;
      margin-bottom: get-space(xs);
    }
    @include mq(l) {
      display: block;
      margin-left: 0;
      margin-bottom: get-space(xs);
    }
  }

  &__menu-txt {
    display: block;
  }

  &__menu-scroller {
    height: calc(100% + 1px); // fix for iOS
    display: flex;
    flex-direction: column;
  }

  &__menu-inner {
    padding: get-space(m) 0;
    @include max-width;

    overflow: visible;
  }

  // ---------- Inital Navigation ----------
  &__initial {
    position: relative;
  }

  &__left {
    display: flex;
    align-items: center;
    grid-column: 1 / span 10;
    margin-left: calc(-1 * #{map-get($containerPadding, 'default')});

    @include mq(l) {
      align-items: flex-start;
      grid-column: 1 / span 5;
      grid-row: 1 / span 2;
      margin-left: calc(-1 * #{map-get($containerPadding, 'l')});
    }

    &--article,
    &--product {
      background: var(--color-primary);
    }
  }

  // sticky left styles
  &__sticky &__left {
    background: var(--color-primary);
    @include get-grid(
      (
        'default': 10,
        'l': 5
      )
    );
    @include mq(l) {
      grid-row: 1 / span 5;
    }
  }

  // hide logo in mobile content flyout
  &__menu &__left {
    display: none;

    @include mq(l) {
      display: block;
    }
  }

  // left area should fill area where meta nav is hidden
  &--product &__initial &__left {
    padding-top: 45px;
    margin-top: -45px;
  }

  &__right {
    grid-column: 1 / span 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 30px;
    padding-top: 50px;
    padding-bottom: get-space(s);

    @include mq(l) {
      grid-column: 6 / span 7;
      padding-top: get-space(xl);
    }
  }

  // ---------- Login Button
  &__login-btn {
    margin-right: get-space(s);
    height: 30px;
    display: flex;
    align-items: center;

    @include mq(l) {
      margin-right: get-space(s);
    }
  }

  // hide login-btn in initial & sticky  navigation for mobile
  &__sticky &__login-btn,
  &__initial &__login-btn {
    display: none;

    @include mq(l) {
      display: flex;
      justify-content: flex-end;
    }
  }

  // position menu-btn in initial navigation for mobile
  &__initial &__right {
    grid-column: 11 / span 2;

    @include mq(l) {
      grid-column: 6 / span 7;
    }
  }

  // grid for right block for mobile
  &__menu &__right {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 6 / span 7;
    }
  }

  // sticky right styles
  &__sticky &__right {
    padding: 0;
    align-items: center;
    grid-column: 11 / span 2;

    @include mq(l) {
      padding: get-space(s) 0;
      grid-column: 6 / span 7;
    }
  }

  // ---------- Logo
  &__logo {
    display: inline-block;
    margin-left: map-get($containerPadding, 'default');
    padding: get-space(s) get-space(s) get-space(m) 0;

    @include mq(m) {
      padding: get-space(m) get-space(s) get-space(l) 0;
    }

    @include mq(l) {
      margin-left: map-get($containerPadding, 'l');
      padding: get-space(l) get-space(m) get-space(xl) 0;
    }

    @include mq(xl) {
      padding: get-space(l) get-space(m) get-space(xl);
    }
  }

  &__logo-svg {
    width: 155px;
    height: 84px;

    @include mq(l) {
      width: 155px;
      height: 84px;
    }
  }

  &__sticky &__logo {
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-bottom: 0;
      padding: get-space(s) get-space(xs) get-space(s) 0;
    }
  }

  &__sticky &__logo-svg {
    fill: var(--color-neutral-10);
  }

  &--article &__logo-svg,
  &--product &__logo-svg {
    fill: var(--color-neutral-10);
  }

  // ---------- CTAs
  &__ctas {
    grid-template-rows: auto 1fr;
    @include max-width;

    overflow: visible;
  }

  // order ctas on top for mobile
  &__menu &__ctas {
    order: -1;

    @include mq(l) {
      order: 0;
    }
  }

  // ---------- Langauge Switch
  &__languages {
    position: relative;
    margin-right: get-space(s);
    display: flex;
    align-items: center;

    @include mq(l) {
      margin-right: get-space(s);
    }
  }

  // hide languages in initial & sticky  navigation for mobile
  &__sticky &__languages,
  &__initial &__languages {
    display: none;

    @include mq(l) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__menu-svg {
    width: 25px;
    height: 25px;
  }

  // ---------- Menu Button
  &__menu-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }
  }

  &__menu-btn &__menu-svg {
    display: none;
  }

  &__burger {
    width: 30px;
    height: 30px;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    @include mq(m) {
      flex-direction: row;
    }
  }

  &__txt-burger-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 5px;
  }

  &__burger-wrapper {
    height: 30px;
    width: 30px;
  }

  &__burger-brick {
    height: 2px;
    background: black;
    margin-top: 7px;
    transition: 0.3s ease-out;
  }

  &__burger-middle {
    transform: scaleX(0.9);
    transform-origin: right;
  }

  &__burger-brick:first-child {
    transform: scaleX(0.5);
    transform-origin: right;
  }

  &__burger-brick:last-child {
    transform: scaleX(0.7);
    transform-origin: right;
  }

  &__burger:hover &__burger-brick {
    background: var(--color-primary);
  }

  &__burger:hover &__burger-brick:first-child {
    transform: scaleX(1);
    transform-origin: right;
  }

  &__burger:hover &__burger-brick:last-child {
    transform: scaleX(1);
    transform-origin: right;
  }

  &__burger:hover .navigation__burger-middle {
    transform: scaleX(1);
    transform-origin: right;
  }

  &__initial &__burger,
  &__sticky &__burger {
    display: flex;
    width: fit-content;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }

  &__menu &__close {
    display: block;
  }

  // ----------  Primary Navigation ----------
  &__primary {
    // set data attribute für active level
    // set data attribute to active button -> add custom element for back button

    grid-column: 1 / span 12;

    @include mq(l) {
      margin-top: get-space(l);
      padding: 0 0 get-space(l);
    }

    @include mq(xl) {
      padding: 0 0 get-space(l) get-space(m);
    }
  }

  &__primary-inner {
    @include mq(l) {
      display: flex;
      padding-top: get-space(m);
      border-top: 1px solid var(--color-neutral-20);
    }
  }

  &__item-lock-svg {
    width: 20px;
    height: 20px;
    display: inline;
    margin-left: -25px;
    margin-top: -5px;
  }

  &__item-upright-svg {
    width: 20px;
    height: 20px;
    display: inline;
    margin-left: 5px;
    margin-top: -5px;
  }

  &__item-lock-svg-menu {
    width: 20px;
    height: 20px;
    display: inline;
  }

  &__back-btn {
    margin-bottom: get-space(xs);
    position: relative;
    display: none;
    width: calc(100% + (2 * #{map-get($containerPadding, 'default')}));
    left: calc(-1 * #{map-get($containerPadding, 'default')});
    border: 0 none;
    background: var(--color-neutral-10);
    text-align: left;
    padding: get-space(xs) calc(#{get-space(s)} + #{map-get($containerPadding, 'default')});
    color: var(--color-neutral);

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    svg {
      position: absolute;
      left: 10px;
    }

    @include mq(l) {
      display: none;
    }
  }

  &__primary[data-nav-level='2'] &__back-btn,
  &__primary[data-nav-level='3'] &__back-btn {
    display: block;
    @include mq(l) {
      display: none;
    }
  }

  &__list {
    opacity: 1;
    position: relative;
    left: 0%;
    visibility: visible;
    transition: opacity ease-in-out 0.3s, left ease-in-out 0.3s;

    &[hidden] {
      display: none;
      opacity: 0;
      position: absolute;
      left: -100px;
      visibility: hidden;
    }

    @include mq(l) {
      margin-right: get-space(m);
      width: 33%;
      background: var(--color-neutral-10);
    }

    button,
    a {
      position: relative;
      display: block;
      width: 96%;
      background: var(--color-neutral-10);
      text-align: left;
      padding: get-space(xs) 30px get-space(xs) get-space(s);

      @include mq(m) {
        width: 97%;
      }
      @include mq(l) {
        width: 100%;
        margin-right: 0;
        background: transparent;
        padding: get-space(xs) 0;
      }
    }

    &--level-1 {
      position: relative;
      z-index: get-z-index('level-1', 'navigation');
    }

    &--level-2 {
      position: relative;
      z-index: get-z-index('level-2', 'navigation');
    }

    &--level-3 {
      position: relative;
      z-index: get-z-index('level-3', 'navigation');

      @include mq(l) {
        margin-right: 0;
      }
    }
  }

  &__primary[data-nav-level='2'] &__list--level-1,
  &__primary[data-nav-level='3'] &__list--level-1,
  &__primary[data-nav-level='3'] &__list--level-2 {
    display: none;

    @include mq(l) {
      display: block;
    }
  }

  &__item {
    display: block;
    transition: transform ease-in-out 0.3s;
    margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
    margin-bottom: get-space(xxs);

    @include mq(l) {
      margin: 0;
    }

    &::before {
      display: none; // hide sanitze css
    }
  }

  &__item:hover {
    transform: translateX(10px);
  }

  &__item-btn {
    position: relative;

    span {
      margin-right: 30px;
    }

    //  for active path
    &[data-nav-btn-active] {
      color: var(--color-primary);
    }
  }

  &__item-btn-svg {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 1;
    transition: opacity ease-in-out 0.1s;
    fill: var(--color-primary) !important;

    @include mq(l) {
      right: 5px;
      opacity: 0;
    }
  }

  &__item-btn:hover &__item-btn-svg {
    opacity: 1;
  }

  &__item-btn[data-nav-btn-active] &__item-btn-svg {
    opacity: 1;
  }

  // ---------- Navigation Teaser ----------
  &__teaser {
    margin-top: get-space(m);
    padding: get-space(m);
    color: var(--color-neutral-10);
    position: relative;
    z-index: get-z-index('teaser', 'navigation');
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: width ease-in-out 0.1s;

    & > * {
      margin-bottom: get-space(xs);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &[hidden] {
      display: none;
    }

    &.theme--green,
    &.theme--orange {
      background: var(--color-primary);
    }

    @include mq(l) {
      margin-top: 0;
      width: 60%;
      width: 67%;
      padding: get-space(m) get-space(xxl);
    }
  }

  &__primary[data-nav-level='2'] &__teaser {
    @include mq(l) {
      width: 33%;
      padding: get-space(m);
    }
  }

  &__primary[data-nav-level='3'] &__teaser {
    @include mq(l) {
      display: none;
    }
  }

  &__teaser-label {
    @include animatableUnderline;
  }

  &__teaser-link {
    display: inline-block;
    cursor: pointer;
  }
  // ---------- Meta Navigation ----------
  &__meta {
    position: absolute;
    width: 100%;
    padding: get-space(s) 0 get-space(xs);

    @include mq(l) {
      padding: 0;
      padding-top: 10px;
      height: $metaDesktopHeight;
    }
  }

  &__meta-inner {
    @include max-width;

    @include mq(l) {
      padding: 0 calc(#{map-get($containerPadding, 'l')});
    }
  }

  // hide initial meta on mobile
  &__initial &__meta {
    display: none;

    @include mq(l) {
      display: block;
    }
  }

  // hide meta in menu for product pages
  &--product &__initial &__meta {
    display: none;

    @include mq(l) {
      display: block;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__meta-primary {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 1 / span 8;
    }
  }

  &__meta-secondary {
    grid-column: 1 / span 12;

    &:not(.navigation__meta-secondary--only) {
      @include mq(l) {
        grid-column: 9 / span 4;
      }
    }
  }

  // hide 1st secondray meta on mobile
  &__meta-primary + &__meta-secondary {
    display: none;

    @include mq(l) {
      display: block;
    }
  }

  &__meta-list {
    width: 100%;

    @include mq(l) {
      display: flex;
    }
  }

  &__meta-list &__link,
  &__meta-list &__meta-label {
    display: inline-block;
    padding-bottom: get-space(xs);
  }

  &__meta-primary &__meta-list &__link {
    @include mq(l) {
      margin-right: get-space(s);
      padding: get-space(xs) get-space(xs) get-space(xs) 0;
    }
  }

  &__meta-secondary &__meta-list {
    @include mq(l) {
      justify-content: flex-end;
    }
  }

  &__meta-secondary &__meta-list &__link,
  &__meta-secondary &__meta-list &__meta-label {
    @include mq(l) {
      margin-left: get-space(s);
      padding: get-space(xs) 0 get-space(xs) get-space(xs);
    }
  }

  &__meta-label-user {
    @include mq(l) {
      padding-top: 11px;
    }
  }

  &__meta-secondary &__meta-list &__meta-label {
    @include mq(l) {
      padding-top: 15px;
    }
  }

  &__meta-primary &__meta-list {
    li {
      &:first-child {
        @include mq(l) {
          padding-left: 0;
        }

        @include mq(xl) {
          padding-left: get-space(m);
        }
      }
    }
  }

  // 2nd meta (for mobile only)
  &__menu &__meta:last-of-type {
    display: block;

    .navigation__meta-list {
      display: flex;
      flex-wrap: wrap;

      li {
        padding-right: get-space(s);

        &:last-child {
          padding-right: 0;
        }
      }
    }

    // hide on desktop
    @include mq(l) {
      display: none;
    }
  }

  // underline for active meta link
  &__meta-link--active a {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: get-space(xs);
      width: 50px;
      height: 1px;
      background: var(--color-primary);
    }
  }

  &__meta-tooltips-container {
    display: none;

    @include mq(l) {
      display: block;
      position: absolute;
      z-index: get-z-index('tooltip', 'navigation');
      top: $metaDesktopHeight;
      padding: 0;
      height: 0;
      right: 0;
      left: 0;
      overflow: hidden;
      background: var(--color-neutral-20);
      transition: height ease-in-out 0.3s;
    }
  }

  &__meta-tooltips {
    grid-column: 1 / span 12;

    @include mq(xl) {
      padding-left: get-space(m);
    }
  }

  // ---------- Sticky ----------
  &__sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: var(--color-neutral-10);
    transform: translateY(-110%);
    transition: transform ease 0.3s;
    z-index: get-z-index('sticky', 'navigation');
  }

  &--is-sticky &__sticky {
    transform: translateY(0);
  }

  // ---------- Buttons ----------
  &__btn {
    cursor: pointer;
    border: 0 none;
    background: transparent;
    padding: 0;
    transition: color ease-in-out 0.1s;
    color: var(--color-neutral);

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    svg {
      transition: fill ease-in-out 0.1s;
    }

    &:hover,
    &:focus {
      color: var(--color-primary);

      svg {
        fill: var(--color-primary);
      }
    }

    &:active {
      color: var(--color-accent-1);

      svg {
        fill: var(--color-accent-1);
      }
    }
  }

  &--has-animation [data-animation-element='navigation__left--article'] {
    @media (prefers-reduced-motion: no-preference) {
      opacity: 0;
      transform: translate(-10px, 0px);
    }

    .navigation__logo {
      @media (prefers-reduced-motion: no-preference) {
        opacity: 0;
      }
    }
  }
}

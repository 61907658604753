.form {
  margin-top: get-space('s');
  margin-bottom: get-space('l');

  &--has-grid {
    @include get-grid;
  }

  &__form {
    > * {
      display: block;
      margin-bottom: get-space('s');
      position: relative;
    }
  }

  &--has-grid &__form {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }

  // checkbox margin should match checkbox group
  &__field--checkbox {
    margin-bottom: get-space('xs');

    &:last-of-type {
      margin-bottom: get-space('s');
    }
  }

  &__richtext.richtext {
    @include text-type-copy-2;

    display: block;
    margin-bottom: get-space('s');
  }

  &__phone {
    display: flex;
    flex-wrap: wrap;
  }

  &__field__phone {
    margin-right: get-space('xs');
  }

  &__field-phone-number-label {
    display: block;
    margin-bottom: get-space('xs');
  }

  &__field-phone-number {
    display: block;
    margin-bottom: get-space('xs');

    input.input__input-phone.form-error {
      border: 2px solid get-color('negative');
    }

    .error-message {
      opacity: 0;
      color: get-color('negative');
      @include text-type-small;

      margin-top: 5px;
      width: 100%;
    }

    .initial-message {
      @include text-type-small;

      margin-top: 5px;
      width: 100%;
    }
  }
}

// Colors for data series and points (pie chart)
$highcharts-chart-colors: get-asset-color('equities') get-asset-color('alt-invest') get-asset-color('fixed-income') get-asset-color('liquidity') !default;

// Colors for line chart series (line chart) - not defined by theme
$highcharts-line-colors: get-color('corporate') get-color('neutral', 40);

// Chart background, point stroke for markers and columns etc
$highcharts-background-color: var(--color-neutral-10) !default;

// Colored shades of blue by default
$highcharts-corporate-color: get-color('corporate') !default; // Corporate orange
$highcharts-primary-color: var(--color-primary) !default; // Primary color defined by theme (orange or green)
$highlights-accent-color-80: var(--color-accent-1-80) !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlights-accent-color-30: var(--color-neutral-30) !default; // Ticks and axis line.

.chart {
  &__loader {
    margin-top: get-space(l);
  }

  &__container[data-highcharts-chart] ~ &__loader {
    display: none;
  }

  // Footnotes
  &__notes {
    margin-bottom: get-space(m);

    @include mq(l) {
      margin-bottom: get-space(l);
    }
  }

  &[data-chart-type='donut'] &__notes {
    text-align: center;
  }

  &__note {
    margin-bottom: get-space(s);
  }

  // HIGHCHARTS THEME
  /* stylelint-disable */
  .highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0; /* #1072 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: $font-family-default;
    font-size: 12px;
    user-select: none;
  }
  .highcharts-root {
    display: block;
  }
  .highcharts-root text {
    stroke-width: 0;
  }
  .highcharts-strong {
    font-weight: bold;
  }
  .highcharts-emphasized {
    font-style: italic;
  }
  .highcharts-anchor {
    cursor: pointer;
  }
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-plot-border,
  .highcharts-plot-background {
    fill: none;
  }
  .highcharts-label-box {
    fill: none;
  }
  .highcharts-button-box {
    fill: inherit;
  }
  .highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: transparent;
    // stroke-width: 22;
    fill: none;
  }
  .highcharts-tracker-area {
    // fill: #e7e7e7;
    stroke-width: 0;
    // opacity: 0.1;
    // fill-opacity: 0.1;
  }

  .highcharts-series.highcharts-series-1.highcharts-arearange-series.highcharts-color-1 {
    fill: var(--color-primary-alpha20);
    fill-opacity: 0.1;
    stroke-width: 0;
    stroke: none;
  }

  .highcharts-markers.highcharts-series-1.highcharts-arearange-series.highcharts-color-1.highcharts-tracker {
    fill: var(--color-primary-30);
    stroke: var(--color-primary-30);
  }

  #arearange-marker-color span:before {
    background-color: var(--color-primary-30) !important;
  }

  /* Titles */
  .highcharts-title {
    @include text-type-copy-1;
    font-size: var(--copy-1-font-size) !important;
    font-weight: 700;
  }
  .highcharts-subtitle {
    fill: var(--color-neutral);
  }

  /* Axes */
  .highcharts-axis-line {
    fill: none;
    stroke: $highlights-accent-color-30;
  }
  .highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
  }
  .highcharts-axis-title {
    fill: var(--color-neutral);
  }
  .highcharts-axis-labels {
    @include text-type-small;
    fill: var(--color-neutral);
    cursor: default;
  }
  .highcharts-grid-line {
    fill: none;
    stroke: var(--color-neutral-30);
  }
  .highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0px;
  }

  .highcharts-tick {
    stroke: $highlights-accent-color-30;
  }
  .highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
  }
  .highcharts-minor-grid-line {
    stroke: var(--color-neutral-20);
  }
  .highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: var(--color-neutral-30);
  }
  .highcharts-crosshair-category {
    stroke: $highlights-accent-color-30;
    stroke-opacity: 0.25;
  }

  // bar overwrites for xaxis
  &[data-chart-type='bar'] .highcharts-xaxis-labels {
    width: 100%;

    span {
      width: 80% !important;
      white-space: normal !important;

      @include mq(m) {
        width: 90% !important;
      }
    }
  }

  /* Credits */
  // .highcharts-credits {
  //   cursor: pointer;
  //   fill: var(--color-neutral);
  //   font-size: 0.7em;
  //   transition: fill 250ms, font-size 250ms;
  // }
  // .highcharts-credits:hover {
  //   fill: black;
  //   font-size: 1em;
  // }

  /* Tooltip */

  .highcharts-tooltip {
    filter: none;
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;

    b {
      font-weight: bold;
    }
  }
  .highcharts-tooltip text {
    fill: var(--color-neutral);
  }
  .highcharts-tooltip .highcharts-header {
    // font-size: 0.85em;
  }
  .highcharts-tooltip-box {
    stroke-width: 0px;
    // stroke-width: 1px;
    fill: var(--color-neutral-20);
    // fill-opacity: 0.85;
  }

  // hide svg tooltip for bar charts
  &[data-chart-type='position'] svg .highcharts-tooltip,
  &[data-chart-type='bar'] svg .highcharts-tooltip {
    display: none;
  }

  // for bar/position charts overwrite styles for html tooltip
  &[data-chart-type='position'] div.highcharts-tooltip,
  &[data-chart-type='bar'] div.highcharts-tooltip {
    span {
      padding: 10px;
      background-color: var(--color-primary);
      color: var(--color-neutral-10) !important;
    }
  }

  .highcharts-tooltip-box .highcharts-label-box {
    fill: var(--color-neutral-20);
    // fill-opacity: 0.85;
  }
  div.highcharts-tooltip {
    // filter: none;
  }
  .highcharts-tooltip-label {
    // custom class
    z-index: 1000;
    display: flex;
    justify-content: space-between;

    span {
      padding-right: 10px;

      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin: 2px;
      }
    }
  }
  @for $i from 1 through length($highcharts-line-colors) {
    $color: nth($highcharts-line-colors, $i);

    .highcharts-tooltip-label-#{$i - 1} {
      & span:before {
        background-color: $color;
      }
    }
  }
  .highcharts-tooltip-series-custom {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 2px;
    background: transparent;
  }

  .highcharts-selection-marker {
    fill: $highlights-accent-color-80;
    fill-opacity: 0.25;
  }

  .highcharts-graph {
    fill: none;
    stroke-width: 4px;
    // stroke-linecap: round;
    // stroke-linejoin: round;
  }

  &[data-chart-type='stock'] .highcharts-graph {
    stroke-width: 4px;
  }

  &[data-chart-type='arearange'] .highcharts-graph {
    stroke-width: 3px;
  }

  .highcharts-arearange-series .highcharts-graph {
    stroke-width: 1px;
  }

  .highcharts-empty-series {
    stroke-width: 1px;
    fill: none;
    stroke: var(--color-neutral-30);
  }

  .highcharts-state-hover .highcharts-graph {
    stroke-width: 3;
  }

  .highcharts-point-inactive {
    opacity: 0.2;
    transition: opacity 50ms; /* quick in */
  }

  .highcharts-series-inactive {
    opacity: 0.2;
    transition: opacity 50ms; /* quick in */
  }

  // disable inactive state & opacity change
  &[data-chart-type='position'] .highcharts-columnrange-series {
    opacity: 1 !important;
    transition: none;
  }

  .highcharts-state-hover path {
    transition: stroke-width 50ms; /* quick in */
  }
  .highcharts-state-normal path {
    transition: stroke-width 250ms; /* slow out */
  }
  /* Legend hover affects points and series */
  g.highcharts-series,
  .highcharts-point,
  .highcharts-markers,
  .highcharts-data-labels {
    transition: opacity 250ms;
  }
  .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
  .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
  .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
  .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    // opacity: 0.2;
  }

  /* Series options */

  /* Default Donut colors */

  &[data-chart-type='donut']:not([data-chart-styled-mode-off]) {
    @for $i from 1 through length($highcharts-chart-colors) {
      $color: nth($highcharts-chart-colors, $i);

      .highcharts-color-type-#{$i - 1} {
        color: $color;
      }

      .highcharts-color-#{$i - 1} {
        fill: $color;
        stroke: $color;
      }
    }
  }

  // Overwrite for bars
  .highcharts-bar-series .highcharts-color-0 {
    fill: get-color('neutral', 20);
    stroke: get-color('neutral', 20);
  }

  // Overwrite for columnrange
  .highcharts-columnrange-series .highcharts-color-0 {
    fill: get-color('neutral', 20);
    stroke: get-color('neutral', 20);
  }

  /* Line colors */
  @for $i from 1 through length($highcharts-line-colors) {
    $color: nth($highcharts-line-colors, $i);
    .highcharts-line-series.highcharts-color-#{$i - 1} {
      fill: $color;
      stroke: $color;
    }
  }

  .highcharts-area {
    fill-opacity: 0.75;
    stroke-width: 0;
  }

  .highcharts-markers {
    stroke-width: 1px;
    stroke: $highcharts-background-color;

    // Removed to stop fixed HighChart marker colour
    // path {
    //   fill: inherit;
    // }
  }
  .highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
  .highcharts-a11y-marker-hidden {
    opacity: 0;
  }
  .highcharts-point {
    stroke-width: 1px;
  }
  .highcharts-dense-data .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold;
  }
  .highcharts-data-label-box {
    fill: none;
    stroke-width: 0;
  }
  .highcharts-data-label text,
  text.highcharts-data-label {
    fill: var(--color-neutral);
  }
  .highcharts-data-label-connector {
    fill: none;
  }
  .highcharts-data-label-hidden {
    pointer-events: none;
  }
  // halo for tooltip markers
  path.highcharts-halo {
    fill: var(--color-neutral-10) !important;
    stroke-width: 0px;
    stroke: inherit;
    filter: url(#drop-shadow-0);
  }
  .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
  .highcharts-markers .highcharts-point-select {
    fill: var(--color-neutral-30);
    stroke: var(--color-neutral);
  }
  .highcharts-column-series rect.highcharts-point {
    // rect to prevent stroke on 3D columns
    stroke: $highcharts-background-color;
  }
  .highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms;
  }
  .highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-column-series .highcharts-point {
    stroke-linejoin: round;
    rx: 0;
    ry: 0;
  }
  .highcharts-columnrange-series .highcharts-point {
    stroke-linejoin: round;
    rx: 0;
    ry: 0;
  }
  .highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $highcharts-background-color;
    rx: 0;
    ry: 0;
  }

  .highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }
  .highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $highcharts-background-color;
  }
  .highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }
  .highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
  }
  .highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $highcharts-background-color;
  }
  .highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }
  .highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
  }
  .highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
  }
  .highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: var(--color-neutral-30);
    transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
  }
  .highcharts-treemap-series .highcharts-point-hover {
    stroke: var(--color-neutral);
    transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
  }

  .highcharts-treemap-series .highcharts-above-level {
    display: none;
  }
  .highcharts-treemap-series .highcharts-internal-node {
    fill: none;
  }
  .highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer;
  }
  .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75;
  }

  .highcharts-vector-series .highcharts-point {
    fill: none;
    stroke-width: 2px;
  }

  .highcharts-windbarb-series .highcharts-point {
    fill: none;
    stroke-width: 2px;
  }

  .highcharts-lollipop-stem {
    stroke: var(--color-neutral);
  }

  .highcharts-focus-border {
    fill: none;
    stroke-width: 2px;
  }

  .highcharts-legend-item-hidden .highcharts-focus-border {
    fill: none !important;
  }

  /* Legend */
  .highcharts-legend-box {
    fill: none;
    stroke-width: 0;
  }

  .highcharts-legend-item > text,
  .highcharts-legend-item > span {
    // @include text-type-copy-1;
    font-family: $font-family-default !important;
    font-size: var(--copy-1-font-size) !important;
    font-weight: 400 !important;
    line-height: var(--copy-1-line-height) !important;
    fill: var(--color-neutral) !important;
    stroke-width: 0;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
  }

  &[data-chart-type='stock'] .highcharts-legend-item > span {
    margin-top: -2px !important;
  }
  &[data-chart-type='donut'] .highcharts-legend-item > span,
  &[data-chart-type='column'] .highcharts-legend-item > span {
    margin-top: -5px !important;
  }

  &:has([data-chart-styled-mode-off]) .highcharts-legend-item > rect {
    fill: inherit !important;
  }

  // .highcharts-legend-item:hover text {
  //   fill: var(--color-neutral);
  // }
  .highcharts-legend-item-hidden * {
    fill: var(--color-neutral-30) !important;
    stroke: var(--color-neutral-30) !important;
    transition: fill 250ms;
  }
  .highcharts-legend-nav-active {
    fill: $highcharts-corporate-color;
    cursor: pointer;
  }
  .highcharts-legend-nav-inactive {
    fill: var(--color-neutral-30);
  }
  circle.highcharts-legend-nav-active,
  circle.highcharts-legend-nav-inactive {
    /* tracker */
    fill: rgba(192, 192, 192, 0.0001);
  }
  .highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
  }
  // to create square legend symbols for stock
  .highcharts-legend-item .highcharts-graph {
    stroke-width: 14px;
  }

  // columnrange legend overwrites
  .highcharts-legend-item.highcharts-columnrange-series.highcharts-color-0 .highcharts-point {
    fill: get-color('neutral', 20);
  }
  // scatter legend overwrites
  .highcharts-legend-item.highcharts-scatter-series.highcharts-color-1 .highcharts-graph {
    fill: get-color('corporate');
    stroke-width: 0px;
    d: path('M7 2 L14 16 L 0 16'); // draw a tringle

    @include mq(m) {
      d: path('M8 0 L16 16 L 0 16');
    }
  }

  // .highcharts-legend-item.highcharts-scatter-series.highcharts-color-1.highcharts-series-1 {
  //   position: relative;
  //   &::before {
  //     content: 't';
  //     position: absolute;
  //     display: block;
  //   }
  // }

  /* Bubble legend */
  .highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5;
  }
  .highcharts-bubble-legend-connectors {
    stroke-width: 1;
  }
  .highcharts-bubble-legend-labels {
    fill: var(--color-neutral);
  }

  /* Loading */
  .highcharts-loading {
    position: absolute;
    background-color: $highcharts-background-color;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
  }
  .highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end;
  }
  .highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
  }

  /* Plot bands and polar pane backgrounds */
  .highcharts-plot-band,
  .highcharts-pane {
    fill: var(--color-neutral);
    fill-opacity: 0.05;
  }
  .highcharts-plot-line {
    fill: none;
    stroke: var(--color-neutral);
    stroke-width: 1px;
  }

  /* Highcharts More and modules */
  .highcharts-boxplot-box {
    fill: $highcharts-background-color;
  }
  .highcharts-boxplot-median {
    stroke-width: 2px;
  }
  .highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
  }
  .highcharts-errorbar-series .highcharts-point {
    stroke: var(--color-neutral);
  }
  .highcharts-gauge-series .highcharts-data-label-box {
    stroke: var(--color-neutral-30);
    stroke-width: 1px;
  }
  .highcharts-gauge-series .highcharts-dial {
    fill: var(--color-neutral);
    stroke-width: 0;
  }
  .highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
  }
  .highcharts-waterfall-series .highcharts-graph {
    stroke: var(--color-neutral);
    stroke-dasharray: 1, 3;
  }
  .highcharts-sankey-series .highcharts-point {
    stroke-width: 0;
  }
  .highcharts-sankey-series .highcharts-link {
    transition: fill 250ms, fill-opacity 250ms;
    fill-opacity: 0.5;
  }
  .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition: fill 50ms, fill-opacity 50ms;
    fill-opacity: 1;
  }
  .highcharts-venn-series .highcharts-point {
    fill-opacity: 0.75;
    stroke: var(--color-neutral-30);
    transition: stroke 250ms, fill-opacity 250ms;
  }
  .highcharts-venn-series .highcharts-point-hover {
    fill-opacity: 1;
    stroke: var(--color-neutral-30);
  }

  /* Highstock */
  .highcharts-navigator-mask-outside {
    fill-opacity: 0;
  }
  .highcharts-navigator-mask-inside {
    fill: var(--color-neutral-40); /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
  }
  .highcharts-navigator-outline {
    stroke: var(--color-neutral-30);
    fill: none;
  }
  .highcharts-navigator-handle {
    stroke: var(--color-neutral-30);
    fill: var(--color-neutral-20);
    cursor: ew-resize;
  }
  .highcharts-navigator-series {
    // fill: var(--color-neutral);
    stroke: $highcharts-corporate-color;
  }
  .highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px;
  }
  .highcharts-navigator-series .highcharts-area {
    // fill-opacity: 0.05;
    fill: none;
  }
  .highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
  }
  .highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: var(--color-neutral-30);
  }
  .highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: var(--color-neutral);
  }
  .highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
  }
  .highcharts-scrollbar-thumb {
    fill: var(--color-neutral-30);
    stroke: var(--color-neutral-30);
    stroke-width: 1px;
  }
  .highcharts-scrollbar-button {
    fill: var(--color-neutral-30);
    stroke: var(--color-neutral-30);
    stroke-width: 1px;
  }
  .highcharts-scrollbar-arrow {
    fill: var(--color-neutral);
  }
  .highcharts-scrollbar-rifles {
    stroke: var(--color-neutral);
    stroke-width: 1px;
  }
  .highcharts-scrollbar-track {
    fill: var(--color-neutral-20);
    stroke: var(--color-neutral-20);
    stroke-width: 1px;
  }
  .highcharts-button {
    fill: var(--color-neutral-20);
    stroke: var(--color-neutral-30);
    cursor: pointer;
    stroke-width: 1px;
    transition: fill 250ms, stroke 250ms;
  }
  .highcharts-button text {
    fill: var(--color-neutral);
  }
  .highcharts-button-hover {
    transition: fill 0ms;
    fill: var(--color-neutral-30);
    stroke: var(--color-neutral-20);
  }
  .highcharts-button-hover text {
    fill: $highcharts-primary-color;
  }
  .highcharts-button-pressed {
    stroke: $highcharts-primary-color;
    fill: $highcharts-primary-color;
  }
  .highcharts-button-pressed text {
    fill: var(--color-neutral-10);
  }
  .highcharts-button-disabled text {
    fill: var(--color-neutral);
  }

  .highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 1px;
    stroke: var(--color-neutral);
    fill: var(--color-neutral-10);
    transition: fill 250ms, stroke 250ms;

    rect {
      rx: 0;
      ry: 0;
      height: 34px;
    }

    @include mq($until: m) {
      // align to left for mobile
      // transform: translateY(-10px) translateX(0) !important;
    }
  }
  .highcharts-range-selector-buttons .highcharts-button-disabled {
    stroke: var(--color-neutral-40);
    cursor: default;
    text {
      fill: var(--color-neutral-40);
    }
  }
  .highcharts-range-selector-buttons .highcharts-button-pressed {
    stroke: $highcharts-primary-color;
    fill: $highcharts-primary-color;
  }
  .highcharts-range-label rect {
    fill: none;
  }
  .highcharts-range-label text {
    fill: var(--color-neutral);
  }
  .highcharts-range-input rect {
    fill: none;
    stroke: transparent;
  }
  .highcharts-range-input text {
    cursor: pointer;
    transition: fill 0.2s;
    fill: var(--color-neutral);
  }
  .highcharts-range-input:hover text {
    fill: var(--color-primary);
  }
  .highcharts-range-input {
    stroke-width: 1px;
    stroke: var(--color-neutral-30);
  }
  input.highcharts-range-selector {
    background-color: var(--color-neutral-10);
    padding: 3px get-space('xs') !important;
    border-radius: 0;
    border: 1px solid var(--color-neutral) !important;
    transition: border 0.2s;
    @include text-type-copy-2;

    position: absolute;
    border: 0;
    width: 1px; /* Chrome needs a pixel to see it */
    height: 1px;
    min-height: 22px;
    min-width: 100px;
    padding: 0;
    text-align: center;
    left: -9em; /* #4798 */
    top: -9999em;
    margin-top: 0;

    &:active,
    &:focus {
      border-radius: 0;
      border: 1px solid var(--color-neutral) !important;
      outline: 1px solid var(--color-neutral);
    }
  }
  .highcharts-crosshair-label text {
    fill: $highcharts-background-color;
    font-size: 1.1em;
  }
  .highcharts-crosshair-label .highcharts-label-box {
    fill: inherit;
  }

  .highcharts-candlestick-series .highcharts-point {
    stroke: var(--color-neutral);
    stroke-width: 1px;
  }
  .highcharts-candlestick-series .highcharts-point-up {
    fill: $highcharts-background-color;
  }
  .highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
  }
  .highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: var(--color-neutral);
    fill: $highcharts-background-color;
    transition: fill 250ms;
  }
  .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: var(--color-neutral);
    fill: $highlights-accent-color-30;
  }
  .highcharts-flags-series .highcharts-point text {
    fill: var(--color-neutral);
    font-size: 0.9em;
    font-weight: bold;
  }

  /* Highmaps */
  .highcharts-map-series .highcharts-point {
    transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
    stroke: var(--color-neutral-30);
  }
  .highcharts-map-series .highcharts-point-hover {
    transition: fill 0ms, fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
  }
  .highcharts-mapline-series .highcharts-point {
    fill: none;
  }
  .highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
  }
  .highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
  }
  .highcharts-coloraxis {
    stroke-width: 0;
  }
  .highcharts-coloraxis-marker {
    fill: var(--color-neutral);
  }
  .highcharts-null-point {
    fill: var(--color-neutral-20);
  }

  /* 3d charts */
  .highcharts-3d-frame {
    fill: transparent;
  }

  /* Exporting module */
  .highcharts-contextbutton {
    fill: $highcharts-background-color; /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
  }
  .highcharts-contextbutton:hover {
    fill: var(--color-neutral-30);
    stroke: var(--color-neutral-30);
  }
  .highcharts-button-symbol {
    stroke: var(--color-neutral);
    stroke-width: 3px;
  }
  .highcharts-menu {
    border: 1px solid var(--color-neutral);
    background: $highcharts-background-color;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888;
  }
  .highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: var(--color-neutral);
    cursor: pointer;
    transition: background 250ms, color 250ms;
  }
  .highcharts-menu-item:hover {
    background: $highlights-accent-color-80;
    color: $highcharts-background-color;
  }

  /* Drilldown module */
  .highcharts-drilldown-point {
    cursor: pointer;
  }
  .highcharts-drilldown-data-label text,
  text.highcharts-drilldown-data-label,
  .highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: $highcharts-corporate-color;
    font-weight: bold;
    text-decoration: underline;
  }

  /* No-data module */
  .highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: var(--color-neutral);
  }

  /* Drag-panes module */
  .highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px;
  }

  /* Bullet type series */
  .highcharts-bullet-target {
    stroke-width: 0;
  }

  /* Lineargauge type series */
  .highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: var(--color-neutral);
  }
  .highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: var(--color-neutral);
  }

  /* Annotations module */
  .highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: var(--color-neutral);
    fill: var(--color-neutral);
    fill-opacity: 0.75;
  }
  .highcharts-annotation-label text {
    fill: var(--color-neutral-30);
  }

  /* Gantt */
  .highcharts-treegrid-node-collapsed,
  .highcharts-treegrid-node-expanded {
    cursor: pointer;
  }
  .highcharts-point-connecting-path {
    fill: none;
  }
  .highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px;
  }
  .highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px;
  }
}

.columns {
  position: relative;
  @include get-grid;

  &--has-background {
    background-color: var(--color-neutral-20);
    padding: get-space(m) get-space(s);

    @include mq(l) {
      padding: get-space(l) 0;
    }
  }

  &__header {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }

    &--align-center {
      @include mq(l) {
        text-align: center;
      }
    }
  }

  &__overline {
    margin-bottom: get-space(xs);
  }

  &__headline {
    margin-bottom: get-space(s);

    @include mq(l) {
      margin-bottom: get-space(m);
    }
  }

  &__headline-text {
    display: inline-block;
  }

  &__copy {
    margin-bottom: get-space(s);
    grid-column: 1 / span 12;

    @include mq(l) {
      margin-bottom: get-space(m);
      grid-column: 2 / span 6;
    }

    &--align-center {
      @include mq(l) {
        grid-column: 2 / span 10;
        text-align: center;
      }
    }
  }

  &__column {
    grid-column: 1 / span 12;
    margin-bottom: get-space(m);
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    /* grid css for column start and end position for all possible columns */
    @include mq(m) {
      @for $i from 1 through 12 {
        &[data-column-m-start='#{$i}'] {
          grid-column-start: #{$i};
        }

        &[data-column-m-end='#{$i + 1}'] {
          grid-column-end: #{$i + 1};
        }
      }
    }

    @include mq(l) {
      margin-bottom: 0;

      @for $i from 1 through 12 {
        &[data-column-l-start='#{$i}'] {
          grid-column-start: #{$i};
        }

        &[data-column-l-end='#{$i + 1}'] {
          grid-column-end: #{$i + 1};
        }
      }
    }
  }

  &__column-headline {
    margin-bottom: get-space(s);

    @include mq(l) {
      margin-bottom: get-space(m);
    }
  }

  // text margin
  &__column--chart + &__column--text {
    @include mq(l) {
      margin-left: get-space(xl);
    }
  }

  &--center-items {
    align-items: center;
  }
}

.blocks {
  width: 100%;

  @include mq($until: m) {
    margin: get-space(l) 0;
  }

  &__block {
    margin-top: get-space(xl);
    margin-bottom: get-space(xl);

    @include mq(m) {
      margin-top: calc(2.5 * #{get-space(l)});
      margin-bottom: calc(2.5 * #{get-space(l)});
    }
  }

  .theme--orange & [data-type='podcast']:first-child,
  .theme--green & [data-type='podcast']:first-child {
    margin-top: calc(-1 * #{get-space(l)});

    @include mq(m) {
      margin-top: calc(-1 * #{get-space(xl)});
    }

    .podcast__container {
      padding-top: 0;
    }
  }
}

// Fonts
$font-family-default: 'Meta', Verdana, Arial, sans-serif;
$font-family-serif: 'Garamond LT', Times, serif;

$text-types: (
  headline-1: (
    fontSizes: 26 42,
    lineHeights: 1.462 1.286,
    letterSpacings: 0 0
  ),
  headline-2: (
    fontSizes: 22 34,
    lineHeights: 1.364 1.294,
    letterSpacings: -0.5 -0.8
  ),
  headline-3: (
    fontSizes: 20 24,
    lineHeights: 1.5 1.5,
    letterSpacings: 0 0
  ),
  headline-4: (
    fontSizes: 17 17,
    lineHeights: 1.67 1.88,
    letterSpacings: 0 0
  ),
  subhead-1: (
    fontSizes: 17 17,
    lineHeights: 1.67 1.88,
    letterSpacings: 0 0
  ),
  overline: (
    fontSizes: 11 13,
    lineHeights: 1.67 1.88,
    letterSpacings: 3 3.5
  ),
  // overline: (
  //   fontSizes: 10 12,
  //   lineHeights: 1.6 1.5,
  //   letterSpacings: 3 3.5
  // ),
  link:
    (
      fontSizes: 11 13,
      lineHeights: 1.67 1.88,
      letterSpacings: 1.83 2
    ),
  copy-1: (
    fontSizes: 17 17,
    lineHeights: 1.5 1.8,
    letterSpacings: 0 0
  ),
  copy-2: (
    fontSizes: 13 15,
    lineHeights: 1.6 1.667,
    letterSpacings: 0 0
  ),
  small: (
    fontSizes: 11 11,
    lineHeights: 1.4 1.4,
    letterSpacings: 0 0
  )
);

@function get-type-config($key, $map: $text-types) {
  @return map-get($map, $key);
}

.teaser {
  display: block;
  border-bottom: 1px solid var(--color-neutral-70);
  padding-bottom: get-space(xs);
  text-decoration: none;

  @include mq(l) {
    padding-bottom: get-space(m);
  }

  [data-whatintent='mouse'] &,
  [data-whatintent='touch'] & {
    outline: 0;
  }

  &__overline {
    margin-bottom: get-space(xxs);
  }

  &__copy {
    margin-top: get-space(xs);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @include mq(l) {
      margin-top: get-space(s);
      display: block;
      overflow: auto;
    }
  }

  &__image-wrapper {
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-top: get-space(s);
    }
  }

  &__image {
    height: auto;
    width: 100%;
  }

  &__news {
    border: solid 1px var(--color-default-20);
  }
}

.fixing-rates {
  margin-top: get-space(s);

  @include mq(l) {
    margin-top: get-space(m);
  }

  &__header {
    background-color: var(--color-neutral-20);
    padding: get-space(m) 0;
    @include get-grid;

    @include mq(l) {
      padding: get-space(xl) 0;
    }
  }

  &__headline {
    grid-column: 1 / span 12;
    margin-bottom: get-space(s);
    padding: 0 get-space(s);

    @include mq(l) {
      margin-bottom: get-space(m);
      grid-column: 2 / span 4;
      padding: 0;
    }
  }

  &__overline {
    grid-column: 1 / span 12;
    margin-bottom: get-space(xxs);
    padding: 0 get-space(s);

    @include mq(l) {
      grid-column: 2 / span 4;
      margin-bottom: get-space(xs);
      padding: 0;
    }
  }

  &__input {
    cursor: text;
    color: var(--color-neutral);
    background-color: var(--color-neutral-20);
    padding: get-space('xs') get-space('s');
    margin: 0 get-space(s);
    border-radius: 0;
    border: 1px solid var(--color-neutral);
    transition: border 0.2s;
    grid-column: 1 / span 12;
    @include text-type-copy-1;

    @include mq(l) {
      margin: 0;
      margin-bottom: get-space(xl);
      grid-column: 2 / span 3;
    }

    @include mq(xl) {
      grid-column: 2 / span 2;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }

    &::placeholder {
      color: var(--color-neutral-40);
      opacity: 1;
      @include text-type-copy-1;
    }

    &:active,
    &:focus {
      border-radius: 0;
      border: 1px solid var(--color-neutral);
      outline: 1px solid var(--color-neutral);
    }
  }

  &__wrapper {
    @include get-grid;
  }

  &__content {
    min-height: 300px;
    background-color: var(--color-neutral-10);
    grid-column: 1 / span 12;
    padding: get-space(s) 0;

    @include mq(l) {
      min-height: 500px;
      grid-column: 2 / span 10;
      padding: get-space(m) get-space(l);
      margin-top: calc(-1 * #{get-space(xl)});
    }
  }

  &__notes {
    grid-column: 1 / span 12;
    margin-bottom: get-space(m);

    @include mq(l) {
      padding: 0 get-space(l);
      margin-bottom: get-space(xl);
      grid-column: 2 / span 10;
    }
  }

  &__loader {
    margin-top: get-space(l);
  }
}

.fund-buy {
  position: relative;
  z-index: get-z-index('fund-buy');
  @include get-grid;

  &__header-wrapper,
  &__fund {
    grid-column: 1 / span 12;
  }

  &__intro,
  &__fund-wrapper,
  &__header {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  &__header-wrapper,
  &__fund-wrapper {
    @include get-grid();
  }

  &__intro,
  &__fund,
  &__header {
    @include get-grid(
      (
        default: 12,
        l: 16
      )
    );
  }

  &__intro {
    position: relative;
    z-index: get-z-index('intro', 'fund-buy');
  }

  &__headline,
  &__description,
  &__link {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 1 / span 9;
    }

    @include mq(xl) {
      grid-column: 1 / span 8;
    }
  }

  &__headline,
  &__description {
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-bottom: get-space(s);
    }
  }

  &__headline-text {
    display: inline-block;
  }

  &__manager {
    grid-column: 1 / span 12;
    display: flex;
    align-items: center;
    margin: get-space(s) 0;

    @include mq(l) {
      grid-row: 1 / span 4;
      grid-column: 11 / span 6;
      margin: 0;
      align-items: flex-end;
      align-self: flex-end;
    }

    @include mq(xl) {
      grid-column: 12 / span 5;
    }
  }

  &__mangager-image {
    width: 100px;
    margin-right: get-space(s);

    @include mq(l) {
      width: 160px;
    }
  }

  &__header-wrapper {
    display: none;
    position: relative;
    background-color: var(--color-neutral-20);
    grid-column: 1 / span 12;

    @include mq(l) {
      display: grid;
      margin-top: get-space(m);
      padding: get-space(l) 0 get-space(xl);
    }
  }

  &__fund-wrapper {
    position: relative;
    background: var(--color-neutral-10);

    @include mq(l) {
      position: relative;
      z-index: get-z-index('funds', 'fund-buy');
      margin: calc(-1 * #{get-space(m)}) calc(-1 * #{get-space(m)}) 0;
      padding: 0 get-space(m);
    }

    @include mq(xl) {
      margin: calc(-1 * #{get-space(l)}) calc(-1 * #{get-space(l)}) 0;
      padding: 0 get-space(l);
    }
  }

  &__fund {
    padding: get-space(s) 0;

    @include mq(xl) {
      padding: get-space(m) 0 get-space(s);
    }
  }

  &__fund + &__fund {
    border-top: 1px solid var(--color-neutral-30);
  }

  // &__fund-headline {
  // }
  &__info {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 1 / span 6;
    }
  }

  &__performance {
    grid-column: 1 / span 12;
    hyphens: auto;

    @include mq(l) {
      text-align: right;
      grid-column: 7 / span 2;
    }
  }

  &__volatility {
    grid-column: 1 / span 12;
    hyphens: auto;

    @include mq(l) {
      text-align: right;
      grid-column: 9 / span 2;
    }
  }

  &__nav {
    grid-column: 1 / span 12;
    hyphens: auto;

    @include mq(l) {
      text-align: right;
      grid-column: 11 / span 2;
    }
  }

  &__ctas {
    grid-column: 1 / span 12;
    margin-top: get-space(s);
    display: flex;
    flex-direction: column;
    text-align: center;

    @include mq(m) {
      flex-direction: row;
      align-items: center;
    }

    @include mq(l) {
      grid-column: 13 / span 4;
      margin-top: 0;
      margin-left: get-space(s);
      flex-direction: column;
    }
  }

  &__cta-buy {
    max-width: 100%;
    margin-bottom: get-space(xs);

    @include mq(m) {
      margin-bottom: 0;
      margin-right: get-space(s);
    }

    @include mq(l) {
      margin-bottom: get-space(xs);
      margin-right: 0;
    }

    .menubar__label {
      max-width: 100%;
    }
  }

  &__date {
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-bottom: 0;
      margin-top: get-space(xs);
    }
  }

  &__labels--mobile {
    @include mq(l) {
      display: none;
    }
  }

  &__labels--desktop {
    display: none;

    @include mq(l) {
      display: block;
    }
  }
}

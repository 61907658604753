.loader {
  text-align: center;
  display: block;

  svg {
    position: relative;
    z-index: get-z-index('loader');
    width: 20px;
    height: 25px;
  }

  svg rect {
    position: relative;
    fill: var(--color-primary);
  }

  @keyframes transformY {
    0% {
      transform: translateY(100%);
    }

    25% {
      transform: translateY(25%);
    }

    50% {
      transform: translateY(10%);
    }

    75% {
      transform: translateY(25%);
    }

    100% {
      transform: translateY(100%);
    }
  }

  &__fix {
    // add this element to prevent chrome bug for animation

    opacity: 0;
  }

  &__left {
    animation: transformY 1s linear 0s infinite;
  }

  &__middle {
    animation: transformY 1.75s linear 0s infinite;
  }

  &__right {
    animation: transformY 1.25s linear 0s infinite;
  }
}

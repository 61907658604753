.investment-advice {
  z-index: get-z-index('investment-advice');
  position: relative;
  color: var(--color-neutral);

  &__wrapper {
    width: 100%;
    margin-bottom: get-space(m);

    @include mq(l) {
      margin-bottom: get-space(l);
    }
  }

  &__section {
    text-align: center;
    @include get-grid;
  }

  // ---------- Header ----------
  &__header {
    grid-column: 1 / span 12;
    margin-bottom: get-space(xs);

    @include mq(l) {
      grid-column: 3 / span 8;
    }
  }

  &__header-logo {
    display: flex;
    justify-content: center;
    margin-top: get-space(m);

    svg {
      width: 40px;
      height: 56px;
    }

    @include mq(l) {
      margin-top: get-space(l);
    }
  }

  &__header-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: get-space(s) 0;

    @include mq(l) {
      margin: get-space(s) 0 get-space(m);
    }
  }

  &__header-number {
    display: flex;
    align-items: center;

    &::after {
      display: block;
      content: '';
      width: 0px;
      height: 1px;
      margin: 0 3px;
      background-color: var(--color-primary-10);

      @include mq(s) {
        width: 30px;
        margin: 0 get-space(xxs);
      }

      @include mq(l) {
        width: 60px;
      }
    }
  }

  &__header-circle {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 2px solid var(--color-primary-10);
    color: var(--color-primary-40);
    flex-shrink: 0;
    position: relative;

    @include mq(l) {
      width: 36px;
      height: 36px;
      border-radius: 18px;
    }

    svg {
      fill: var(--color-primary-40);
      stroke: var(--color-primary-40);
    }

    &--active {
      border-color: var(--color-primary-50);
      background: var(--color-primary-50);
      color: var(--color-neutral-10);

      svg {
        fill: var(--color-neutral-10);
        stroke: var(--color-neutral-10);
      }
    }
  }

  &__header-circle-text.text {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__header-results {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__header-overline {
    margin-bottom: get-space(xs);
  }

  // &__header-headline {
  //   margin-bottom: get-space(xs);
  // }
  &__header-description {
    margin-top: get-space(s);

    @include mq(l) {
      // margin-top: get-space(l);
    }
  }

  &__header-sticky {
    z-index: get-z-index('sticky', 'investment-advice');
    position: fixed;
    top: 0;
    width: 100%;
    padding: 16px 0;
    left: 0;
    background: var(--color-neutral-10);
    box-shadow: 0 2px 8px 0 rgba(52, 52, 52, 0.15);
    transform: translateY(-110%);
    transition: transform ease 0.3s;
  }

  &__header-sticky--active {
    transform: translateY(0);
  }

  &__header-sticky-content {
    @include max-width();

    padding: 0 get-space(s);
    display: flex;
    align-items: center;

    @include mq(l) {
      @include get-grid();
    }
  }

  &__header-sticky &__header-number {
    display: inline-flex;

    @include mq(l) {
      grid-column: 2 / span 1;
    }
  }

  &__header-short-headline {
    position: relative;
    top: 2px;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: get-space(xs);
    text-align: left;

    @include mq(l) {
      padding-left: get-space(xxs);
      grid-column: 3 / span 9;
    }

    @include mq(xl) {
      padding-left: get-space(xxs);
      grid-column: 3 / span 8;
    }
  }

  // ---------- Footer ----------
  &__footer {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }

    @include mq(xxl) {
      grid-column: 3 / span 8;
    }
  }

  &__results + &__footer {
    @include mq(xl) {
      grid-column: 2 / span 10;
    }
  }

  &__footer-help {
    margin-top: get-space(xs);
    @include get-grid(
      (
        default: 12,
        l: 8
      )
    );
  }

  &__footer-help-btn {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 4;
    }
  }

  &__footer-help-copy {
    margin-top: get-space(xs);
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 6;
    }
  }

  &__footer-navigation {
    margin-top: get-space(m);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include mq(s) {
      flex-direction: row;
    }
  }

  &__footer-navigation-btn {
    width: 100%;

    @include mq(s) {
      width: auto;
    }

    &.button--secondary {
      order: 2;

      @include mq(s) {
        order: initial;
      }
    }

    &.button--primary {
      order: 1;
      margin-bottom: get-space(xs);

      @include mq(s) {
        order: initial;
        margin-bottom: 0;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      display: none;

      @include mq(s) {
        display: block;
      }
    }
  }

  // ---------- Question ----------
  &__question {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }

    @include mq(xxl) {
      grid-column: 3 / span 8;
    }
  }

  // ---------- Answer Simple ----------
  &__answer-simple {
    position: relative;
    cursor: pointer;
    color: var(--color-neutral);
    background: var(--color-primary-alpha10);
    border: 1px solid var(--color-primary-alpha20);
    border-radius: 0;
    padding: get-space(s);
    padding-right: get-space(m);
    width: 100%;
    margin-bottom: get-space(xs);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s, box-shadow ease-in-out 0.5s;

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    @include mq(l) {
      min-height: 85px;
      padding-right: 120px;
      flex-direction: row;
      align-items: center;
    }

    &:hover,
    &:active {
      background: var(--color-primary);
      color: var(--color-neutral-10);
      box-shadow: 5px 6px 14px 0 rgba(64, 92, 75, 0.2);
    }

    &--active {
      background: var(--color-primary);
      color: var(--color-neutral-10);
    }
  }

  &__answer-simple &__check {
    position: absolute;
    width: 42px;
    height: 40px;
    right: 0;
    top: 17px;
    transform: translateX(50%);
    stroke: var(--color-primary);

    @include mq(l) {
      width: 40px;
      height: 40px;
      right: get-space(m);
      top: 50%;
      stroke: transparent;
      transform: translateY(-50%);
    }
  }

  &__answer-simple--has-image {
    @include mq(l) {
      min-height: auto;
    }
  }

  &__answer-simple &__answer-text {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include mq(l) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__answer-simple--has-image &__answer-text {
    border-top: 1px solid var(--color-neutral-alpha20);
    margin-top: get-space(s);
    padding-top: get-space(s);

    @include mq(l) {
      border-top: 0 none;
      margin-top: 0;
      padding-top: 0;
      flex-direction: column;
      border-left: 1px solid var(--color-neutral-alpha20);
      width: calc(70%);
      padding-left: get-space(m);
    }
  }

  &__answer-simple--has-image.investment-advice__answer-simple--active &__answer-text {
    border-top: 1px solid var(--color-neutral-40);

    @include mq(l) {
      border-top: 0 none;
      border-left: 1px solid var(--color-neutral-40);
    }
  }

  &__answer-simple &__answer-image {
    width: 100%;

    img {
      width: 100%;
      max-width: 200px;
    }

    @include mq(l) {
      margin-right: get-space(m);
      width: calc(30% - #{get-space(m)});
    }
  }

  &__answer-simple &__answer-headline {
    margin: 0;

    @include mq(l) {
      width: calc(35% - #{get-space(m)});
      margin-right: get-space(m);
    }
  }

  &__answer-simple &__answer-copy {
    border-top: 1px solid var(--color-neutral-alpha20);
    margin-top: get-space(s);
    padding-top: get-space(s);

    @include mq(l) {
      border-top: 0 none;
      border-left: 1px solid var(--color-neutral-alpha20);
      width: calc(65%);
      padding-left: get-space(m);
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__answer-simple--active &__answer-copy {
    border-top: 1px solid var(--color-neutral-40);

    @include mq(l) {
      border-top: 0 none;
      border-left: 1px solid var(--color-neutral-40);
    }
  }

  &__answer-simple--has-image &__answer-headline {
    border-top: 0 none;
    margin-top: 0;
    padding-top: 0;

    @include mq(l) {
      width: 100%;
      margin-right: 0;
      padding-right: 0;
    }
  }

  &__answer-simple &__answer-headline:only-child {
    @include mq(l) {
      width: 100%;
    }
  }

  &__answer-simple--has-image &__answer-copy {
    border-top: 0 none;
    margin-top: 0;
    padding-top: 0;

    @include mq(l) {
      border-left: 0 none;
      margin-left: 0;
      padding-left: 0;
      width: 100%;
    }
  }
  // ---------- Question Nested ----------
  &__radio-question {
    background-color: var(--color-primary-alpha10);
    border: 1px solid var(--color-primary-alpha20);
    padding: get-space(s);
    width: 100%;
    min-height: 10px;
    margin-bottom: get-space(xs);
    text-align: left;
    display: flex;
    flex-direction: column;
    transition: background-color ease-in-out 0.3s;

    &--active {
      background-color: var(--color-primary);
      color: var(--color-neutral-10);
    }

    @include mq(l) {
      flex-direction: row;
    }
  }

  &__radio-question-headline {
    margin: 0;

    @include mq(l) {
      width: calc(35% - #{get-space(m)});
      margin-right: get-space(m);
    }
  }

  &__radio-question-answers {
    border-top: 1px solid var(--color-neutral-alpha20);
    margin-top: get-space(xs);
    padding-top: get-space(s);
    display: flex;
    flex-direction: column;

    @include mq(l) {
      border-top: 0 none;
      border-left: 1px solid var(--color-neutral-alpha20);
      width: calc(65%);
      padding-left: get-space(m);
      margin-top: 0;
      padding-top: 0;
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &__radio-question &__radio-question-answers {
    border-top: 1px solid var(--color-neutral-40);

    @include mq(l) {
      border-top: 0 none;
      border-left: 1px solid var(--color-neutral-40);
    }
  }

  &__radio-item {
    display: flex;
    align-items: center;

    // @include mq(l) {
    //   min-width: 120px;
    // }
    &:not(:first-child) {
      margin-top: get-space(s);

      @include mq(l) {
        margin-top: 0;
      }
    }
  }

  &__radio-input {
    @include hideVisually();
  }

  &__radio-label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 1;
    color: var(--color-neutral);
    margin-right: get-space(s);
    transition: color ease-in-out 0.3s;
  }

  &__radio-custom {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid var(--color-neutral);
    margin-right: get-space(xs);
    border-radius: 100%;
    transition: border-color ease-in-out 0.3s;
    flex-shrink: 0;

    &:after {
      visibility: hidden;
      position: absolute;
      content: '';
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background-color: var(--color-neutral);
      transition: background-color ease-in-out 0.3s;
    }
  }

  &__radio-question--active &__radio-label {
    color: var(--color-neutral-10);
  }

  &__radio-question--active &__radio-custom {
    border-color: var(--color-neutral-10);
  }

  &__radio-input[disabled] ~ &__radio-label {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // :focus
  &__radio-input:active ~ &__radio-label > &__radio-custom,
  &__radio-input:focus ~ &__radio-label > &__radio-custom {
    border: 2px solid var(--color-neutral);
  }

  // :checked
  &__radio-input:checked ~ &__radio-label > &__radio-custom:after {
    visibility: visible;
    background-color: var(--color-neutral-10);
  }

  &__radio-question--active &__radio-input:active ~ &__radio-label > &__radio-custom,
  &__radio-question--active &__radio-input:focus ~ &__radio-label > &__radio-custom {
    border-color: var(--color-neutral-10);
  }

  // ---------- Question ----------
  &__results {
    grid-column: 1 / span 12;
    border: 1px solid var(--color-primary-20);
    box-shadow: 5px 6px 14px 0 rgba(64, 92, 75, 0.2);

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  &__results-navigation {
    background: var(--color-primary-10);
    padding: get-space(xs);
    display: flex;
    flex-direction: column;

    @include mq(l) {
      flex-direction: row;
      padding: get-space(s) 0;
    }
  }

  &__results-switch {
    cursor: pointer;
    border: 0 none;
    background: transparent;
    padding: 0;
    transition: color ease-in-out 0.1s;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--color-neutral);

    div {
      text-align: center;
      width: 100%;
    }

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    @include mq(l) {
      padding: 0 get-space(xs);
    }

    &:first-of-type {
      @include mq(l) {
        // padding-left: get-space(s);
      }

      // span {
      //   @include mq(l) {
      //     text-align: left;
      //   }
      // }
    }

    &:last-of-type {
      @include mq(l) {
        // padding-right: get-space(s);
      }

      // span {
      //   @include mq(l) {
      //     text-align: right;
      //   }
      // }
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &__results-switch-headline {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: get-space(xxs);

    @include mq(m) {
      margin-bottom: get-space(xs);
    }

    span {
      @include animatableUnderline(var(--color-neutral));
    }
  }

  &__results-switch-subline {
    opacity: 0;
    letter-spacing: 1.5px;
    transition: opacity ease-in-out 0.3s;
    margin-bottom: get-space(xxs);

    @include mq(l) {
      margin-bottom: 0;
    }
  }

  &__results-switch--active &__results-switch-subline {
    opacity: 1;
  }

  &__results-switch--active &__results-switch-headline span:after {
    width: 100%;
  }

  &__results-switch:hover:not(:disabled) &__results-switch-subline {
    opacity: 1;
  }

  &__results-switch:hover:not(:disabled) &__results-switch-headline span:after {
    width: 100%;
  }

  // ---------- Chart ----------
  // &__chart {
  // }

  // ---------- Switch ----------
  &__switch {
    display: flex;
    justify-content: center;
    column-gap: get-space(s);
    margin: get-space(xxs) 0 get-space(m);
    // background: var(--color-primary-alpha10);
    &-btn {
      // flex: 50%;

      padding: get-space(s) 0;
      color: var(--color-neutral);

      span {
        border-bottom: 0 none;
      }

      &.is-active span {
        border-bottom: 2px solid var(--color-accent-1);
      }
    }
  }

  // ---------- Slider ----------
  &__slider-container {
    display: flex;
    flex-direction: column;
    margin-bottom: get-space(m);

    @include mq(l) {
      flex-direction: row;
      column-gap: get-space(xs);
    }
  }

  &__slider {
    flex: 50%;

    &:first-child {
      margin-bottom: get-space(xs);

      @include mq(l) {
        margin-bottom: 0;
      }
    }

    .MuiSlider-rail {
      color: var(--color-primary-20);
    }

    .MuiSlider-thumb.Mui-active {
      box-shadow: 0px 0px 0px get-space(xs) var(--color-neutral-alpha20) !important;
    }

    .MuiBox-root {
      margin: 0 auto !important;
    }

    .MuiSlider-colorPrimary.MuiSlider-sizeLarge.MuiSlider-root {
      height: get-space(xxs) !important;
    }

    .MuiSlider-thumb {
      width: get-space(s) !important;
      height: get-space(s) !important;
    }

    .MuiSlider-thumb:hover {
      box-shadow: 0px 0px 0px get-space(xs) var(--color-primary-alpha20) !important;
    }

    .MuiSlider-thumbColorPrimary {
      color: var(--color-accent-1-60) !important;
      border: solid var(--color-neutral-10) 3px;
    }

    .MuiSlider-colorPrimary {
      color: var(--color-accent-1-60) !important;
    }
  }

  &__slider-icon {
    margin: 0 auto;
    height: get-space(s);
    width: get-space(s);
    margin-bottom: get-space(xxs);
  }

  &__slider-label {
    margin: get-space(xs) 0 get-space(xxs);
  }

  &__slider-wrapper {
    width: 70%;
    margin: auto;
  }

  &__slider-value {
    margin-top: 0;
    color: var(--color-primary) !important;
  }

  // ---------- Strategy ----------
  &__strategy {
    background-color: var(--color-neutral-10);
    padding: get-space(s);
    display: flex;
    flex-direction: column;

    @include mq(l) {
      padding: get-space(xl);
      flex-direction: row;
    }
  }

  &__strategy-chart {
    @include mq(l) {
      width: 60%;
    }
  }

  &__strategy-text {
    text-align: left;
    padding-top: get-space(m);

    @include mq(l) {
      width: 50%;
      padding-top: 0;
      padding-left: get-space(m);
    }
  }

  &__strategy-headline {
    margin-bottom: get-space(s);
  }

  // ---------- Loader ----------
  &__loader {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  // .compliance-hint-footer {
  //   margin-top: 0px !important; // Replace margin from footer class
  //   margin-bottom: 15px;
  // }

  // .filler {
  //   padding: 18px;
  // }

  // .compliance-hint-copy {
  //   padding: 8px;
  // }

  // .compliance-hint-link {
  //   font-weight: bold;
  // }

  // .compliance-hint-link:hover {
  //   font-weight: bold;
  //   text-decoration: underline;
  // }
}

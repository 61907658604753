.awards {
  &--has-grid {
    @include get-grid;
  }

  &__headline {
    margin-bottom: get-space(xs);
  }

  &--has-grid &__headline {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  &--has-grid &__wrapper {
    grid-column: 1 / span 12;
    @include grid-flex;

    width: 100%;
    align-items: center;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  &__award {
    display: inline-block;
  }

  &--has-grid &__award {
    padding-right: get-space(xs);
    margin-bottom: get-space(xs);

    @include mq(s) {
      padding-right: get-space(s);
      margin-bottom: get-space(s);
    }
  }

  &--has-grid &__award--portrait {
    img {
      max-height: 160px;

      @include mq(s) {
        max-height: 180px;
      }
    }
  }

  &--has-grid &__award--landscape {
    img {
      max-width: 160px;

      @include mq(s) {
        max-width: 180px;
      }
    }
  }
}

.ratings {
  &__headline.text {
    text-align: left;
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-bottom: get-space(s);
    }
  }

  &__container {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }

  table {
    min-width: 100%;
  }

  &--is-html table {
    text-align: left;
  }

  // th,
  td {
    padding: get-space(xs) get-space(s) get-space(xs) 0;
    word-break: keep-all;

    span {
      line-height: 1;
    }

    strong {
      display: block;
      font-weight: 700;
    }

    em {
      color: var(--color-primary);
    }
  }

  // thead {
  //   border-bottom: 1px solid var(--color-neutral-alpha50);
  // }
  tbody tr {
    border-bottom: 1px solid var(--color-neutral-alpha20);

    td {
      width: 50%;

      &:first-child {
        min-width: 124px;
      }

      // if two columns width should be 50%
      &:nth-child(2):last-child {
        width: 124px;
      }
    }
  }

  &__value {
    display: flex;
  }

  &__star {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    fill: var(--color-primary) !important;
    top: 4px; // to align with text
    position: relative;
  }

  &__default {
    flex-shrink: 0;
    display: inline-flex !important;
    justify-content: center;
    justify-self: flex-end;
    align-self: flex-end;
    align-items: center;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 100%;
    width: 28px;
    height: 28px;
  }
}

.footer {
  @include get-grid;

  &__main {
    grid-column: 1 / span 12;
    margin: 0 calc(-1 * #{map-get($containerPadding, 'default')});
    background-color: var(--color-neutral-20);
    padding-top: 166px;

    @include mq(l) {
      padding-top: get-space(m);
      grid-column: 1 / span 11;
      margin-left: calc(-1 * #{map-get($containerPadding, 'l')});
      margin-right: 0;
    }
  }

  &--is-screen &__main {
    margin: 0;

    @include mq(l) {
      margin: 0;
      grid-column: 1 / span 12;
    }
  }

  &__main-content {
    @include get-grid(
      (
        default: 12,
        l: 11
      )
    );

    padding: 0 calc(#{map-get($containerPadding, 'default')});

    @include mq(l) {
      padding-left: calc(#{map-get($containerPadding, 'l')});
      padding-right: 0;
    }
  }

  &__logo {
    grid-row: 3;
    grid-column: 1 / span 3;
    margin-bottom: get-space(m);

    @include mq(l) {
      grid-row: 1;
      grid-column: 1 / span 2;
      margin: 0 get-space(m) get-space(m);
    }
  }

  &__svg.svg-sprite {
    width: 50px;
    height: 70px;

    @include mq(l) {
      width: 70px;
      height: 97px;
    }
  }

  &__nav {
    grid-column: 1 / span 12;

    @include mq(l) {
      margin-right: get-space(m);
      grid-column: 1 / span 7;
    }

    @include mq(xl) {
      margin-right: get-space(xl);
    }
  }

  // main navigation
  &__nav--main {
    margin-bottom: get-space(xs);

    @include mq(l) {
      @include get-grid(
        (
          default: 3
        )
      );

      margin-bottom: get-space(m);
      margin-left: get-space(m);
    }
  }

  &__group {
    @include mq($until: l) {
      margin-bottom: get-space(s);
    }
  }

  &__group-headline {
    margin-bottom: get-space(xs);

    @include mq($until: l) {
      grid-column: 1 / span 2;
    }
  }

  &__links {
    @include mq($until: l) {
      @include get-grid(
        (
          default: 2
        )
      );

      grid-column-gap: 12px;
    }
  }

  &__item {
    &:nth-child(odd) {
      grid-column: 1 / span 1;
    }

    &:nth-child(even) {
      grid-column: 2 / span 1;
    }
  }

  &__link {
    display: inline-block;
    color: var(--color-neutral);
    transition: color ease-in-out 0.1s;
    padding-bottom: get-space(xxs);

    &:hover,
    &:focus {
      color: var(--color-accent-1-80);
    }

    &:active {
      color: var(--color-accent-1);
    }
  }

  // social navigation
  &__nav--social {
    margin-bottom: get-space(xs);
    padding-bottom: get-space(xs);
    border-bottom: 1px solid var(--color-neutral-40);

    @include mq(l) {
      margin-bottom: get-space(xs);
      margin-left: get-space(m);
    }
  }

  &__nav--social ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__nav--social a {
    padding: get-space(xxs);
    padding-left: 0;
    margin-right: get-space(xxs);
  }

  &__social-link svg {
    width: 20px;
    height: 20px;
    fill: var(--color-neutral);
  }

  // sub navigation
  &__nav--sub {
    margin-bottom: get-space(m);
    grid-column: 4 / span 9;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    @include mq(l) {
      justify-content: flex-start;
      margin-bottom: get-space(m);
      margin-left: get-space(m);
    }
  }

  &__nav--sub ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__nav--sub ul li {
    @include mq(m) {
      margin-right: get-space(xs);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__nav--sub li > &__link,
  &__nav--sub li > span {
    display: inline-block;
    padding-right: get-space(xs);
    padding-bottom: get-space(xxs);
  }

  // hero element
  &__hero {
    position: relative;
    grid-row: 1;
    grid-column: 1 / span 10;
    margin-bottom: -130px;

    @include mq(l) {
      grid-row: 2;
      grid-column: 8 / span 5;
      margin-bottom: 0;
    }
  }

  &__hero-teaser {
    margin-left: calc(-1 * #{map-get($containerPadding, 'default')});

    @include mq(l) {
      position: absolute;
      bottom: 0;
      right: calc(-1 * #{map-get($containerPadding, 'l')});
      margin-left: 0;
      z-index: get-z-index('hero', 'footer');
    }
  }

  &__hero-content {
    display: block;
    padding: get-space(m);
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    transition: background-color ease-in-out 0.2s;

    @include mq(l) {
      padding: get-space(xxl);
    }
  }

  &__hero-overline {
    margin-bottom: get-space(xs);
  }

  &__hero-cta {
    margin-top: get-space(s);
    @include linkUnderline();
  }

  &__hero-content:focus,
  &__hero-content:hover {
    background-color: var(--color-primary-60);
  }
}

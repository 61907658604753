.accordion {
  &--has-grid {
    @include get-grid;
  }

  &--slim {
    @include mq(l) {
      margin-top: get-space(s);
    }
  }

  &__headline {
    margin-bottom: get-space(s);
  }

  &--has-grid &__headline {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 9;
    }
  }

  &__container {
    position: relative;
  }

  &--slim &__container::before {
    display: none;
  }

  &--has-grid &__container {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 8;
    }
  }

  &__accordion {
    border-top: 1px solid var(--color-neutral);
  }

  &--slim &__accordion {
    border-top: 0 none;
  }

  &__item {
    border-bottom: 1px solid var(--color-neutral);
    padding: get-space(xs) 0;
  }

  &--slim &__item {
    border-bottom: 1px solid var(--color-neutral-20);

    @include mq(l) {
      padding: 0 0 get-space(s);
      margin-bottom: get-space(s);
    }
  }

  &__control {
    background: transparent;
    border: 0 none;
    cursor: pointer;
    padding: 0;
    position: relative;
    text-align: left;
    transition: color ease-in-out 0.2s;
    width: 100%;
    color: var(--color-neutral);

    &:focus,
    &:hover,
    &:active {
      color: var(--color-primary);
    }

    &[aria-expanded='true'] {
      color: var(--color-primary);
    }
  }

  &__title {
    display: block;
    margin-right: get-space(m);

    @include mq(m) {
      margin-right: get-space(l);
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    transition: transform ease-in-out 0.2s;
  }

  &__title,
  &__icon {
    pointer-events: none;
  }

  &--slim &__control {
    &[aria-expanded='true'] {
      color: var(--color-neutral);

      .accordion__title {
        font-weight: bold;
      }
    }
  }

  &__svg {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;

    @include mq(l) {
      height: 40px;
      width: 40px;
    }
  }

  &--slim &__svg {
    width: 20px;
    height: 20px;

    @include mq(l) {
      height: 25px;
      width: 25px;
    }
  }

  &__minus {
    display: none;
  }

  &__control[aria-expanded='true'] &__minus {
    display: block;
  }

  &__control[aria-expanded='true'] &__plus {
    display: none;
  }

  &__panel {
    @include get-grid(
      (
        'default': 12,
        'l': 9
      )
    );

    margin-top: get-space(xs);

    /* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
    &[hidden] {
      display: none;
    }
  }

  &__block {
    &--image {
      grid-column: 1 / span 12;

      @include mq(l) {
        grid-column: 1 / span 4;
      }
    }

    &--funds-listing,
    &--text {
      grid-column: 1 / span 12;
    }
  }

  &__block + &__block {
    margin-top: get-space(m);
  }
}

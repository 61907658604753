// woff are necessary  MIME type exception for CORS for inxmail subdomains

@font-face {
  font-family: 'Garamond LT';
  font-weight: 400;
  src: url('../fonts/StempelGaramondLTStd-Roman.woff2') format('woff2');
}

@font-face {
  font-family: 'Garamond LT';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/StempelGaramondLTPro-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Garamond LT';
  font-weight: 700;
  src: url('../fonts/StempelGaramondLTPro-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Garamond LT';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/StempelGaramondLTPro-BoldIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Meta';
  font-weight: 400;
  src: url('../fonts/Meta-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Meta';
  font-weight: 700;
  src: url('../fonts/Meta-Bold.woff2') format('woff2');
}

.download {
  &--has-grid {
    @include get-grid;
  }

  &--has-grid &__headline {
    margin-bottom: get-space(m);
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 9;
    }
  }

  &--has-grid &__wrapper {
    grid-column: 1 / span 12;
    @include get-grid(
      (
        default: 12,
        l: 9
      )
    );

    @include mq(l) {
      grid-column: 3 / span 9;
    }
  }

  &__download {
    display: flex;
    flex-direction: row;
    margin-bottom: get-space(s);
  }

  &__text {
    color: var(--color-neutral);
    transition: color ease-in-out 0.3s;
  }

  &__download:hover &__text,
  &__download:active &__text {
    color: var(--color-primary);
  }

  &--has-grid &__download {
    grid-column: 1 / span 12;

    &:nth-child(3n + 1) {
      @include mq(l) {
        grid-column: 1 / span 3;
      }
    }

    &:nth-child(3n + 2) {
      @include mq(l) {
        grid-column: 4 / span 3;
      }
    }

    &:nth-child(3n + 3) {
      @include mq(l) {
        grid-column: 7 / span 3;
      }
    }
  }

  &__svg.svg-sprite {
    fill: var(--color-primary);
    flex-shrink: 0;
    height: 40px;
    margin-right: get-space(xs);
    width: 40px;
  }

  // &__content {
  // }
  &__file {
    margin-top: get-space(xxs);
  }

  &__file-type {
    text-transform: uppercase;
  }
}

.news {
  &__max-width {
    @include max-width;
  }

  .blocks {
    margin-top: 0;

    @include mq(l) {
      margin-top: 0;
    }

    & > * {
      &.blocks__block:first-child {
        margin-top: 0;

        @include mq(l) {
          margin-top: 0;
        }
      }
    }
  }
}

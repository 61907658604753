.menubar {
  display: inline-block;
  position: relative;

  &__dropdown {
    width: 100%;
    display: flex;
    // grid-template-areas: 'select';

    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: var(--color-primary);
    border: 1px solid var(--color-neutral-10);

    // Custom arrow
    &:after {
      position: absolute;
      right: 0;
      content: '';
      justify-self: end;
      width: 20px;
      height: 20px;
      background-color: var(--color-neutral-10);
      clip-path: path('M3 7.043L4.05 6 10 11.908 15.949 6 17 7.043l-5.95 5.909.006.005L10.006 14z');
      padding-right: get-space(m);
      pointer-events: none;
    }

    &:before,
    &:after {
      // grid-area: select;
    }
  }

  &__label {
    grid-area: select;
    color: var(--color-neutral-10);
    padding: get-space('xs') get-space('l') get-space('xs') get-space('s');
    width: 100%;
  }

  &__dropdown-list {
    z-index: get-z-index('menubar');
    position: absolute;
    top: calc(100% + 2px);
    left: -1px;
    max-height: 265px;
    background-color: var(--color-neutral-10);
    box-shadow: 0 2px 8px 0 rgba(52, 52, 52, 0.15);
    overflow-y: auto;
    white-space: nowrap;
    width: calc(100% + 2px);

    [hidden] {
      display: none;
    }

    li {
      width: 100%;

      &:before {
        content: '';
      }

      + li {
        border-top: 1px solid var(--color-neutral-20);
      }

      button {
        cursor: pointer;
        border: 0 none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
      }

      a,
      button {
        width: calc(100% - 2px);
        margin: 2px;
        padding: 8px get-space('s');
        color: var(--color-neutral);
        display: block;
        transition: color ease-in-out 0.1s;

        span {
          color: black;
        }

        [data-whatintent='mouse'] &,
        [data-whatintent='touch'] & {
          outline: 0;
        }

        &:active,
        &:focus,
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  // if width is not set by biggest option
  // allow mulitple lines
  &:not([data-calculate-width='true']) &__dropdown-list {
    white-space: normal;
  }
}

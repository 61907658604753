.button {
  cursor: pointer;
  border: 0 none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ease-in-out 0.1s, border-color ease-in-out 0.1s, color ease-in-out 0.1s;
  color: var(--color-neutral);

  [data-whatintent='mouse'] &,
  [data-whatintent='touch'] & {
    outline: 0;
  }

  span {
    // to align with icon

    min-height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: border-color ease-in-out 0.1s, color ease-in-out 0.1s;
  }

  &--primary,
  &--secondary {
    padding: get-space(xs) get-space(s);
    // primary & secondary buttons are fullwidth on mobile

    width: 100%;

    @include mq(m) {
      width: auto;
    }
  }

  &--icon-only {
    padding: get-space(xs);
  }

  &--small {
    padding: get-space(xxs);
  }

  &--primary {
    color: var(--color-neutral-10);
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary);
  }

  &--primary:hover,
  &--primary:focus {
    border-color: var(--color-accent-1-80);
    background-color: var(--color-accent-1-80);
  }

  &--primary:active {
    border-color: var(--color-accent-1);
    background-color: var(--color-accent-1);
  }

  &--secondary {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    background-color: var(--color-neutral-10);
  }

  &--secondary:hover,
  &--secondary:focus {
    color: var(--color-accent-1-80);
    border-color: var(--color-accent-1-80);
  }

  &--secondary:active {
    color: var(--color-accent-1);
    border-color: var(--color-accent-1);
  }

  &--link {
    color: var(--color-primary);

    span {
      display: inline-block;
      border-bottom: 1px solid var(--color-primary);
    }
  }

  &--link:hover,
  &--link:focus {
    color: var(--color-accent-1-80);

    span {
      border-color: var(--color-accent-1-80);
    }
  }

  &--link:active {
    color: var(--color-accent-1);

    span {
      border-color: var(--color-accent-1);
    }
  }

  &:disabled {
    pointer-events: none;
    user-select: none;
    background: transparent;
    color: var(--color-neutral-40);
  }

  &--primary:disabled {
    border-color: transparent;
    background-color: var(--color-neutral-30);
  }

  &--secondary:disabled {
    border-color: var(--color-neutral-40);
  }

  &--link:disabled span {
    border-color: var(--color-neutral-40);
  }

  &__icon {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    transition: transform 150ms ease-in-out, color ease-in-out 0.1s;

    &:not(:only-child) {
      margin-right: get-space(xs);
    }
  }
}

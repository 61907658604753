.block-media {
  position: relative;

  &--has-grid {
    @include get-grid;
  }

  &--has-grid &__header {
    grid-column: 1 / span 11;
    margin-bottom: get-space(xs);

    @include mq(l) {
      margin-bottom: get-space(s);
      grid-column: 4 / span 6;
    }
  }

  &--has-grid#{&}--is-fullwidth &__header {
    margin-bottom: get-space(s);

    @include mq(l) {
      margin-bottom: get-space(m);
      text-align: center;
      grid-column: 3 / span 8;
    }
  }

  &__subhead.text {
    margin-top: get-space(xs);
  }

  &__wrapper {
    position: relative;
  }

  &--has-grid &__wrapper {
    position: relative;
    grid-column: 1 / span 12;
    margin-left: calc(-1 * #{map-get($containerPadding, 'default')});

    @include mq(l) {
      grid-column: 4 / span 6;
      margin-left: 0;
    }
  }

  &--has-grid#{&}--is-fullwidth &__wrapper,
  &--has-grid &__wrapper--expanded {
    @include mq(l) {
      grid-column: 1 / span 12;
    }
  }

  &__caption,
  &__note {
    padding-top: get-space(xs);
  }

  &--has-grid &__caption,
  &--has-grid &__note {
    grid-column: 2 / span 11;

    @include mq(l) {
      grid-column: 4 / span 5;
    }
  }

  &--has-grid#{&}--is-fullwidth &__caption,
  &--has-grid#{&}--is-fullwidth &__note {
    @include mq(l) {
      grid-column: 1 / span 5;
    }
  }

  &__graphic-btn {
    display: none;
    position: relative;
    width: 100%;
    cursor: zoom-in;
    border: 0 none;
    background: transparent;
    padding: 0;

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    @include mq(l) {
      display: block;
    }
  }

  &--has-grid &__wrapper--expanded &__graphic-btn {
    cursor: zoom-out;
  }

  &__graphic {
    transition: transform 150ms ease-in-out !important;
  }

  &__graphic-btn:hover &__graphic {
    @include mq(l) {
      transform: scale(1.01);
    }
  }

  &:not(.block-media--is-fullwidth) &__graphic--static {
    @include mq(l) {
      display: none;
    }
  }
}

.input {
  &--hidden {
    margin: 0;
  }

  &__label {
    display: block;
    color: var(--color-neutral);
    margin-bottom: get-space('xs');
    width: 100%;
  }

  &__input {
    background-color: var(--color-neutral-10);
    padding: get-space('xs') get-space('s');
    border-radius: 0;
    border: 1px solid var(--color-neutral);
    width: 100%;
    transition: border 0.2s;
    @include text-type-copy-1;
  }

  &__input-phone {
    background-color: var(--color-neutral-10);
    padding: get-space('xs') get-space('s');
    border-radius: 0;
    border: 1px solid var(--color-neutral);
    width: 100%;
    transition: border 0.2s;
    @include text-type-copy-1;
  }

  &__input-phone::placeholder {
    color: var(--color-neutral-40);
    opacity: 1;
    @include text-type-copy-1;
  }

  &__input-phone:disabled {
    cursor: default;
    pointer-events: none;
    background-color: var(--color-neutral-10);
    border-color: var(--color-neutral-40);

    &::placeholder {
      color: var(--color-neutral-alpha20);
    }
  }

  &__input-phone:active,
  &__input-phone:focus {
    border-radius: 0;
    border: 1px solid var(--color-neutral);
    outline: 1px solid var(--color-neutral);
  }

  &.form-error &__input-phone {
    border: 1px solid var(--color-negative);
    outline: 1px solid var(--color-negative);

    &:active,
    &:focus {
      border: 1px solid var(--color-neutral);
      outline: 1px solid var(--color-neutral);
    }
  }

  &__input::placeholder {
    color: var(--color-neutral-40);
    opacity: 1;
    @include text-type-copy-1;
  }

  &__input:disabled {
    cursor: default;
    pointer-events: none;
    background-color: var(--color-neutral-10);
    border-color: var(--color-neutral-40);

    &::placeholder {
      color: var(--color-neutral-alpha20);
    }
  }

  &__input:active,
  &__input:focus {
    border-radius: 0;
    border: 1px solid var(--color-neutral);
    outline: 1px solid var(--color-neutral);
  }

  &.form-error &__input {
    border: 1px solid var(--color-negative);
    outline: 1px solid var(--color-negative);

    &:active,
    &:focus {
      border: 1px solid var(--color-neutral);
      outline: 1px solid var(--color-neutral);
    }
  }

  &__required {
    margin-top: get-space(xxs);
  }

  &.form-error &__required {
    color: var(--color-negative);
  }

  // &__error.text {
  //   display: none;
  //   margin-top: get-space(xxs);
  //   color: var(--color-negative);
  // }

  // &.form-error &__error {
  //   display: block;
  // }
}

.investor-profile {
  position: relative;
  color: var(--color-neutral);
  @include get-grid;

  &__wrapper {
    grid-column: 2 / span 10;

    @include mq(m) {
      grid-column: 3 / span 8;
    }

    @include mq(xl) {
      grid-column: 4 / span 6;
    }
  }

  .select {
    margin-bottom: get-space(s);
  }

  .select select,
  .select select:disabled {
    background-color: transparent;
    color: var(--color-neutral);
  }

  // ---------- Loader ----------
  &__loader {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }
}

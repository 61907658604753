.notification {
  &[hidden] {
    display: none;
  }

  &--has-grid {
    @include get-grid;
  }

  &__wrapper {
    padding: 10px get-space(xs);
    color: var(--color-neutral-10);

    @include mq(m) {
      padding: 10px 20px;
    }
  }

  &--has-grid &__wrapper {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }

  &--neutral &__wrapper {
    background-color: var(--color-neutral-40);
  }

  &--progress &__wrapper {
    background-color: var(--color-progress);
  }

  &--warning &__wrapper {
    background-color: var(--color-warning);
  }

  &--success &__wrapper {
    background-color: var(--color-positive);
  }

  &--error &__wrapper {
    background-color: var(--color-negative);
  }

  &__content {
    position: relative;
    padding-right: 36px;
    display: flex;

    span {
      margin-top: 1px; // center text to icon
    }
  }

  &--has-grid &__content {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 9;
    }
  }

  &__svg {
    height: 20px;
    width: 20px;
    margin-right: get-space(xs);
    flex-shrink: 0;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    border: 0 none;
    background: transparent;
    padding: 0;

    svg {
      height: 20px;
      width: 20px;
      color: var(--color-neutral-10);
    }
  }
}

.checkbox {
  position: relative;

  // hide native radio button
  &__input {
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
  }

  // label
  &__input + &__label {
    display: inline-flex;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;

    a {
      border-bottom: 1px solid;
      padding-bottom: 1px;
    }
  }

  // disabled label
  &__input:disabled + &__label {
    cursor: default;
    pointer-events: none;
    color: var(--color-neutral-40);
  }

  // custom buttons - basic style
  &__input + &__label::before {
    content: '';
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    top: -2px;
    width: 24px;
    height: 24px;
    background-color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: get-space(xs);
    transition: border 0.2s;

    @include mq(l) {
      top: 1px;
    }
  }

  // :disabled for custom input
  &__input:disabled + &__label::before {
    border-color: var(--color-neutral-40);
  }

  // error custom input
  &.form-error &__label::before {
    border: 1px solid var(--color-negative);
    outline: 1px solid var(--color-negative);
  }

  // :focus
  &__input:active + &__label::before,
  &__input:focus + &__label::before {
    border: 1px solid var(--color-neutral);
    outline: 1px solid var(--color-neutral);
  }

  // :checked
  &__input:checked + &__label::before {
    box-shadow: none;
    background: var(--color-primary);
    border-color: var(--color-primary);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cpath d='M17.278 5l1.159 1.159-8.877 8.876.006.006-1.16 1.159-.005-.006-.005.006-1.16-1.159.006-.006L3 10.793l1.159-1.159L8.4 13.877z' fill='%23FFFFFF' fill-rule='nonzero' stroke='%23FFFFFF' stroke-width='.5'/%3E%3C/svg%3E");
    background-size: 22px;
  }

  &__input:disabled:checked + &__label::before {
    background-color: var(--color-neutral-10);
    border-color: var(--color-neutral-40);
  }

  // :checked:active
  &__input:checked:active + &__label::before,
  &__input:checked:focus + &__label::before {
    border: 1px solid var(--color-neutral);
    outline: 1px solid var(--color-neutral);
  }

  &__required {
    margin-top: 2px;
  }

  &.form-error &__required {
    color: var(--color-negative);
  }

  // &__error.text {
  //   display: none;
  //   margin-top: 2px;
  //   color: var(--color-negative);
  // }

  // &.form-error &__error {
  //   display: block;
  // }
}

.funds-listing {
  position: relative;

  &__fund-description {
    margin-top: get-space(xxs);
  }

  &__fund {
    display: block;
    padding: get-space(s) 0;

    &:active,
    &:focus,
    &:hover {
      .funds-listing__fund-headline {
        color: var(--color-primary);
      }
    }
  }

  &__fund + &__fund {
    border-top: 1px solid var(--color-neutral-30);
  }
}

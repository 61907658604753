.block-chart {
  position: relative;

  .blocks,
  .blocks__block {
    margin: 0;

    @include mq(l) {
      margin: 0;
    }
  }
}

.image {
  position: relative;

  &[style*='--ratio'] {
    height: 0;
    padding-bottom: calc(100% / (var(--ratio)));
    position: relative;
    box-sizing: border-box;

    > * {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  // &__copyright {
  //   text-align: right;
  //   padding: 4px space('xxs') 0;
  //   font-style: italic;

  //   @include mq(l) {
  //     text-align: left;
  //     position: absolute;
  //     bottom: 0;
  //     right: 0;
  //     background: color('white');
  //     opacity: 0;
  //     transition: opacity 0.2s ease-in-out;
  //   }
  // }

  // &:hover &__copyright,
  // .has-hoverable-children:hover &__copyright {
  //   @include mq(l) {
  //     opacity: 1;
  //   }
  // }
}

.text {
  color: inherit;
  font-style: normal;
  font-weight: normal;

  &--headline-1 {
    @include text-type-headline-1;
  }

  &--headline-2 {
    @include text-type-headline-2;
  }

  &--headline-3 {
    @include text-type-headline-3;
  }

  &--headline-4 {
    @include text-type-headline-4;
  }

  &--subhead-1 {
    @include text-type-subhead-1;
  }

  &--overline {
    @include text-type-overline;
  }

  &--link {
    @include text-type-link;
  }

  &--copy-1 {
    @include text-type-copy-1;
  }

  &--copy-2 {
    @include text-type-copy-2;
  }

  &--small {
    @include text-type-small;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--hyphenate {
    hyphens: auto;
  }

  &--bold {
    font-weight: 700;
  }

  &--centered {
    text-align: center;
  }

  &--italic {
    font-style: italic;

    b,
    strong {
      font-style: normal;
    }
  }

  &--wide {
    letter-spacing: 0.75em;
  }
}

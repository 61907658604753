.contact {
  &__stage {
    @include max-width;

    overflow: visible;
  }

  &__teaser {
    margin-top: get-space(m);

    @include mq(l) {
      margin-top: get-space(l);
    }
  }

  &__max-width {
    @include max-width;
  }
}

.quote {
  margin: get-space(xl) 0;

  &--has-grid {
    @include get-grid;
  }

  // &__wrapper {
  // }
  &--has-grid &__wrapper {
    grid-column: 2 / span 10;

    @include mq(l) {
      grid-column: 3 / span 8;
    }
  }

  &__content {
    position: relative;
  }

  &__quotation.text {
    color: var(--color-primary);
    margin-bottom: 0;
    margin-top: 0;

    // add DE quotation marks
    &::before {
      content: '\201E';
    }

    &::after {
      content: '\201C';
    }
  }

  &--en &__quotation.text {
    // add EN quotation marks
    &::before {
      content: '\201C';
    }

    &::after {
      content: '\201D';
    }
  }

  &__author {
    display: block;
    margin-top: get-space(xs);

    @include mq(l) {
      margin-top: get-space(s);
    }

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100px;
      background-color: var(--color-neutral);
      margin-bottom: get-space(xs);
    }
  }
}

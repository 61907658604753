.home {
  &__stage {
    @include max-width;

    overflow: visible;
  }

  &__headline-wrapper {
    @include get-grid;

    margin-top: get-space(xl);
    margin-bottom: calc(-1 * #{get-space(s)});

    @include mq(m) {
      margin-top: calc(2 * #{get-space(l)});
    }
  }

  &__headline {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }

  &__max-width {
    @include max-width;
  }

  &__main--home {
    .blocks .blocks__block {
      // first text block under stage-home should have less margin to h1
      &[data-type='text'] {
        &:first-child {
          margin-top: 0;

          @include mq(m) {
            margin-top: get-space(l);
          }
        }
      }
    }
  }
}

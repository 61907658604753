.checkbox-group {
  width: 100%;

  &__label {
    display: block;
    color: var(--color-neutral);
    margin-bottom: get-space('xs');
    width: 100%;
  }

  > * {
    display: block;
    margin-bottom: get-space('xs');
    position: relative;
  }
}

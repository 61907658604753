.inxmail {
  @include get-grid;

  &__content {
    grid-column: 1 / span 12;

    @include mq(s) {
      grid-column: 2 / span 10;
    }

    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }

  // ----------- INXMAIL FORM STYLING -----------
  form > * {
    display: block;
    margin-bottom: get-space('s');
    position: relative;
  }

  form a {
    color: var(--color-primary);
  }

  .heading {
    margin-top: get-space(xl);
  }

  input + .heading {
    margin-top: 0;
  }

  .step {
    display: none; // TODO how to?
  }

  .title {
    @include text-type-headline-2;
  }

  .department {
    margin-top: get-space(xl);
    @include text-type-headline-3;
  }

  .subtitle {
    @include text-type-copy-1;

    font-weight: bold;
  }

  .subtitle-paragraph {
    margin-top: calc(-1 * #{get-space(s)});
    @include text-type-copy-1;
  }

  .mailinfo {
    margin-top: calc(-1 * #{get-space(s)});
    margin-bottom: get-space(m);
    margin-left: 28px;
    @include text-type-copy-2;
  }

  .release {
    b {
      font-weight: bold;
    }
  }

  // label
  .label {
    display: block;
    color: var(--color-neutral);
    margin-bottom: get-space('xs');
    width: 100%;
  }

  // text input
  .textfield input.text {
    display: block;

    @extend .input__input; // add styling from input component

    &.inxmail-error {
      outline: 1px solid var(--color-negative);
      border: 1px solid var(--color-negative);
    }
  }

  // align checkbox before label
  .checkboxfield {
    display: flex;
    align-items: center;
    margin-bottom: get-space(m);

    .label {
      width: auto;
      display: inline-block;
      margin-bottom: 0;
      order: 1;
    }
  }

  .checkboxfield input.checkbox {
    order: 0;
    width: 20px;
    height: 20px;
    border: solid 1px var(--color-neutral);
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;

    // hide native select styles

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // custom checked styles
    &:checked::before {
      content: '';
      width: 18px;
      height: 18px;
      background-color: var(--color-primary);
      position: absolute;
      box-shadow: none;
      border-color: var(--color-primary);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cpath d='M17.278 5l1.159 1.159-8.877 8.876.006.006-1.16 1.159-.005-.006-.005.006-1.16-1.159.006-.006L3 10.793l1.159-1.159L8.4 13.877z' fill='%23FFFFFF' fill-rule='nonzero' stroke='%23FFFFFF' stroke-width='.5'/%3E%3C/svg%3E");
      background-size: 20px;
    }

    &:disabled {
      border-color: var(--color-neutral-40);
    }

    &.inxmail-error {
      outline: 1px solid var(--color-negative);
      border: 1px solid var(--color-negative);
    }

    // [type=radio]:checked::before{
    //   border-radius: 100%;
    // }
  }

  // select container
  .listfield {
    // show custom arrow
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      bottom: 15px; // TODO how to center better?
      justify-self: end;
      width: 20px;
      height: 20px;
      background-color: var(--color-neutral);
      clip-path: path('M3 7.043L4.05 6 10 11.908 15.949 6 17 7.043l-5.95 5.909.006.005L10.006 14z');
    }
  }

  // select
  .listfield select {
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid var(--color-neutral);
    padding: get-space('xs') get-space('s');
    position: relative;

    @include text-type-copy-1;

    &:focus {
      outline: 1px solid var(--color-neutral);
    }

    &:disabled {
      background-color: var(--color-neutral-10);
      border-color: var(--color-neutral-40);
      color: var(--color-neutral-alpha20);
    }

    &.inxmail-error {
      outline: 1px solid var(--color-negative);
      border: 1px solid var(--color-negative);
    }

    // hide native select styles

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  .submit {
    margin-top: get-space(m);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include mq(s) {
      flex-direction: row;
    }
  }

  .submit input {
    width: 100%;
    cursor: pointer;

    @include mq(s) {
      width: auto;
    }
  }

  .submit input[type='submit'] {
    order: 1;
    margin-bottom: get-space(xs);

    @extend .button--primary; // add button styling

    @include mq(s) {
      order: 2;
      margin-bottom: 0;
    }
  }

  .submit input[type='reset'] {
    order: 2;

    @extend .button--secondary; // add button styling

    @include mq(s) {
      order: 1;
    }
  }

  .message_missing {
    color: var(--color-negative);
  }
}

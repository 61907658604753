.tabs {
  position: relative;

  &--is-fullwidth &__wrapper {
    border-bottom: 1px solid var(--color-neutral-20);
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tablist {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: get-space(s);
  }

  &--is-fullwidth &__tablist {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    min-width: 100%;
    width: auto;
    justify-content: space-between;
  }

  &__cta {
    display: none;
    position: absolute;

    &--right {
      top: 0;
      right: -24px;
    }

    &--left {
      top: 0;
      left: -24px;
    }

    &--active {
      display: block;
    }
  }

  &__tab {
    position: relative;
    cursor: pointer;
    border: 0 none;
    background: transparent;
    padding: 0 get-space(s) 0 0;
    color: var(--color-neutral);
    display: flex;
    align-items: flex-start;
    text-align: left;
    transition: color ease-in-out 0.1s;

    span {
      position: relative;
    }

    &:focus,
    &:hover {
      color: var(--color-primary);
    }

    &[aria-selected='true'] span {
      color: var(--color-primary);

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 50px;
        height: 1px;
        background: var(--color-primary);
      }
    }
  }

  &--is-fullwidth &__tab {
    flex-shrink: 0;
  }

  &__tabpanel {
    margin: get-space(l) 0;

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }
  }
}

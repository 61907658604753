.search,
.search-auto {
  z-index: get-z-index('search');

  &__wrapper {
    position: relative;
  }

  input {
    padding-right: 55px;
  }

  &__button {
    color: var(--color-primary);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: get-space(xs);
    border: 0 none;
    width: auto;
    background: transparent;

    &:hover,
    &:focus {
      color: var(--color-accent-1-80);
    }

    &:active {
      color: var(--color-accent-1);
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.search-auto {
  $self: &;
  @include get-grid();

  position: relative;

  &__wrapper,
  &__label {
    grid-column: 1 / span 12;
    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }

  &__container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }

  &__suggestions-container {
    display: none;
    position: absolute;
    left: 0;
    top: calc(100% + 1px);
    width: calc(100%);
    max-height: 300px;
    background-color: var(--color-neutral-10);
    box-shadow: 0 2px 8px 0 rgba(52, 52, 52, 0.15);
    overflow-y: auto;
    z-index: get-z-index('overlay', 'search');

    &--open {
      display: block;
    }
  }

  &__section-container {
    border-top: 1px solid var(--color-neutral-20);

    &--first {
      border-top: 0;
    }
  }

  &__suggestions-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__suggestion {
    cursor: pointer;
    border-top: 1px solid var(--color-neutral-20);
    padding: 0;

    @include mq(m) {
      padding: 0;
    }

    a {
      margin: 2px;
      padding: get-space('xs') get-space('s');
      color: var(--color-neutral);
      display: block;

      [data-whatintent='mouse'] &,
      [data-whatintent='touch'] & {
        outline: 0;
      }

      &:active,
      &:focus,
      &:hover {
        color: var(--color-primary);
      }
    }

    &--highlighted {
      color: var(--color-primary);
    }
  }

  &__suggestion-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__suggestion-text {
    display: flex;
    align-items: flex-start;
  }

  &__suggestion-entry {
    text-transform: uppercase;
    display: none;

    @include mq(m) {
      display: block;
    }
  }
}

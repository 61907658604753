.teaser-highlight {
  position: relative;
  padding-bottom: get-space(xl);
  @include get-grid();

  &--is-stage {
    padding-bottom: 0;
  }

  .teaser-highlight__image-wrapper {
    min-height: get-space(l);
    position: relative;
    grid-column: 1 / span 12;
    grid-row: 1;
    margin-right: calc(-1 * #{map-get($containerPadding, 'default')});

    @media (prefers-reduced-motion: no-preference) {
      // inital animation style

      opacity: 0.5;
      transform: translate(10px, 0px);
    }

    @include mq(m) {
      grid-column: 2 / span 11;
      margin-right: 0;
    }

    @include mq(l) {
      min-height: auto;
    }

    video,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .teaser-highlight__video {
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
    position: relative;
    box-sizing: border-box;
  }

  &--is-stage .teaser-highlight__image-wrapper {
    grid-row: 2;

    @include mq(l) {
      grid-row: 1;
    }
  }

  .teaser-highlight__content {
    position: relative;
    grid-column: 1 / span 10;
    grid-row: 1;
    align-self: end;
    margin-top: calc(-1 * #{get-space(l)});
    margin-bottom: calc(-1 * #{get-space(l)});
    margin-left: calc(-1 * #{map-get($containerPadding, 'default')});
    padding: get-space(s) get-space(s) get-space(m) #{map-get($containerPadding, 'default')};
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    transition: background-color ease-in-out 0.2s;

    @media (prefers-reduced-motion: no-preference) {
      // inital animation style

      opacity: 0;
      transform: translate(-10px, 0px);
    }

    & > * {
      @media (prefers-reduced-motion: no-preference) {
        opacity: 0;
      }
    }

    @include mq(m) {
      padding: get-space(m) get-space(s) get-space(l) #{map-get($containerPadding, 'default')};
    }

    @include mq(l) {
      grid-column: 1 / span 5;
      margin-left: calc(-1 * #{map-get($containerPadding, 'l')});
      margin-bottom: 0;
      padding: get-space(l) get-space(m) get-space(l) map-get($containerPadding, 'l');
    }

    @include mq(xl) {
      padding: get-space(l) get-space(m) get-space(l) calc(#{map-get($containerPadding, 'l')} + #{get-space(m)});
    }
  }

  &--is-stage .teaser-highlight__content {
    align-self: start;
    margin-top: 0;
  }

  &--is-link .teaser-highlight__content {
    grid-row: 2;

    @include mq(l) {
      grid-row: 1;
      margin-top: get-space(xxl);
    }
  }

  .teaser-highlight__overline {
    margin-bottom: get-space(xs);
  }

  .teaser-highlight__label {
    margin-top: get-space(s);
    @include linkUnderline();

    @include mq(m) {
      margin-top: get-space(m);
    }
  }

  .teaser-highlight__link {
    display: inline-block;
    margin-top: get-space(s);
    border: 1px solid var(--color-neutral-10);
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    width: auto;

    @include mq(m) {
      margin-top: get-space(m);
    }

    &:hover,
    &:focus {
      background-color: var(--color-primary-40);
    }

    &:active {
      background-color: var(--color-primary-40);
    }
  }

  &--is-link {
    &:focus,
    &:hover {
      .teaser-highlight__content {
        background-color: var(--color-primary-60);
      }

      .teaser-highlight__label:after {
        width: 100%;
      }
    }
  }
}

.screen {
  position: relative;
  margin: get-space(m) 0;

  &__max-width {
    @include max-width;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: var(--color-neutral-20);
    padding: get-space(s) 0 get-space(m);
    min-height: calc(100vh - 2 * #{get-space(m)});

    @include mq(l) {
      padding: get-space(l) 0;
    }
  }

  &__wappen {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: get-space(xs);

    svg {
      display: block;
      width: 40px;
      height: 55px;
    }

    @include mq(m) {
      margin-bottom: get-space(s);
    }
  }

  &__header {
    width: 100%;
    @include get-grid;
  }

  &__overline,
  &__headline {
    text-align: center;
    grid-column: 2 / span 10;

    @include mq(m) {
      grid-column: 3 / span 8;
    }

    @include mq(xl) {
      grid-column: 4 / span 6;
    }
  }

  &__overline {
    margin-bottom: get-space(xxs);

    @include mq(m) {
      margin-bottom: get-space(xs);
    }
  }

  &__headline {
    @include mq(m) {
      margin-bottom: get-space(xs);
    }
  }

  &__content-link {
    margin-top: get-space(xs);

    @include mq(m) {
      margin-top: get-space(m);
    }
  }

  .blocks {
    margin: 0;
  }

  .blocks__block {
    margin: get-space(s) 0 0;
  }

  .form {
    margin: get-space(s) 0 0;
    max-width: none;
    @include get-grid;

    // .input__input {
    //   background-color: var(--color-neutral-20);
    //   border: 1px solid var(--color-neutral-90);
    // }
    .button {
      margin-top: get-space(xs);
      margin-bottom: 0;
      grid-column: 2 / span 10;

      @include mq(m) {
        grid-column: 6 / span 2;
      }
    }
  }

  .form--has-grid .form__form,
  .notification--has-grid .notification__wrapper,
  .richtext--has-grid .richtext__wrapper {
    grid-column: 2 / span 10;

    @include mq(m) {
      grid-column: 3 / span 8;
    }

    @include mq(xl) {
      grid-column: 4 / span 6;
    }
  }
}

.link {
  transition: color ease-in-out 0.1s;

  [data-whatintent='mouse'] &,
  [data-whatintent='touch'] & {
    outline: 0;
  }

  span {
    // to align with icon
    min-height: 18px;
    display: inline-flex;
    align-items: center;
    transition: border-color ease-in-out 0.1s, color ease-in-out 0.1s;
    position: relative;

    @include linkUnderline();
  }

  &--primary {
    color: var(--color-primary);

    & span::after {
      background-color: var(--color-primary);
    }
  }

  &--primary:hover,
  &--primary:focus {
    color: var(--color-accent-1-80);

    & span::after {
      background-color: var(--color-accent-1-80);
    }
  }

  &--primary:active {
    color: var(--color-accent-1);

    & span::after {
      background: var(--color-accent-1);
    }
  }

  &--secondary {
    & span::after {
      background-color: var(--color-neutral);
    }
  }

  &--secondary:hover,
  &--secondary:focus {
    color: var(--color-primary);

    & span::after {
      background-color: var(--color-primary);
    }
  }

  &--secondary:active {
    color: var(--color-accent-1);

    & span::after {
      background-color: var(--color-neutral-10);
    }
  }

  &--tertiary {
    & span::after {
      background-color: var(--color-neutral-10);
    }
  }

  &--tertiary:hover,
  &--tertiary:focus {
    color: var(--color-neutral-30);

    & span::after {
      background-color: var(--color-neutral-10);
    }
  }

  &--tertiary:active {
    color: var(--color-accent-1);

    & span::after {
      background-color: var(--color-accent-1);
    }
  }

  &--inverted {
    color: var(--color-neutral-10);
  }

  &--inverted,
  &--inverted:hover,
  &--inverted:focus,
  &--inverted:active {
    & span::after {
      background-color: var(--color-neutral-10);
    }
  }

  &--inverted:hover,
  &--inverted:focus {
    opacity: 0.9;
  }
}

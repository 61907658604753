$overlapAreaMobile: 200px;
$overlapAreaDesktop: 250px;

.stage-contact {
  position: relative;
  z-index: get-z-index('stage');
  @include get-grid;

  // ------------- grey background -------------
  &__background {
    grid-row: 1 / span 5;
    background-color: var(--color-neutral-20);
    grid-column: 1 / span 12;
    margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
    margin-top: get-space(xxl);

    @include mq(l) {
      margin-top: get-space(xxl);
      grid-column: 2 / span 11;
      margin-right: 0;
    }
  }

  // ------------- main -------------
  // main: grid
  &__main {
    z-index: get-z-index('main', 'stage');
    grid-row: 1 / span 1;
    grid-column: 1 / span 10;
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    margin-left: calc(-1 * #{map-get($containerPadding, 'default')});
    display: flex;
    align-items: center;

    @include mq(l) {
      margin-left: calc(-1 * #{map-get($containerPadding, 'l')});
      grid-column: 1 / span 5;
      grid-row: 1 / span 1;
    }
  }

  // main-content: inner margin & padding
  &__main-content {
    margin-left: map-get($containerPadding, 'default');
    padding: get-space(s) get-space(s) get-space(m) 0;

    @include mq(m) {
      padding: get-space(m) get-space(s) get-space(l) 0;
    }

    @include mq(l) {
      margin-left: map-get($containerPadding, 'l');
      padding: get-space(l) get-space(m) get-space(xl) 0;
    }

    @include mq(xl) {
      padding: get-space(l) get-space(m) get-space(xl);
    }
  }

  // ------------- image & video -------------
  &__video,
  &__image {
    grid-row: 2 / span 1;
    margin-top: calc(-1 * #{get-space(s)});
    position: relative;
    z-index: get-z-index('articleImage', 'stage');

    @include mq(l) {
      grid-row: 1 / span 2;
      margin-top: get-space(m);
    }

    img,
    video {
      width: 100%;
    }
  }

  &__video,
  &__image--landscape {
    grid-column: 2 / span 11;

    @include mq(l) {
      grid-column: 7 / span 5;
    }
  }

  &__image--portrait {
    margin-top: get-space(m);
    grid-column: 2 / span 5;

    @include mq(l) {
      grid-column: 8 / span 4;
      padding-bottom: 100px;
    }
  }

  // ------------- sub -------------
  // sub: grid dimensions
  &__sub {
    grid-row: 2 / span 1;
    grid-column: 2 / span 11;
    margin-top: get-space(m);
    padding-bottom: get-space(m);

    @include mq(l) {
      grid-row: 1 / span 2;
      grid-column: 7 / span 5;
      padding-bottom: get-space(l);
      margin-top: calc(#{get-space(xxl)} + #{get-space(m)});
    }
  }

  &--has-media &__sub {
    grid-row: 3 / span 1;

    @include mq(l) {
      margin-top: get-space(l);
      grid-row: 2 / span 1;
      grid-column: 3 / span 5;
    }
  }

  &__image--landscape + &__sub {
    @include mq(l) {
      grid-row: 4 / span 2;
    }
  }

  // spacer line
  &__spacer {
    border: 0 none;
    top: 12px;
    left: 0;
    height: 0;
    margin: 0;
    position: absolute;

    &::before {
      background: var(--color-neutral);
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      right: 0;
      margin-right: get-space(xs);
      width: 30px;

      @include mq(s) {
        width: 35px;
      }

      @include mq(m) {
        margin-right: 15px;
      }

      @include mq(l) {
        width: 40px;
        margin-right: get-space(s);
      }
    }
  }

  &__intro-text {
    @include text-type-subhead-1;

    margin-right: get-space(s);
  }

  &__meta {
    margin-bottom: get-space(xs);
  }

  &__info {
    margin-bottom: get-space(xs);

    @include mq(m) {
      margin-bottom: get-space(s);
    }
  }

  &__location {
    margin-bottom: get-space(xs);
  }
}

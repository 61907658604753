/**
 * If you dont want to use CSS Variables remove :root from _base.scss
 * and the following properties from text types
 *
 * font-size: var(--headline-1-font-size);
 * line-height: var(--headline-1-line-height);
 *
 * and add the following mixin to text type instead
 *
 * @include adaptive-size(get-type-config(headline-1));
 *
 */

@mixin text-type-headline-1 {
  font-display: swap;
  font-family: $font-family-serif;
  font-size: var(--headline-1-font-size);
  font-weight: 400;
  letter-spacing: var(--headline-1-letter-spacing);
  line-height: var(--headline-1-line-height);
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-headline-2 {
  font-display: swap;
  font-family: $font-family-serif;
  font-size: var(--headline-2-font-size);
  font-weight: 400;
  letter-spacing: var(--headline-2-letter-spacing);
  line-height: var(--headline-2-line-height);
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-headline-3 {
  font-display: swap;
  font-family: $font-family-serif;
  font-size: var(--headline-3-font-size);
  font-weight: 400;
  letter-spacing: var(--headline-3-letter-spacing);
  line-height: var(--headline-3-line-height);
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-headline-4 {
  font-display: swap;
  font-family: $font-family-default;
  font-size: var(--headline-4-font-size);
  font-weight: 700;
  letter-spacing: var(--headline-4-letter-spacing);
  line-height: var(--headline-4-line-height);
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-subhead-1 {
  font-display: swap;
  font-family: $font-family-default;
  font-size: var(--subhead-1-font-size);
  font-weight: 700;
  letter-spacing: var(--subhead-1-letter-spacing);
  line-height: var(--subhead-1-line-height);
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-overline {
  font-display: swap;
  font-family: $font-family-default;
  font-size: var(--overline-font-size);
  font-weight: 400;
  letter-spacing: var(--overline-letter-spacing);
  line-height: var(--overline-line-height);
  text-transform: uppercase;
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-link {
  font-display: swap;
  font-family: $font-family-default;
  font-size: var(--link-font-size);
  font-weight: 400;
  letter-spacing: var(--link-letter-spacing);
  line-height: var(--link-line-height);
  text-transform: uppercase;
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-copy-1 {
  font-display: swap;
  font-family: $font-family-default;
  font-size: var(--copy-1-font-size);
  font-weight: 400;
  letter-spacing: var(--copy-1-letter-spacing);
  line-height: var(--copy-1-line-height);
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-copy-2 {
  font-display: swap;
  font-family: $font-family-default;
  font-size: var(--copy-2-font-size);
  font-weight: 400;
  letter-spacing: var(--copy-2-letter-spacing);
  line-height: var(--copy-2-line-height);
  font-feature-settings: 'lnum' 1;
}

@mixin text-type-small {
  font-display: swap;
  font-family: $font-family-default;
  font-size: var(--small-font-size);
  font-weight: 400;
  letter-spacing: var(--small-letter-spacing);
  line-height: var(--small-line-height);
  font-feature-settings: 'lnum' 1;
}

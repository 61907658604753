.fund {
  &__max-width {
    @include max-width;
  }

  &__stage {
    position: relative;
    margin-top: get-space(s);
    background-color: var(--color-neutral-20);
    padding: get-space(m) 0;
    @include get-grid;
  }

  &__stage-main {
    grid-template-rows: auto auto auto 1fr;
    grid-column: 1 / span 12;
    padding: 0 get-space(s);

    @include mq(l) {
      padding: 0;
      grid-column: 2 / span 4;
    }
  }

  &__stage-sub {
    grid-template-rows: auto 1fr;
    grid-column: 1 / span 12;
    padding: 0 get-space(s);

    @include mq(l) {
      padding: 0;
      grid-column: 7 / span 5;
      display: flex;
      flex-direction: column;
    }
  }

  &__stage-sub-bottom {
    @include mq(l) {
      margin-top: auto;
    }
  }

  &__stage-meta,
  &__stage-asset-class {
    margin-bottom: get-space(xxs);

    @include mq(l) {
      margin: get-space(xs) 0;
    }
  }

  &__stage-headline {
    margin-bottom: get-space(xxs);

    @include mq(l) {
      margin-bottom: 0;
    }
  }

  &__stage-menubar {
    display: inline-block;
    position: relative;
    margin-top: get-space(xs);
    width: 100%;

    @include mq(l) {
      margin-top: get-space(s);
    }

    .menubar__dropdown {
      background-color: var(--color-neutral-20);
      border: 1px solid var(--color-neutral-90);

      // Custom arrow
      &:after {
        background-color: var(--color-neutral-90);
      }
    }

    .menubar__label {
      color: var(--color-neutral-90);
    }
  }

  &__stage-cta-profile {
    display: inline-block;
    margin-top: get-space(s);

    @include mq(l) {
      margin-top: get-space(m);
    }
  }

  &__stage-manager {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: get-space(s) 0;
    padding: 0 get-space(s);

    @include mq(l) {
      padding: 0;
      align-items: flex-end;
      align-self: flex-end;
    }
  }

  &__stage-manager-image {
    width: 100px;
    margin-left: get-space(s);

    @include mq(l) {
      margin-left: get-space(m);
      width: 160px;
      margin-top: -85px;
    }
  }

  &__stage-intro {
    width: 100%;
    grid-column: 2 / span 11;
    margin-bottom: get-space(s);

    @include mq(l) {
      margin-top: get-space(xs);
      grid-column: 6 / span 5;
      margin-bottom: get-space(m);
    }
  }

  &__stage-intro-text {
    margin-bottom: get-space(xs);
  }

  &__stage-ctas {
    width: 100%;
  }

  &__stage-intro-desc {
    @include text-type-subhead-1;
  }

  &__stage-cta-contact {
    background-color: var(--color-neutral-20);

    @include mq(m) {
      width: 100%;
    }

    @include mq(l) {
      width: auto;
    }
  }

  &__stage-cta-buy,
  &__stage-cta-contact {
    margin-bottom: get-space(xs);
    min-width: 100%;

    @include mq(l) {
      display: inline-flex;
      width: auto;
      min-width: auto;
    }

    &:not(:last-child) {
      @include mq(l) {
        margin-right: get-space(xs);
      }
    }
  }

  &__stage-cta-buy .menubar__dropdown {
    border-color: var(--color-primary);
  }

  &__stage-cta-buy .menubar__label {
    min-width: 100%;

    @include mq(l) {
      min-width: auto;
    }
  }

  // Main Content
  &__main {
    .blocks__block:first-child {
      margin-top: get-space(m);

      @include mq(l) {
        margin-top: get-space(xl);
      }
    }

    .blocks__block[data-type='fundColumns'] {
      .columns__column--text {
        grid-row: 2 / span 3;
      }

      .ratings {
        @include mq(l) {
          padding-left: get-space(m);
        }
      }

      .awards__headline {
        @include mq(l) {
          padding-left: get-space(s);
        }
      }

      .awards__wrapper {
        @include get-grid(
          (
            default: 12,
            m: 6,
            l: 4
          )
        );
      }

      .awards__award {
        &:nth-child(2n + 1) {
          grid-column: 1 / span 6;

          @include mq(s) {
            grid-column: 1 / span 5;
          }

          @include mq(m) {
            grid-column: 1 / span 3;
          }

          @include mq(l) {
            grid-column: 1 / span 2;
          }
        }

        &:nth-child(2n + 2) {
          grid-column: 7 / span 6;

          @include mq(s) {
            grid-column: 7 / span 5;
          }

          @include mq(m) {
            grid-column: 4 / span 3;
          }

          @include mq(l) {
            grid-column: 3 / span 2;
          }
        }
      }

      .awards__image {
        padding-right: get-space(xs);
        margin-bottom: get-space(xs);

        @include mq(m) {
          padding-right: get-space(s);
          margin-bottom: get-space(s);
        }

        @include mq(l) {
          padding-right: 0;
          padding-left: get-space(s);
        }
      }
    }
  }

  // Tabs
  &__tabs {
    margin: get-space(m) 0;
    @include get-grid;

    @include mq(l) {
      margin: get-space(xl) 0;
    }

    .tabs__wrapper {
      grid-column: 1 / span 12;

      @include mq(l) {
        grid-column: 1 / span 12;
      }

      @include mq(xl) {
        grid-column: 2 / span 10;
      }
    }

    .tabs__tabpanel {
      margin: 0;
      grid-column: 1 / span 12;
    }

    .richtext--has-grid .richtext__wrapper {
      @include mq(l) {
        grid-column: 2 / span 8;
      }

      @include mq(xl) {
        grid-column: 2 / span 6;
      }
    }

    .richtext h2,
    .contact h2 {
      margin-bottom: get-space(s);

      @include mq(l) {
        margin-bottom: get-space(m);
      }
    }

    .table h2 {
      display: inline-block;
      color: var(--color-neutral);
      margin-bottom: get-space(xs);
      @include text-type-headline-2;

      @include mq(l) {
        margin-bottom: get-space(m);
      }
    }

    .table p {
      @include text-type-copy-2;

      margin-top: get-space(s);
    }

    // Monthly performance table custom styles
    .table[data-table-custom-type='monthly-performance'] {
      th {
        font-weight: 700;
      }

      th,
      td {
        text-align: right;
      }

      tr th:first-child,
      tr td:first-child {
        text-align: left;
      }

      tr th:last-child,
      tr td:last-child {
        color: var(--color-primary);
      }
    }

    .download--has-grid .download__headline,
    .download--has-grid .download__wrapper {
      @include mq(l) {
        grid-column: 2 / span 10;
      }
    }

    .contact__headline {
      @include mq(l) {
        grid-column: 2 / span 11;
      }
    }

    .contact__contact {
      @include get-grid(
        (
          default: 12,
          l: 3
        )
      );

      &:nth-child(3n + 1) {
        @include mq(l) {
          grid-column: 2 / span 3;
        }
      }

      &:nth-child(3n + 2) {
        @include mq(l) {
          grid-column: 5 / span 3;
        }
      }

      &:nth-child(3n + 3) {
        @include mq(l) {
          grid-column: 8 / span 3;
        }
      }
    }
  }

  // ColumnsFund Overwrites

  // Footnotes
  &__notes {
    margin-bottom: get-space(m);
    @include get-grid;

    @include mq(l) {
      margin-bottom: get-space(l);
    }
  }

  &__note {
    grid-column: 1 / span 12;
    margin-bottom: get-space(s);

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }
}

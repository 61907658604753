@import '../../tokens/colors/colors';

$themeColorMap: (
  'orange': (
    'neutral': 'neutral',
    'primary': 'corporate',
    'accent-1': 'corporate-accent',
    'negative': 'negative',
    'positive': 'positive',
    'warning': 'warning',
    'progress': 'progress'
  ),
  'green': (
    'neutral': 'neutral',
    'primary': 'product',
    'accent-1': 'product-accent',
    'negative': 'negative',
    'positive': 'positive',
    'warning': 'warning',
    'progress': 'progress'
  )
);

// Convert a hex value to comma-delimited rgb values
// In: #000
// Out: 0,0,0

@function derive-rgb-values-from-hex($hex) {
  @return red($hex), green($hex), blue($hex);
}

@mixin colors-to-variables($theme) {
  @if map-has-key($themeColorMap, $theme) {
    // get theme colors
    $themeColors: map-get($themeColorMap, $theme);

    @each $colorKey in map-keys($themeColors) {
      // for each color get mapped color
      $mappedColor: map-get($themeColors, $colorKey);

      @if map-has-key($colors, $mappedColor) {
        // get color map from _colors.scss
        $colorMap: map-get($colors, $mappedColor);

        @each $step in map-keys($colorMap) {
          @if $step == 'default' {
            $defaultColor: map-get($colorMap, 'default');
            $colorHex: map-get($colorMap, $defaultColor);

            // return default color
            --color-#{$colorKey}: #{$colorHex};

            @if $colorKey == 'neutral' or $colorKey == 'primary' {
              // get transparency for default color of "neutral" and "primary"
              $aphaValues: (10, 20, 30, 40, 50, 60, 70, 80, 90);
              $colorRGB: derive-rgb-values-from-hex($colorHex);

              @each $alphaValue in $aphaValues {
                --color-#{$colorKey}-alpha#{$alphaValue}: rgba(#{$colorRGB}, #{$alphaValue * 0.01});
              }
            }
          } @else {
            // return step color
            --color-#{$colorKey}-#{$step}: #{map-get($colorMap, $step)};
          }
        }
      } @else {
        @error "ERROR: #{$mappedColor} does not exist in #{$colors} (in _colors.scss)";
      }
    }
  } @else {
    @error "ERROR: #{$theme} does not exist in #{$colorMapping}";
  }
}

.theme {
  &--orange {
    @include colors-to-variables('orange');
  }

  &--green {
    @include colors-to-variables('green');
  }
}

.tombstones {
  @include get-grid;

  &__content {
    grid-column: 1 / span 12;
    margin: get-space(m) 0;
    display: flex;
    flex-direction: column;

    @include mq(s) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include mq(l) {
      margin-bottom: get-space(l);
      grid-column: 2 / span 10;
      margin-top: calc(-1 * #{get-space(xl)});
    }
  }

  &__tombstone {
    cursor: pointer;
    background-color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral-20);
    margin-bottom: get-space(xs);
    padding: get-space(m) get-space(l);
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-neutral);

    @include mq(s) {
      margin-left: get-space(xs);
      width: calc((100% - (#{get-space(xs)} * 1)) / 2);
    }

    @include mq(l) {
      padding: get-space(m) get-space(l);
      width: calc((100% - (#{get-space(xs)} * 2)) / 3);
    }

    @include mq(xl) {
      width: calc((100% - (#{get-space(xs)} * 3)) / 4);
    }

    &:nth-child(2n + 1) {
      @include mq(s) {
        margin-left: 0;
      }

      @include mq(l) {
        margin-left: get-space(xs);
      }
    }

    &:nth-child(3n + 1) {
      @include mq(l) {
        margin-left: 0;
      }

      @include mq(xl) {
        margin-left: get-space(xs);
      }
    }

    &:nth-child(4n + 1) {
      @include mq(xl) {
        margin-left: 0;
      }
    }
  }

  &__tombstone-footer {
    width: 100%;
    padding-top: get-space(m);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }

  &__image-wrapper {
    margin-bottom: get-space(xs);
    height: 50px;
    width: 100%;
    max-width: 200px;
  }

  &__image-inner {
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    img {
      max-height: 50px;
      margin: auto;
    }
  }

  &__transaction-type {
    position: relative;
    margin-bottom: get-space(m);

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(-1 * #{get-space(xs)});
      height: 1px;
      width: 48px;
      background-color: var(--color-neutral-40);
    }
  }

  &__date,
  &__transaction-type {
    text-transform: uppercase;
  }

  &__flag {
    height: 20px;
  }
}

.stage-home {
  position: relative;
  margin-top: get-space(s);
  margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
  margin-left: calc(-1 * #{map-get($containerPadding, 'default')});

  @include mq(l) {
    margin-right: 0;
    margin-left: 0;
  }

  &__container {
    display: flex;
    padding-right: map-get($containerPadding, 'default');

    [data-whatintent='touch'] & {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include mq($until: l) {
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
    }

    @include mq(l) {
      padding-right: 0;
      @include get-grid;
    }
  }

  &__teaser {
    @include get-grid;

    position: relative;
    overflow: hidden;
    cursor: pointer;
    grid-template-rows: auto 1fr;
    padding: 0 0 0 map-get($containerPadding, 'default');
    width: 100%;
    flex: 0 0 95%;
    scroll-snap-align: start;

    @include mq(s) {
      flex: 0 0 49%;
    }

    @include mq(l) {
      padding: get-space(s);
      background-color: var(--color-neutral-10);
      transition: background-color ease-in-out 0.5s;
    }

    &:nth-child(3n + 1) {
      @include mq(l) {
        grid-column: 1 / span 4;
      }
    }

    &:nth-child(3n + 2) {
      @include mq(l) {
        grid-column: 5 / span 4;
      }
    }

    &:nth-child(3n + 3) {
      @include mq(l) {
        grid-column: 9 / span 4;
      }
    }
  }

  &__teaser--hover {
    @include mq(l) {
      background-color: var(--color-neutral-20);
    }
  }

  &__image-wrapper {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
    height: 0;
    padding-bottom: calc(100% / (7 / 7));
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    @include mq(l) {
      padding-bottom: calc(100% / (7 / 7));
      transition: padding-bottom ease-in-out 0.5s;
    }

    @include mq(widescreen) {
      padding-bottom: calc(100% / (7 / 9));
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  &__image-wrapper--hover {
    @include mq(l) {
      padding-bottom: calc(100% / (7 / 5));
    }

    @include mq(widescreen) {
      padding-bottom: calc(100% / (7 / 7));
    }
  }

  &__teaser-number {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--color-neutral-10);
    width: 50px;
    height: 50px;
    transition: background-color ease-in-out 0.5s, color ease-in-out 0.5s;

    @include mq(l) {
      width: 60px;
      height: 60px;
    }

    span {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
    }
  }

  &__teaser:hover &__teaser-number {
    @include mq(l) {
      color: var(--color-neutral-10);
      background-color: var(--color-primary);
    }
  }

  &__info {
    padding-top: get-space(s);
    grid-column: 1 / span 11;
    grid-row: 2 / span 2;
    display: flex;
    flex-direction: column;

    @include mq(l) {
      grid-column: 1 / span 12;
    }
  }

  &__info-overline {
    display: inline-block;
    padding-bottom: get-space(xxs);
  }

  &__info-title {
    display: inline-block;
  }

  &__info-copy {
    padding-top: get-space(xs);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include mq(l) {
      transition: opacity ease-in-out 0.2s;
      padding-top: get-space(xs);
      display: none;
    }
  }

  &__info-copy--animating {
    @include mq(l) {
      opacity: 0;
      display: -webkit-box;
    }
  }

  &__info-copy--visible {
    @include mq(l) {
      opacity: 1;
      display: -webkit-box;
    }
  }

  &__link {
    display: inline-block;
    padding-top: get-space(xxs);
    padding-bottom: get-space(xs);

    @include mq(l) {
      margin-top: auto;
      padding-bottom: 0;
    }
  }

  &__link-text {
    text-transform: uppercase;
    letter-spacing: 1px !important;
  }

  &__link-svg {
    margin-right: get-space(xxs);
    fill: var(--color-primary) !important;
    width: 13px;
    height: 13px;
  }
}

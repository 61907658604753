.stage-simple {
  position: relative;
  margin-top: get-space(s);
  background-color: var(--color-neutral-20);
  padding: get-space(m) 0;
  @include get-grid;

  @include mq(l) {
    margin-top: get-space(m);
    padding: get-space(xl) 0;
  }

  &--sub-in-overlap {
    padding: get-space(xs) 0 0;

    @include mq(l) {
      padding: get-space(s) 0 0;
    }
  }

  &--sub-in-overlap:after {
    display: none;

    @include mq(l) {
      display: block;
      content: '';
      background-color: var(--color-neutral-10);
      width: 100%;
      height: calc(2 * #{get-space(xxl)});
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 0;
    }
  }

  &--has-white-overlap:after {
    display: none;
    grid-column: 1 / span 12;

    @include mq(l) {
      display: block;
      content: '';
      position: relative;
      background: var(--color-neutral-10);
      bottom: calc(-1 * #{get-space(xl)});
      height: get-space(xl);
      grid-column: 2 / span 10;
    }
  }

  // ---------- MAIN ----------
  &__main {
    grid-template-rows: auto 1fr;
    grid-column: 1 / span 12;
    @include get-grid(
      (
        default: 12,
        l: 4
      )
    );

    @include mq(l) {
      grid-column: 2 / span 4;
    }
  }

  &__main:last-child {
    @include mq(l) {
      grid-column: 2 / span 6;
    }
  }

  &--has-overlap &__main {
    @include mq(l) {
      margin-bottom: get-space(xl);
    }
  }

  &--center &__main {
    display: block;
    text-align: center;

    @include mq(l) {
      margin-bottom: 0;
      grid-column: 3 / span 8;
    }
  }

  &--sub-in-overlap &__main {
    margin-bottom: get-space(m);

    @include mq(l) {
      margin-bottom: get-space(xl);
    }
  }

  // HEADLINE
  &__headline {
    padding: 0 get-space(s);
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 1 / span 4;
      padding: 0;
    }
  }

  // META
  &__meta {
    margin-bottom: get-space(l);
    padding: 0 get-space(s);
    grid-column: 1 / span 12;

    @include mq(l) {
      padding: 0;
      margin-bottom: get-space(xl);
    }
  }

  &--center &__meta {
    text-align: right;

    @include mq(l) {
      padding: 0 get-space(m);
    }
  }

  // OVERLINE
  &__overline {
    margin-bottom: get-space(xxs);
    padding: 0 get-space(s);
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 4;
      padding: 0;
      margin-bottom: get-space(xs);
    }
  }

  &--center &__overline {
    text-align: center;

    @include mq(l) {
      grid-column: 3 / span 8;
      margin-bottom: get-space(s);
    }
  }

  // SUBLINE
  &__subline {
    padding: 0 get-space(s);
    margin-top: get-space(xxs);

    @include mq(l) {
      padding: 0;
      margin-top: get-space(s);
    }
  }

  &--center &__subline {
    width: 100%;
    text-align: center;
  }

  // LINK
  &__link {
    display: inline-block;
    margin-top: get-space(m);
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 1 / span 4;
    }
  }

  // MENUBAR
  &__menubar {
    position: relative;
    margin-top: get-space(s);
    grid-column: 1 / span 10;
    padding-left: get-space(s);

    @include mq(s) {
      grid-column: 1 / span 8;
    }

    @include mq(m) {
      margin-top: get-space(m);
      grid-column: 1 / span 4;
    }

    @include mq(l) {
      padding: 0;
      grid-column: 1 / span 3;
    }

    @include mq(xl) {
      grid-column: 1 / span 2;
    }

    .menubar__dropdown {
      background-color: var(--color-neutral-20);
      border: 1px solid var(--color-neutral-90);

      // Custom arrow
      &:after {
        background-color: var(--color-neutral-90);
      }
    }

    .menubar__label {
      color: var(--color-neutral-90);
    }
  }

  // ---------- SUB ----------
  &__sub {
    grid-template-rows: auto 1fr;
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 7 / span 5;
    }
  }

  &--center &__sub {
    text-align: center;
    padding-top: get-space(xs);

    @include mq(l) {
      padding-top: get-space(s);
      grid-column: 3 / span 8;
    }
  }

  &--has-overlap &__sub {
    @include mq(l) {
      margin-bottom: get-space(xl);
    }
  }

  &--sub-in-overlap &__sub {
    text-align: left;
    padding: get-space(s);
    background: var(--color-neutral-10);
    @include get-grid(
      (
        default: 12,
        l: 10
      )
    );

    @include mq(l) {
      position: relative;
      z-index: 1;
      padding: get-space(l) 0 get-space(xl);
      grid-column: 2 / span 10;
      margin-bottom: get-space(xxl);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--color-neutral-20);

      @include mq(l) {
        right: get-space(m);
        left: get-space(m);
      }
    }
  }

  // INTRO
  &__intro {
    padding: get-space('xs') get-space('s') 0;
    @include text-type-subhead-1;

    @include mq(l) {
      padding: 0;
    }
  }

  &--sub-in-overlap &__intro {
    grid-column: 1 / span 12;
    padding-right: 0;
    padding-left: 0;

    @include mq(l) {
      grid-column: 4 / span 6;
      padding: 0;
    }
  }

  &--center &__info {
    grid-column: 1 / span 12;

    @include mq(l) {
      padding: 0 get-space(s) 0 get-space(m);
      grid-column: 1 / span 3;
    }
  }
}

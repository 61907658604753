$gridMaxWith: 1440px;

$gridConfigs: (
  default: 12,
  l: 12
);

$gutterConfigs: (
  default: 6px,
  l: 12px
);

$containerPadding: (
  default: 28px,
  l: 60px
);

// centered container with max-width

@mixin max-width {
  margin: 0 auto;
  max-width: $gridMaxWith;
  width: 100%;
  overflow: hidden;

  // right & left padding

  @each $breakpoint in map-keys($containerPadding) {
    $padding: map-get($containerPadding, $breakpoint);

    @if $breakpoint == 'default' {
      padding: 0 $padding;
    } @else {
      @include mq($breakpoint) {
        padding: 0 $padding;
      }
    }
  }
}

@mixin get-grid-columns($customConfig) {
  @each $breakpoint in map-keys($customConfig) {
    $columns: map-get($customConfig, $breakpoint);

    @if $breakpoint == 'default' {
      grid-template-columns: repeat($columns, 1fr);
    } @else {
      @include mq($breakpoint) {
        grid-template-columns: repeat($columns, 1fr);
      }
    }
  }
}

@mixin get-grid-gutters() {
  @each $breakpoint in map-keys($gutterConfigs) {
    $gutters: map-get($gutterConfigs, $breakpoint);

    @if $breakpoint == 'default' {
      grid-column-gap: $gutters;
    } @else {
      @include mq($breakpoint) {
        grid-column-gap: $gutters;
      }
    }
  }
}

// get grid container

@mixin get-grid($customConfig: $gridConfigs) {
  display: grid;

  @include get-grid-columns($customConfig);
  @include get-grid-gutters();
}

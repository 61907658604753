.table {
  position: relative;

  &--has-grid {
    @include get-grid;
  }

  &__headline.text {
    color: var(--color-primary);
    margin-bottom: get-space(s);
  }

  &--has-grid &__headline {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 6;
    }
  }

  &__container {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &--has-grid &__container {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  table {
    min-width: 100%;
  }

  &--is-html table {
    text-align: left;
  }

  th,
  td {
    padding: get-space(xs) get-space(s) get-space(xs) 0;
    word-break: keep-all;
    text-align: left;

    span {
      line-height: 1;
    }

    strong {
      display: block;
      font-weight: 700;
    }

    em {
      color: var(--color-primary);
    }
  }

  thead {
    border-bottom: 1px solid var(--color-neutral-alpha50);

    @media (max-width: 767px) {
      border-top: 1px solid var(--color-neutral-alpha50);
    }
  }

  tbody tr {
    border-bottom: 1px solid var(--color-neutral-alpha20);

    td {
      &:first-child {
        min-width: 100px;
      }

      // if two columns width should be 50%
      &:nth-child(2):last-child {
        width: 50%;
      }
    }
  }
}

.table__headline::before {
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    bottom: 0;
    top: 53px;
    right: 0;
    width: 5%;
    height: auto;
    min-height: 1px;
    background: linear-gradient(to right, transparent, var(--color-neutral-alpha20));
  }
}

.table--noheadline::before {
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0px;
    right: 0;
    width: 5%;
    height: auto;
    min-height: 1px;
    background: linear-gradient(to right, transparent, var(--color-neutral-alpha20));
  }
}

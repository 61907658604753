.tooltip {
  vertical-align: top;
  display: inline-block;

  &__trigger {
    position: relative;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-left: get-space(xxs);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 20px 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='27' height='27' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 27'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M13.5 1C20.404 1 26 6.596 26 13.5S20.404 26 13.5 26 1 20.404 1 13.5 6.596 1 13.5 1z' stroke='%23AEAEAE' stroke-width='1.5'/%3E%3Cpath d='M15.31 10.443h-2.825l-1.332 6.695c-.051.265-.09.511-.115.739a5.657 5.657 0 00-.038.627c0 .441.054.818.163 1.131.109.313.257.57.445.774.188.204.411.354.666.45a2.366 2.366 0 001.58.014c.25-.085.473-.216.67-.393.198-.176.363-.4.494-.666.13-.269.21-.59.235-.964a2.21 2.21 0 01-.23.092c-.083.027-.218.041-.403.041-.274 0-.475-.056-.603-.168-.128-.113-.191-.314-.191-.606 0-.11.005-.22.014-.332.01-.112.03-.227.062-.341l1.409-7.093zM14.429 6c-.217 0-.42.044-.608.133a1.58 1.58 0 00-.494.362 1.71 1.71 0 00-.45 1.177c0 .231.04.446.12.647a1.568 1.568 0 00.824.866c.188.084.391.127.608.127.217 0 .42-.043.608-.127A1.623 1.623 0 0016 7.672c0-.232-.042-.447-.125-.647a1.736 1.736 0 00-.34-.53 1.635 1.635 0 00-.498-.362A1.41 1.41 0 0014.429 6z' fill='%23262626'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &__tip {
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    z-index: get-z-index('tooltip');
    display: inline-block;
    background: var(--color-neutral-20);
    padding: get-space(xxs) get-space(xs);
    max-width: 83%;

    @include mq(m) {
      max-width: 320px;
    }

    &-show {
      opacity: 1;
      visibility: visible;
    }
  }

  &__arrow,
  &__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  &__arrow {
    visibility: hidden;
  }

  &__arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &__tip[data-popper-placement^='top'] > &__arrow {
    bottom: -4px;
  }

  &__tip[data-popper-placement^='bottom'] > &__arrow {
    top: -4px;
  }

  &__tip[data-popper-placement^='left'] > &__arrow {
    right: -4px;
  }

  &__tip[data-popper-placement^='right'] > &__arrow {
    left: -4px;
  }
}

.faq {
  @include get-grid;

  &__headline {
    grid-column: 2 / span 10;
    margin-bottom: get-space(xs);

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  &__container {
    position: relative;
    grid-column: 2 / span 10;
    @include get-grid(
      (
        default: 12,
        l: 10
      )
    );

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  &__column {
    grid-column: 1 / span 12;

    &--left {
      @include mq(l) {
        grid-column: 1 / span 5;
        padding-right: get-space(s);
      }
    }

    &--right {
      @include mq(l) {
        grid-column: 6 / span 5;
        padding-left: get-space(s);
      }
    }
  }
}

.radio-group {
  width: 100%;

  &__group-label {
    display: block;
    color: var(--color-neutral);
    margin-bottom: get-space('xs');
    width: 100%;
  }

  &--inline &__options {
    display: flex;
    flex-wrap: wrap;
  }

  &__radio {
    display: block;
    position: relative;

    &:not(:last-child) {
      margin-bottom: get-space('xs');
    }
  }

  &--inline &__radio {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: get-space('s');
    }
  }

  // hide native radio button
  &__input {
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
  }

  // label
  &__input + &__label {
    display: inline-flex;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;
  }

  // disabled label
  &__input:disabled + &__label {
    cursor: default;
    pointer-events: none;
    color: var(--color-neutral-40);
  }

  // custom input - basic style
  &__input + &__label::before {
    content: '';
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--color-neutral-10);
    border: 1px solid var(--color-neutral);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: get-space(xs);

    @include mq(l) {
      top: 4px;
    }
  }

  // :disabled for custom input
  &__input:disabled + &__label::before {
    border-color: var(--color-neutral-40);
  }

  // error custom input
  &.form-error &__label::before {
    border: 2px solid var(--color-negative);
  }

  // :checked
  &__input:checked + &__label::before {
    box-shadow: none;
    border-color: var(--color-primary);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Ccircle cx='9' cy='9' r='8' fill='%23c6562c'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
    background-size: 8px;
  }

  // :focus
  &__input:active + &__label::before,
  &__input:focus + &__label::before {
    border: 2px solid var(--color-neutral);
  }

  // :checked:active
  &__input:checked:active + &__label::before,
  &__input:checked:focus + &__label::before {
    border: 2px solid var(--color-primary);
  }

  &__required.text {
    margin-top: get-space(xxs);
  }

  &.form-error &__required {
    color: var(--color-negative);
  }

  // &__error.text {
  //   display: none;
  //   color: var(--color-negative);
  // }

  // &.form-error &__error {
  //   display: block;
  // }
}

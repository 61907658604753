.faq-container {
  margin-top: get-space(l);
  margin-bottom: get-space(l);

  @include mq(l) {
    margin-top: 0;
    margin-bottom: get-space(xl);
  }

  // white overlap
  &:before {
    display: none;
    grid-column: 1 / span 12;

    @include mq(l) {
      position: absolute;
      display: block;
      content: '';
      background: var(--color-neutral-10);
      top: calc(-1 * #{get-space(xl)});
      height: get-space(xl);
      grid-column: 2 / span 10;
      left: get-space(l);
      right: get-space(l);
    }
  }

  // TOP FAQS
  &__top {
    padding-bottom: get-space(xl);

    @include mq(l) {
      padding-bottom: get-space(xxl);
    }
  }

  // FAQ NAVIGATION
  &__navigation {
    position: relative;
    margin-bottom: get-space(m);

    @include get-grid;

    @include mq(l) {
      // padding-bottom: get-space(xl);
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      border-top: 24px solid var(--color-primary);
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__navigation-inner {
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    grid-column: 1 / span 12;
    padding: get-space(s);

    @include mq(l) {
      grid-column: 3 / span 8;
      padding: get-space(xl) get-space(xl);
    }
  }

  &__navigation-header {
    text-align: center;
    margin-bottom: get-space(xs);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include mq(l) {
      margin-bottom: get-space(m);
    }
  }

  &__navigation-header-before,
  &__navigation-header-after {
    display: inline-block;
    margin: 0 get-space(xs) 7px;

    @include mq(l) {
      margin: 0 get-space(s) 13px;
    }
  }

  &__menubar {
    display: inline-block;
    margin-bottom: get-space(xxs);

    @include mq(l) {
      margin-bottom: get-space(xs);
    }

    .menubar__label {
      @include text-type-headline-1;
    }
  }

  &__navigation-category {
    flex-wrap: wrap;
    justify-content: center;

    &:not([hidden]) {
      display: flex;
    }
  }

  &__link {
    padding: get-space(xs);
  }

  &__section-category {
    &[hidden] {
      display: none;
    }
  }

  // FAQ CATEGORY SECTIONS
  &__section-category .faq {
    padding-top: get-space(xl);

    @include mq(l) {
      padding-top: get-space(xxl);
    }
  }
}

.teaser-row {
  position: relative;
  @include get-grid;

  &__headline,
  &__copy {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 8;
      text-align: center;
    }
  }

  &__copy {
    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }

  &__copy:not(:first-child) {
    margin-top: get-space(s);
  }

  &__teasers {
    position: relative;
    grid-column: 1 / span 12;
    display: flex;

    &:not(:first-child) {
      margin-top: get-space(m);

      @include mq(l) {
        margin-top: get-space(l);
      }
    }

    [data-whatintent='touch'] & {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include mq($until: l) {
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
    }

    @include mq(l) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__teaser {
    position: relative;
    margin: 0 get-space(xxs);
    padding: get-space(l) get-space(m);
    transition: background-color ease-in-out 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    flex: 0 0 80%;
    scroll-snap-align: center;

    @include mq(s) {
      width: 55%;
      flex: 0 0 55%;
    }

    @include mq(l) {
      margin: 0 get-space(xxs) get-space(xs);
      max-width: 30%;
      flex: 1 1 auto;
    }

    &-image-wrapper,
    &-icon-wrapper,
    &-content {
      margin-bottom: get-space(s);
    }

    &-icon-wrapper {
      position: relative;
      width: 100%;
      height: 100px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    &-copy {
      margin-top: get-space(xxs);

      ul,
      ol {
        text-align: left;
      }
    }

    &-read-more.text {
      margin: 0 auto;
      color: var(--color-primary);
      text-decoration: underline;
      text-underline-offset: 7px;
      text-decoration-thickness: 1px;
    }
  }

  &--align-centered &__teaser {
    text-align: center;
  }

  a.teaser-row__teaser {
    &:hover,
    &:focus {
      .teaser-row__teaser-read-more {
        color: var(--color-accent-1-80);

        &::after {
          background: var(--color-accent-1-80);
        }
      }
    }

    &:active {
      .teaser-row__teaser-read-more {
        color: var(--color-accent-1);

        &::after {
          width: 100%;
          background: var(--color-accent-1);
        }
      }
    }
  }

  &__teaser--has-background {
    background-color: var(--color-neutral-20);

    &:is(a):hover,
    &:is(a):focus {
      background-color: var(--color-neutral-30);
    }
  }
}

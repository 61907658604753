.gallery {
  &__headline {
    padding-bottom: get-space(m);
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  &__wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: get-space(s);
  }

  &__picture {
    align-self: center;
    justify-self: center;
  }

  &__image img {
    width: 100vw;
    height: 150px;
    object-fit: contain;
  }
}

.fund-disclosures {
  @include get-grid;

  &__content {
    background-color: var(--color-neutral-10);
    grid-column: 1 / span 12;
    margin-bottom: get-space(s);
    padding: get-space(s) 0 get-space(m);

    @include mq(l) {
      grid-column: 2 / span 10;
      margin-left: calc(-1 * #{get-space(l)});
      margin-right: calc(-1 * #{get-space(l)});
      margin-bottom: get-space(l);
      margin-top: calc(-1 * #{get-space(xl)});
      padding: get-space(m) get-space(l);
      // margin-top: calc(-1 * #{get-space(xl)});
    }
  }

  &__table td strong {
    text-transform: uppercase;
  }

  &__pagination {
    grid-column: 1 / span 12;
    display: flex;
    justify-content: flex-end;
    margin-bottom: get-space(xl);
    margin-top: get-space(m);
    width: 100%;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }
}

.podcast {
  position: relative;
  @include get-grid;

  &__container {
    background-color: var(--color-neutral-20);
    padding: get-space(s) 0;
    grid-column: 2 / span 10;
    @include get-grid(
      (
        default: 10,
        l: 8
      )
    );

    @include mq(l) {
      grid-column: 3 / span 8;
    }
  }

  &__container-inner {
    grid-column: 2 / span 8;

    @include mq(l) {
      grid-column: 2 / span 6;
    }
  }

  &__headline {
    padding-bottom: get-space(xxs);
  }
}

.compliance-disclosures {
  @include get-grid;

  &__content {
    background-color: var(--color-neutral-10);
    grid-column: 1 / span 12;
    margin: get-space(m) 0;
    padding: 0;
    @include get-grid(
      (
        default: 12,
        l: 10
      )
    );

    @include mq(l) {
      margin-bottom: 0;
      grid-column: 2 / span 10;
      padding: get-space(xl) 0;
      margin-top: calc(-1 * #{get-space(xl)});
    }
  }

  // overwrite styles
  #companydisclosures {
    grid-column: 2 / span 10;

    @include mq(l) {
      grid-column: 3 / span 6;
    }

    .char-container h1 {
      margin-bottom: get-space(s);
      @include text-type-headline-3;
    }

    a {
      color: var(--color-neutral);
      transition: color ease-in-out 0.1s;
    }

    a:hover,
    a:focus {
      color: var(--color-primary);
    }

    .char-container h1 a {
      font-size: 20px;
      color: var(--color-primary) !important;
      transition: color ease-in-out 0.1s;
      padding: 0 get-space(xxs);
      float: none;
    }

    h1 a:hover,
    h1 a:focus {
      color: var(--color-accent-1-80);
    }

    h1 a:active {
      color: var(--color-accent-1);
    }

    #mainContent > h1 {
      display: none;
    }

    .mainText > p {
      margin-bottom: get-space(s);
      @include text-type-headline-3;
    }

    .mainText > ul {
      padding-bottom: get-space(xs);
      border-bottom: 1px solid var(--color-neutral-30);
    }

    .mainText > ul li {
      display: inline-block;
      margin: 0 get-space(s) get-space(xs) 0;
    }

    .mainText > ul li a {
      position: relative;
      display: inline-block;
      padding: 0 get-space(xs) get-space(xs) 0;
      color: var(--color-primary);
      transition: color ease-in-out 0.1s;
      @include text-type-subhead-1;

      &::after {
        @include text-type-headline-3;

        display: block;
        opacity: 0;
        position: absolute;
        content: '\2193';
        right: -5px;
        top: 0;
        font-size: 20px;
        color: var(--color-neutral);
        transition: opacity ease-in-out 0.15s;
      }

      &:hover,
      &:focus {
        color: var(--color-accent-1-80);
      }

      &:hover::after {
        opacity: 1;
      }

      &:active {
        color: var(--color-accent-1);
      }
    }

    .char-container span {
      position: relative;
      top: 0;
      display: block;
      padding: 55px 0;
    }
  }
}

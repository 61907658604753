.modal {
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: get-z-index('modal');

  @include mq(m) {
    background: var(--color-neutral-alpha80);
  }

  &__wrapper {
    // to bubble click on wrapper to .modal background

    pointer-events: none;
    display: flex;
    height: 100vh;
    width: 100%;

    /* workaround for flexbugs #3 (IE does not align-items with min-height) */
    &::before {
      @include mq(m) {
        content: '';
        display: block;
        height: calc(100vh - #{2 * get-space(l)});
      }
    }
  }

  &--has-grid &__wrapper {
    height: auto;
    width: auto;
    min-height: 100%;

    @include mq(m) {
      align-items: center;
      @include max-width;
    }
  }

  &__content {
    pointer-events: all;
    background: var(--color-neutral-10);
    position: relative;
    display: flex;
    flex-direction: column;
    padding: get-space(s) map-get($containerPadding, 'default');
    width: 100%;

    @include mq(m) {
      padding: get-space(m);
    }
  }

  &--has-grid &__content {
    padding: get-space(m) map-get($containerPadding, 'default');

    @include mq(m) {
      @include get-grid;
      padding: get-space(xl) 0;
    }
  }

  &--has-grid &__header,
  &--has-grid &__body,
  &--has-grid &__footer {
    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__header {
    margin-right: get-space(s);
    margin-bottom: get-space(xs);

    @include mq(m) {
      margin-right: 0;
    }
  }

  &--has-grid &__header {
    margin-bottom: get-space(m);

    @include mq(m) {
      margin-bottom: get-space(m);
    }
  }

  &__close {
    position: absolute;
    right: get-space(xs);
    top: get-space(xs);

    @include mq(m) {
      right: get-space(s);
      top: get-space(s);
    }

    .svg-sprite {
      display: block;
      height: 30px;
      width: 30px;

      @include mq(l) {
        height: 40px;
        width: 40px;
      }
    }
  }

  &__body {
    overflow-y: auto;
    overflow-y: none;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &--has-grid &__body {
    overflow: visible;
    display: static;
  }

  &__footer {
    margin-top: get-space(m);

    @include mq(m) {
      display: flex;
    }
  }

  &__cta {
    margin-bottom: get-space(xs);

    &:last-child {
      margin-bottom: 0;
    }

    @include mq(m) {
      margin-bottom: 0;
      margin-right: get-space(s);
    }
  }
}

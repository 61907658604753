.pagination {
  &_list {
    align-items: center;
    display: inline-flex;
    list-style-type: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
  }

  &__item {
    display: none;
    position: relative;
    width: 36.5px;
    height: 36.5px;
    margin: 0;
    padding: 0;

    &:before {
      content: '';
    }

    & + & {
      margin-left: get-space(xxs);
    }

    @include mq(m) {
      display: inline-block;
      width: 48px;
      height: 48px;
    }

    // allways show the left and right arrow
    &:first-child,
    &:last-child {
      display: inline-block;
    }

    &--show-on-mobile {
      display: inline-block;
    }

    &--is-current a {
      color: var(--color-neutral-10);
      border-color: var(--color-primary);
      background: var(--color-primary);

      &:focus,
      &:hover {
        color: var(--color-neutral-10);
        border-color: var(--color-accent-1-80);
        background-color: var(--color-accent-1-80);
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    line-height: get-space(l);
    transition: color 150ms ease-in-out;
    border: 1px solid var(--color-neutral-100);

    [data-whatinput='mouse'] &,
    [data-whatinput='touch'] & {
      outline: 0;
    }

    &:hover,
    &:focus {
      color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  &__button {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;

    &--is-disabled {
      pointer-events: none;
      user-select: none;
    }
  }
}

.stage-product {
  position: relative;
  z-index: get-z-index('stage');
  @include get-grid;

  &__main {
    position: relative;
    grid-column: 1 / span 10;
    grid-row: 1;
    z-index: get-z-index('main', 'stage');
    margin-top: get-space(xxs);

    @include mq(l) {
      min-height: 680px;
      grid-column: 1 / span 12;
      margin-top: get-space(l);
    }

    @include get-grid(
      (
        'default': 10,
        'l': 12
      )
    );

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + #{map-get($containerPadding, 'default')});
      height: 100%;
      margin-left: calc(-1 * #{map-get($containerPadding, 'default')});
      background-color: var(--color-neutral-20);

      @include mq(l) {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  &__main-content {
    position: relative;
    grid-column: 1 / span 8;
    grid-row: 1;
    align-self: center;
    padding: get-space(l) 0 get-space(xl) 0;

    @include mq(l) {
      grid-column: 2 / span 4;
      padding: get-space(xl) get-space(l) get-space(xl) 0;
    }
  }

  &__sub {
    position: relative;
    grid-column: 1 / span 12;
    align-self: start;
    margin-top: -48px;
    z-index: get-z-index('sub', 'stage');

    @include mq(l) {
      grid-column: 8 / span 5;
      grid-row: 1;
      margin-top: 0;
      margin-bottom: get-space(l);
    }

    @include get-grid(
      (
        'default': 12,
        'l': 5
      )
    );

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + #{map-get($containerPadding, 'default')});
      height: 100%;
      margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
      background-color: var(--color-primary);

      @include mq(l) {
        width: calc(100% + #{map-get($containerPadding, 'l')});
        margin-right: calc(-1 * #{map-get($containerPadding, 'l')});
      }
    }
  }

  &__sub-content {
    grid-column: 1 / span 12;
    padding: get-space(l) 0 get-space(l) map-get($containerPadding, 'default');

    @include mq(l) {
      grid-column: 1 / span 5;
      padding: get-space(xl) get-space(m) get-space(xl) get-space(xxl);
    }
  }

  &__image {
    position: absolute;
    min-width: 150px;
    max-width: 262px;
    top: 36px;
    right: -28px;
    z-index: get-z-index('image', 'stage');
    overflow: hidden;

    @include use-susy-span(
      'width',
      (
        l: 3 narrow of 11 narrow
      ),
      (4 narrow of 11 narrow)
    );

    @include mq(l) {
      position: relative;
      grid-column: 6 / span 3;
      grid-row: 1;
      max-width: 100%;
      width: 100%;
      top: 0;
      right: auto;
      left: -48px;
    }

    .image {
      left: auto;
      right: -60px;

      @include mq(l) {
        left: 0;
        right: auto;
      }
    }
  }

  &__meta {
    margin-bottom: get-space(xs);
  }

  &__cta {
    display: inline-block;
    width: auto;
    margin-top: get-space(m);

    @include mq(l) {
      margin-top: get-space(l);
    }
  }

  &__intro {
    position: relative;
    color: var(--color-neutral-10);
    padding-right: 24%;
    @include text-type-subhead-1;

    @include mq(l) {
      padding-right: 0;
    }

    ul {
      list-style: none;
      position: relative;
      @include text-type-copy-1;
    }

    ul li {
      display: block;
      position: relative;
      min-height: get-space(m);
      margin-top: get-space(xs);
      margin-bottom: get-space(xs);
      margin-left: get-space(l);
      color: var(--color-neutral-10);

      &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: -48px;
        width: 36px;
        height: 36px;
      }
    }
  }

  &.theme--green .stage-product__intro ul li:before {
    background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Ccircle fill='%236b817f' cx='18' cy='18' r='18'/%3E%3Cpath d='M25.848 11l1.448 1.45L16.2 23.549l.007.007-1.448 1.449-.008-.007-.007.007-1.448-1.449.006-.007L8 18.244l1.448-1.448 5.303 5.304z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &.theme--orange .stage-product__intro ul li:before {
    background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Ccircle fill='%23ce7655' cx='18' cy='18' r='18'/%3E%3Cpath d='M25.848 11l1.448 1.45L16.2 23.549l.007.007-1.448 1.449-.008-.007-.007.007-1.448-1.449.006-.007L8 18.244l1.448-1.448 5.303 5.304z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &--is-inverted {
    &:before {
      @include mq($until: l) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + #{map-get($containerPadding, 'default')});
        height: 100%;
        margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
        background-color: var(--color-neutral-20);
      }
    }

    .stage-product__main {
      grid-column: 1 / span 10;
      align-self: start;
      margin-top: 0;
      z-index: get-z-index('sub', 'stage');
      color: var(--color-neutral-10);
      @include get-grid(
        (
          'default': 10,
          'l': 5
        )
      );

      @include mq(l) {
        min-height: auto;
        grid-column: 1 / span 5;
        margin-bottom: get-space(l);
      }

      &:before {
        margin-left: calc(-1 * #{map-get($containerPadding, 'default')});
        background-color: var(--color-primary);

        @include mq(l) {
          width: calc(100% + #{map-get($containerPadding, 'l')});
          margin-left: calc(-1 * #{map-get($containerPadding, 'l')});
        }
      }
    }

    .stage-product__main-content {
      grid-column: 1 / span 9;
      // padding: get-space(l) 0 get-space(xl) 0;

      padding: get-space(s) 0 get-space(m) 0;

      @include mq(m) {
        padding: get-space(m) 0 get-space(l) 0;
      }

      @include mq(l) {
        grid-column: 1 / span 5;
        padding: get-space(l) get-space(m) get-space(xl) 0;
      }

      @include mq(xl) {
        padding: get-space(l) get-space(m) get-space(xl);
      }
    }

    .stage-product__sub {
      grid-column: 1 / span 12;
      align-self: stretch;
      padding: get-space(l) 0 get-space(l) map-get($containerPadding, 'default');
      z-index: get-z-index('main', 'stage');

      @include mq(l) {
        grid-column: 1 / span 12;
        margin-bottom: 0;
        padding: 0;
      }

      @include get-grid(
        (
          'default': 12,
          'l': 12
        )
      );

      &:before {
        margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
        background-color: var(--color-neutral-20);

        @include mq(l) {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    .stage-product__sub-content {
      grid-column: 1 / span 12;
      padding: get-space(l) 0 0 map-get($containerPadding, 'default');

      @include mq(l) {
        grid-column: 9 / span 3;
        padding: get-space(xl) 0;
      }
    }

    .stage-product__intro,
    .stage-product__intro ul li {
      color: var(--color-neutral-100);
    }

    &.theme--green .stage-product__intro ul li:before {
      background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Ccircle fill='%23395654' cx='18' cy='18' r='18'/%3E%3Cpath d='M25.848 11l1.448 1.45L16.2 23.549l.007.007-1.448 1.449-.008-.007-.007.007-1.448-1.449.006-.007L8 18.244l1.448-1.448 5.303 5.304z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &.theme--orange .stage-product__intro ul li:before {
      background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Ccircle fill='%23c2542b' cx='18' cy='18' r='18'/%3E%3Cpath d='M25.848 11l1.448 1.45L16.2 23.549l.007.007-1.448 1.449-.008-.007-.007.007-1.448-1.449.006-.007L8 18.244l1.448-1.448 5.303 5.304z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  &:not(.stage-product--has-image) {
    .stage-product__sub-content {
      @include mq(l) {
        grid-column: 7 / span 5;
      }
    }

    .stage-product__intro {
      padding-right: 0;
    }
  }
}

.simple {
  position: relative;
  z-index: get-z-index('simple');

  &__max-width {
    @include max-width;
  }

  &__header {
    .stage-simple__menubar {
      position: relative;
      z-index: get-z-index('menubar', 'simple');
    }
  }

  &__main {
    position: relative;
    z-index: get-z-index('main', 'simple');

    & > .blocks {
      margin-top: 0;

      @include mq(l) {
        margin-top: calc(-1 * #{get-space(xl)});
      }
    }
  }

  &__main > .blocks .accordion--has-grid {
    .accordion__container,
    .accordion__headline {
      @include mq(l) {
        grid-column: 3 / span 8;
      }
    }
  }

  .stage-simple--has-white-overlap ~ &__main {
    .accordion--has-grid .accordion__container {
      @include mq(l) {
        grid-column: 3 / span 8;
      }
    }
  }

  &__top-btn {
    margin-bottom: get-space(m);

    @include mq(m) {
      margin-bottom: get-space(xl);
    }

    svg {
      transform: rotate(180deg);
    }
  }
}

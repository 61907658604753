.top-btn {
  margin-bottom: get-space(m);

  @include mq(m) {
    margin-bottom: get-space(xl);
  }

  svg {
    transform: rotate(180deg);
  }
}

.select {
  position: relative;

  &__select {
    width: 100%;
  }

  &__label {
    display: block;
    color: var(--color-neutral);
    margin-bottom: get-space('xs');
    width: 100%;
  }

  &__phone {
    width: 220px;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid var(--color-neutral);
    padding: get-space('xs') get-space('s');

    &::after {
      grid-area: select;
    }

    // Custom arrow
    &:not(.select--multiple)::after {
      content: '';
      justify-self: end;
      width: 20px;
      height: 20px;
      background-color: var(--color-neutral);
      clip-path: path('M3 7.043L4.05 6 10 11.908 15.949 6 17 7.043l-5.95 5.909.006.005L10.006 14z');
    }
  }

  // custom select
  &__custom {
    width: 100%;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid var(--color-neutral);
    padding: get-space('xs') get-space('s');

    &::after {
      grid-area: select;
    }

    // Custom arrow
    &:not(.select--multiple)::after {
      content: '';
      justify-self: end;
      width: 20px;
      height: 20px;
      background-color: var(--color-neutral);
      clip-path: path('M3 7.043L4.05 6 10 11.908 15.949 6 17 7.043l-5.95 5.909.006.005L10.006 14z');
    }
  }

  // Hide native select (including removing the default dropdown arrow)
  &__custom select {
    appearance: none;
    background-color: transparent;
    color: var(--color-neutral);
    border: none;
    padding: 0 get-space('s') 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    grid-area: select;

    // Stack above custom arrow

    z-index: 1;

    // Remove dropdown arrow in IE10 & IE11
    // @link https://www.filamentgroup.com/lab/select-css.html
    &::-ms-expand {
      display: none;
    }

    // Remove focus outline

    outline: none;
  }

  &__phone select {
    appearance: none;
    background-color: transparent;
    color: var(--color-neutral);
    border: none;
    padding: 0 get-space('s') 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    grid-area: select;

    // Stack above custom arrow

    z-index: 1;

    // Remove dropdown arrow in IE10 & IE11
    // @link https://www.filamentgroup.com/lab/select-css.html
    &::-ms-expand {
      display: none;
    }

    // Remove focus outline

    outline: none;
  }

  // select[multiple] {
  //   padding-right: 0;
  //   /*
  //   * Safari will not reveal an option unless the select height has room to
  //   * show all of it Firefox and Chrome allow showing a partial option
  //   */
  //   height: 6rem;

  //   option {
  //     white-space: normal;

  //     // Only affects Chrome
  //     outline-color: blue;
  //   }
  // }

  // :focus
  &__custom select:focus + &__focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 2px solid var(--color-neutral);
  }

  &--disabled &__custom {
    cursor: default;
    pointer-events: none;
    background-color: var(--color-neutral-10);
    border-color: var(--color-neutral-40);
  }

  &.form-error &__custom {
    outline: 1px solid var(--color-negative);
    border-color: var(--color-negative);
  }

  &__required {
    margin-top: get-space(xxs);
  }

  &.form-error &__required {
    color: var(--color-negative);
  }

  // &__error.text {
  //   display: none;
  //   margin-top: get-space(xxs);
  //   color: var(--color-negative);
  // }

  // &.form-error &__error {
  //   display: block;
  // }

  // option to style native select
  // (right now only used in react component)
  &--native select {
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid var(--color-neutral);
    padding: get-space('xs') get-space('s');
    position: relative;

    @include text-type-copy-1;

    &:focus {
      outline: 1px solid var(--color-neutral);
    }

    &:disabled {
      background-color: var(--color-neutral-10);
      border-color: var(--color-neutral-40);
      color: var(--color-neutral-alpha20);
    }

    &.inxmail-error {
      outline: 1px solid var(--color-negative);
      border: 1px solid var(--color-negative);
    }

    // hide native select styles

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  &--native &__native {
    // show custom arrow
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      bottom: 15px; // TODO how to center better?
      justify-self: end;
      width: 20px;
      height: 20px;
      background-color: var(--color-neutral);
      clip-path: path('M3 7.043L4.05 6 10 11.908 15.949 6 17 7.043l-5.95 5.909.006.005L10.006 14z');
    }
  }

  &--disabled &__custom select {
    color: var(--color-neutral-alpha20);
  }
}

.standard {
  &__stage {
    @include max-width;

    overflow: visible;
  }

  &__max-width {
    @include max-width;
  }
}

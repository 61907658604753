.richtext {
  @include text-type-copy-1;

  &--is-small {
    @include text-type-copy-2;
  }

  &--is-large {
    @include text-type-subhead-1;
  }

  &__wrapper {
    // margin bottom between first level children elements
    & > *:not(:last-child),
    .cookiefirst-cookie-declaration > *:not(:last-child) {
      margin-bottom: get-space(s);
    }
  }

  &__cta {
    display: inline-block;
    margin-top: get-space(m);
    border-bottom: 0 none !important;
  }

  /***** has-grid styles ***************************/
  &--has-grid {
    @include get-grid;
  }

  &--has-grid &__wrapper {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 8;
    }
  }

  &--has-grid.richtext--has-background &__wrapper {
    background-color: var(--color-neutral-20);
    padding: get-space(m) get-space(s);

    @include mq(l) {
      grid-column: 3 / span 8;
      padding: get-space(l) 13%;
    }
  }

  /***** headline styles ***************************/
  h2 {
    @include text-type-headline-2;
  }

  h3 {
    @include text-type-headline-3;
  }

  a {
    border-bottom: 1px solid;
    padding-bottom: 1px;
  }

  /***** paragraph styles **************************/
  p {
    &.is-overline {
      @include text-type-overline;

      margin-bottom: get-space(xs);
    }

    &.is-highlight {
      color: var(--color-primary);
    }

    strong {
      font-weight: 700;
    }

    em {
      font-style: italic;
    }
  }

  /***** list styles ******************************/

  // unordered lists
  ul {
    color: var(--color-primary);
    list-style: disc;
    list-style-position: inside;
  }

  // resets for modifier "neutral"
  &--neutral ul {
    color: inherit;
  }

  ul ul {
    list-style: circle;
    list-style-position: inside;
  }

  li {
    strong {
      font-weight: 700;
    }

    em {
      font-style: italic;
    }
  }

  ul li {
    list-style-position: outside;
    margin-left: 17px;
    padding-left: 3px;
    color: var(--color-neutral);

    &::marker {
      color: var(--color-primary);
    }
  }

  // resets for modifier "neutral"
  &--neutral ul li::marker {
    color: inherit;
  }

  // margin top between list items
  li > ul,
  li + li {
    margin-top: get-space(xs);
  }

  // ordered lists
  ol {
    position: relative;
    counter-reset: item;
    padding-left: 24px;
  }

  ol li:before {
    position: absolute;
    left: 0;
    font-weight: 700;
    margin-right: get-space(xs);
  }

  // margin top between list items
  li > ol {
    margin-top: get-space(xs);
  }

  ol > li {
    counter-increment: item;

    &::before {
      color: var(--color-primary);
      content: counters(item, '') '.';
    }
  }

  // resets for modifier "neutral"
  &--neutral ol > li:before {
    color: inherit;
  }

  ol ol {
    padding-left: 40px;
  }

  ol ol ol {
    padding-left: 56px;
  }

  ol ol ol ol {
    padding-left: 72px;
  }

  ol ol > li {
    display: block;

    &::before {
      content: counters(item, '.') '.';
    }
  }

  // indentation of nested lists
  ul ul li {
    margin-left: get-space(s);
  }

  /***** hr styles **************************/
  hr {
    display: block;
    border: 0 none;
    height: 1px;
    border-top: 1px solid var(--color-neutral-40);
    margin: 0;
    margin-bottom: get-space(s);
    position: relative;
  }
}

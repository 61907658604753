.teaser-news {
  position: relative;
  @include get-grid();

  /***************  CONTENT  ***************/
  &__content {
    position: relative;
    grid-column: 1 / span 10;
    margin-left: calc(-1 * #{map-get($containerPadding, 'default')});
    padding: get-space(l) 0 78px #{map-get($containerPadding, 'default')};
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    @include get-grid(
      (
        default: 10,
        l: 6
      )
    );

    @include mq(m) {
      padding: get-space(m) get-space(s) get-space(xl) #{map-get($containerPadding, 'default')};
    }

    @include mq(l) {
      grid-column: 1 / span 5;
      margin-left: calc(-1 * #{map-get($containerPadding, 'l')});
      padding: 132px 36px 132px map-get($containerPadding, 'l');
    }

    @include mq(xl) {
      padding: 132px 36px 132px 96px;
    }

    &--is-stage {
      padding-top: get-space(s);
      padding-bottom: get-space(xxl);

      @include mq(m) {
        padding-bottom: get-space(xxl);
      }

      @include mq(l) {
        padding-top: get-space(l);
      }

      @include mq(xl) {
        padding-top: get-space(l);
      }
    }

    &--has-menubar {
      z-index: 1;
      padding: get-space(l) 0 get-space(l) #{map-get($containerPadding, 'default')};

      @include mq(m) {
        padding: get-space(m) get-space(s) get-space(m) #{map-get($containerPadding, 'default')};
      }
    }
  }

  &--is-news-blog {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: get-space(xs);

    .teaser-news__tabs {
      width: 100%;
    }

    .teaser {
      border-bottom: 1px solid var(--color-neutral-20);
    }

    .teaser:hover {
      background-color: var(--color-neutral-20);
    }

    .teaser-news__content {
      display: none;
    }

    .teaser__image-wrapper {
      position: relative;
      width: 100%;
      padding-top: 100%; /* 1:1 Aspect Ratio */
      overflow: hidden;
      margin-top: 0;

      img {
        object-fit: cover;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .teaser__icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40px;
        aspect-ratio: 1/1;
        background: white;
        padding: 10px;
      }
    }

    p.text--headline-3 {
      font-weight: bold;
      overflow: hidden;
      display: -webkit-box;
      height: 95px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

      @include mq(s) {
        height: 95px;
      }
      @include mq(l) {
        height: 105px;
      }
    }

    p.teaser__copy {
      margin-top: auto;
      padding-top: get-space(xs);
    }
  }

  &__teaser-blog {
    display: flex;
    height: 100%;
    border: 1px solid var(--color-neutral-20);
    flex: 1 1 calc(100% - #{get-space(xs)});
    gap: get-space(xs);
    padding: get-space(s);

    @include mq(s) {
      flex: 1 1 calc(50% - #{get-space(xs)});
    }

    @include mq(m) {
      flex: 1 1 calc(33.333% - #{get-space(xs)});
    }
  }

  &--is-fund-detail &__content {
    background-color: transparent;
    color: var(--color-neutral);
    grid-column: 1 / span 10;
    padding-top: 0;
    padding-right: 0;

    @include get-grid(
      (
        default: 10,
        l: 6
      )
    );

    @include mq(l) {
      grid-column: 2 / span 5;
      margin-left: 0;
      padding: 0 36px 132px 0;
    }
  }

  &__overline,
  &__headline,
  &__link {
    grid-column: 1 / span 9;

    @include mq(m) {
      grid-column: 1 / span 7;
    }

    @include mq(l) {
      grid-column: 1 / span 5;
    }
  }

  &__overline {
    margin-bottom: get-space(xs);
  }

  &__link {
    margin-top: get-space(s);

    @include mq(l) {
      margin-top: get-space(m);
    }
  }

  &--is-fund-detail &__overline,
  &--is-fund-detail &__headline,
  &--is-fund-detail &__link {
    grid-column: 1 / span 10;

    @include mq(m) {
      grid-column: 1 / span 7;
    }

    @include mq(l) {
      grid-column: 1 / span 5;
    }
  }

  &--is-fund-detail &__link {
    margin-top: get-space(s);
  }

  &__menubar,
  &__cta {
    margin-top: get-space(s);
    grid-column: 1 / span 9;

    @include mq(s) {
      grid-column: 1 / span 7;
    }

    @include mq(m) {
      grid-column: 1 / span 5;
    }

    @include mq(l) {
      margin-top: get-space(l);
      grid-column: 1 / span 4;
    }
  }

  &--is-fund-detail &__menubar {
    .menubar__dropdown {
      background-color: var(--color-neutral-10);
      border: 1px solid var(--color-neutral-90);

      // Custom arrow
      &:after {
        background-color: var(--color-neutral-90);
      }
    }

    .menubar__label {
      color: var(--color-neutral-90);
    }
  }

  /***************  TEASER  ***************/
  &__teaser {
    align-self: start;
    grid-column: 2 / span 10;
    margin-bottom: get-space(s);
    border-bottom: 1px solid var(--color-neutral-70);
    transition: border-color ease-in-out 0.1s;
    @include animatableUnderline(var(--color-accent-1));

    &:hover {
      border-color: transparent;

      &::after {
        width: 100%;
      }
    }

    @include mq(l) {
      grid-column: 2 / span 9;
    }
  }

  &__teaser--1,
  &__teaser--2,
  &__teaser--3 {
    margin-bottom: get-space(m);

    @include mq(l) {
      margin-bottom: get-space(xl);
    }
  }

  &__teaser--1 {
    grid-column: 3 / span 10;
    margin-top: calc(-1 * #{get-space(l)});

    @include mq(s) {
      grid-column: 5 / span 8;
    }

    @include mq(l) {
      grid-column: 6 / span 5;
      grid-row: 2;
      margin-top: -266px;
      margin-right: 0;
      margin-left: calc(-1 * #{get-space(l)});
      padding-right: 0;
    }
  }

  &__teaser--2 {
    grid-column: 1 / span 10;

    @include mq(s) {
      grid-column: 1 / span 7;
    }

    @include mq(l) {
      grid-column: 1 / span 3;
      grid-row: 2 / span 2;
      margin-top: calc(-1 * #{get-space(l)});
    }
  }

  &__teaser--3 {
    grid-column: 3 / span 10;

    @include mq(s) {
      grid-column: 5 / span 7;
    }

    @include mq(l) {
      grid-column: 4 / span 3;
      margin-left: get-space(l);
      margin-right: calc(-1 * #{get-space(l)});
    }
  }

  &__teaser--4 {
    margin-top: get-space(m);
  }

  &--is-fund-detail &__teaser--1 {
    @include mq(l) {
      margin-top: -160px;
      grid-column: 7 / span 4;
    }
  }

  &--is-fund-detail &__teaser--2 {
    @include mq(l) {
      grid-column-start: 2;
    }
  }

  &--is-fund-detail &__teaser--3 {
    @include mq(l) {
      grid-column-start: 5;
    }
  }

  // content has menubar
  &__content--has-menubar ~ &__teaser--1 {
    @include mq($until: l) {
      margin-top: get-space(m);
    }

    @include mq(l) {
      z-index: 1;
    }
  }

  &__content--has-menubar ~ &__teaser--2 {
    @include mq(l) {
      margin-top: get-space(l);
    }
  }

  /***************  PORTRAIT IMAGE TEASER ***************/
  &__teaser--1.teaser--has-portrait-image {
    @include mq(l) {
      grid-column: 6 / span 4;
    }
  }

  &--is-fund-detail &__teaser--1.teaser--has-portrait-image {
    @include mq(l) {
      grid-column: 7 / span 4;
    }
  }

  /***************  TEASER WITH BACKGROUND (NO IMAGE) ***************/
  &__teaser--1.teaser--has-background,
  &__teaser--2.teaser--has-background,
  &__teaser--3.teaser--has-background {
    padding: get-space(s) get-space(s);
    background: var(--color-neutral-20);

    @include mq(s) {
      padding: get-space(l) get-space(m);
    }

    .teaser__overline,
    .teaser__headline,
    .teaser__copy {
      @include mq($until: l) {
        padding-right: 0;
      }
    }
  }

  &__teaser--1.teaser--has-background {
    @include mq(l) {
      padding: get-space(xxl) get-space(l);
    }
  }

  &__teaser--2.teaser--has-background,
  &__teaser--3.teaser--has-background {
    @include mq(l) {
      padding: get-space(xl) get-space(m);
    }
  }

  &__teaser--3.teaser--has-background {
    @include mq(l) {
      grid-column: 4 / span 4;
    }
  }

  &__teaser--2.teaser--has-background {
    @include mq(l) {
      grid-column: 1 / span 4;
    }

    + .teaser {
      @include mq(l) {
        grid-column: 5 / span 3;
      }
    }

    + .teaser--has-background {
      @include mq(l) {
        grid-column: 5 / span 4;
      }
    }
  }

  &--is-fund-detail &__teaser--3.teaser--has-background {
    @include mq(l) {
      grid-column: 5 / span 4;
    }
  }

  &--is-fund-detail &__teaser--2.teaser--has-background {
    @include mq(l) {
      grid-column: 2 / span 4;
    }

    + .teaser {
      @include mq(l) {
        grid-column: 6 / span 3;
      }
    }

    + .teaser--has-background {
      @include mq(l) {
        grid-column: 6 / span 4;
      }
    }
  }

  // MOBILE IMAGES
  .teaser__image-wrapper {
    @include get-grid(
      (
        default: 10,
        s: 7
      )
    );

    @include mq(l) {
      display: block;
    }
  }

  &__teaser--1 .teaser__image-wrapper {
    @include get-grid(
      (
        default: 10,
        s: 8
      )
    );

    @include mq(l) {
      display: block;
    }
  }

  .teaser--has-portrait-image {
    .image {
      grid-column: 1 / span 5;

      @include mq(l) {
        display: block;
      }
    }
  }

  .teaser--has-landscape-image {
    .image {
      grid-column: 1 / span 7;
    }
  }

  &__teaser--1.teaser--has-landscape-image {
    .image {
      grid-column: 1 / span 9;

      @include mq(s) {
        grid-column: 1 / span 7;
      }
    }
  }

  /***************  TABS ***************/
  &__tabs .blocks {
    display: flex;
    flex-wrap: wrap;
    gap: get-space(xs);
    padding-bottom: get-space(s);
  }

  &__tabs .blocks__block {
    flex: 1 1 calc(100% - #{get-space(xs)});
    margin-top: 0;
    margin-bottom: 0;

    @include mq(m) {
      max-width: calc(33.333% - #{get-space(xs)});
      flex: 1 1 calc(33.333% - #{get-space(xs)});
    }
    @include mq(s) {
      flex: 1 1 calc(50% - #{get-space(xs)});
    }
  }

  /***************  PAGINATION ***************/
  &__pagination {
    display: flex;
    justify-content: flex-end;
    margin-bottom: get-space(xl);
    margin-top: get-space(m);
    width: 100%;

    @include use-susy-span(
      'width',
      (
        l: 9 narrow
      ),
      (10 narrow)
    );

    @include use-susy-span(
      'margin-left',
      (
        l: 1 wide
      ),
      (1 wide)
    );
  }
}

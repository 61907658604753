$overlapAreaMobile: 200px;
$overlapAreaDesktop: 250px;

.funds {
  &__max-width {
    @include max-width;
  }

  &__stage {
    position: relative;
    z-index: get-z-index('stage');
    @include get-grid;
  }

  // main: grid dimensions
  &__stage-main {
    z-index: get-z-index('main', 'stage');
    grid-column: 1 / span 10;

    // move to front on desktop
    @include mq(l) {
      position: relative;
      grid-column: 1 / span 5;
    }
  }

  // main: background wrapper
  &__stage-main-bg {
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    margin-left: calc(-1 * #{map-get($containerPadding, 'default')});
    min-height: $overlapAreaMobile;

    @include mq(l) {
      margin-left: calc(-1 * #{map-get($containerPadding, 'l')});
      min-height: $overlapAreaDesktop;
    }
  }

  // main: inner margin & padding
  &__stage-main-content {
    margin-left: map-get($containerPadding, 'default');
    padding: get-space(l) get-space(s) get-space(l) 0;

    @include mq(l) {
      margin-left: map-get($containerPadding, 'l');
      padding: get-space(l) get-space(m) get-space(xl) get-space(s);
    }

    @include mq(xl) {
      padding: get-space(l) get-space(l) get-space(xl) get-space(m);
    }
  }

  // sub: grid dimensions
  &__stage-sub {
    grid-column: 1 / span 12;
    margin-top: calc(-1 * #{$overlapAreaMobile});

    @include mq(l) {
      grid-column: 2 / span 11;
      margin-top: calc(-1 * #{$overlapAreaDesktop});
    }
  }

  // sub: background wrapper
  &__stage-sub-bg {
    background-color: var(--color-neutral-20);
    margin-right: calc(-1 * #{map-get($containerPadding, 'default')});

    @include mq(l) {
      margin-right: 0;
      min-height: calc(#{get-space(m)} + #{$overlapAreaDesktop});
    }
  }

  // sub: grid
  &__stage-sub-grid {
    padding-top: $overlapAreaMobile;
    @include get-grid(
      (
        'default': 12,
        'l': 11
      )
    );

    @include mq(l) {
      padding-top: get-space(m);
      position: relative;
    }
  }

  // sub: inner margin & padding
  &__stage-sub-content {
    margin-right: map-get($containerPadding, 'default');
    padding-bottom: get-space(m);

    @include mq(l) {
      margin-right: 0;
      padding-bottom: get-space(xl);
    }
  }

  // spacer line
  &__stage-spacer {
    border: 0 none;
    height: 0;
    margin: 0;
    margin-top: get-space(m);
    position: relative;
    grid-column: 1 / span 1;

    @include mq(l) {
      grid-column: 5 / span 1;
      margin-top: 0;
    }

    &::before {
      background: var(--color-neutral);
      content: '';
      display: block;
      height: 1px;
      margin: get-space(l) auto 0;
      margin-top: 12px;
      position: absolute;
      right: get-space(xxs);
      width: 30px;

      @include mq(s) {
        width: 40px;
        right: get-space(xs);
      }

      @include mq(m) {
        right: get-space(s);
      }

      @include mq(l) {
        margin-top: 15px;
        position: relative;
        right: auto;
      }
    }
  }

  // intro grid dimensions
  &__stage-intro {
    margin-top: get-space(m);
    grid-column: 2 / span 11;

    @include mq(l) {
      margin-top: 0;
      grid-column: 6 / span 5;
    }
  }

  &__stage-intro-text {
    @include text-type-subhead-1;
  }

  &__stage-contact {
    margin-top: get-space(m);
    background-color: var(--color-neutral-20);

    @include mq(l) {
      display: inline-flex;
      width: auto;
    }
  }

  &__stage-cta-profile {
    display: inline-block;
    margin-top: get-space(s);

    @include mq(l) {
      margin-top: get-space(m);
    }
  }

  .richtext h2 {
    color: var(--color-primary);
    display: inline;
    @include text-type-headline-1;
  }

  .accordion {
    @include mq(m) {
      margin-top: -36px;
    }
  }

  .accordion__container {
    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }
}

.location {
  position: relative;
  z-index: get-z-index('location');

  &__wrapper {
    width: 100%;
  }

  &__header {
    text-align: center;
    padding-bottom: get-space(xxs);
    @include get-grid;
  }

  &__headline,
  &__navigation {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 3 / span 8;
    }
  }

  &__navigation {
    ul {
      display: flex;
      justify-content: center;
    }

    li {
      padding: get-space(xs);

      @include mq(l) {
        padding: 0 get-space(xs) get-space(xs);
      }
    }
  }

  &__navigation--right {
    @include mq(l) {
      grid-column: 4 / span 9;
    }

    ul {
      justify-content: flex-end;
    }

    li:last-child {
      @include mq(l) {
        padding-right: 0;
      }
    }
  }

  &__switch {
    display: inline;
    color: var(--color-neutral);
    transition: color ease-in-out 0.1s;

    &:hover {
      color: var(--color-accent-1);
    }

    &--active {
      border-bottom: 2px solid var(--color-accent-1);
    }
  }

  &__content {
    @include get-grid;

    grid-template-rows: auto 1fr;
  }

  &__information {
    position: relative;
    z-index: get-z-index('information', 'location');
    grid-column: 1 / span 12;
    margin-top: -15px;

    @include mq(s) {
      // margin-top: calc(-1 * #{get-space(m)});
    }

    @include mq(l) {
      grid-row: 1 / span 2;
      margin-top: 0;
      padding-top: calc(#{get-space(xxl)});
      grid-column: 1 / span 5;
    }
  }

  &__information-inner {
    padding: get-space(s) get-space(s) get-space(xl);
    background-color: var(--color-primary);
    color: var(--color-neutral-10);
    margin-bottom: calc(-1 * #{get-space(m)});

    @include mq(s) {
      padding: get-space(l) get-space(l) get-space(xl);
    }

    @include mq(l) {
      min-height: 300px;
    }

    @include mq(xl) {
      min-height: 400px;
      padding: get-space(l) get-space(xxl);
    }

    h3 {
      margin-bottom: get-space(xs);

      &:not(:first-child) {
        margin-top: get-space(s);
      }
    }

    a.link {
      color: var(--color-neutral-10);
      transition: color ease-in-out 0.1s;
    }

    .text--subhead-1 {
      font-weight: normal;
    }
  }

  &__route-link {
    margin-top: get-space(m);

    @include mq(l) {
      text-align: left;
      display: inline-block;
    }
  }

  &__map {
    grid-row: 1;
    grid-column: 1 / span 12;
    padding-top: get-space(xs);
    margin-right: calc(-1 * #{map-get($containerPadding, 'default')});
    margin-left: -100px; // to center map more for mobile
    overflow: hidden;

    @include mq(s) {
      margin-left: -150px; // to center map more for mobile
    }

    @include mq(l) {
      margin-right: 0;
      grid-column: 6 / span 12;
      margin-left: -130px;
      overflow: visible;
    }

    @include mq(xl) {
      margin-left: -160px;
    }
  }

  &__map-wrapper {
    position: relative;
  }

  &__map-svg {
    width: 100%;
    height: auto;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__pin {
    position: absolute;
    transform: translate(-50%, -100%);
    padding: 0;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      z-index: get-z-index('pin', 'location');
      bottom: -5px;
      width: 40px;
      height: 10px;
      // background: url('./pin_shadow.png');
      // background-position: center;
      // background-size: contain;
    }

    svg {
      width: 20px;

      @include mq(s) {
        width: 30px;
      }
    }

    &--active {
      z-index: get-z-index('active', 'location');

      svg {
        width: 25px;

        @include mq(s) {
          width: 50px;
        }
      }
    }
  }

  &__pin:hover {
    z-index: get-z-index('hover', 'location');
  }

  &__pin--button {
    cursor: pointer;
    pointer-events: auto;
    border: 0 none;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease-in-out 0.1s, border-color ease-in-out 0.1s, color ease-in-out 0.1s;

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }
  }

  &__tooltip {
    position: absolute;
    display: block;
    width: 120px;
    left: 40px;
    padding: 12px;
    background: white;
    font-weight: bold;
    opacity: 0;
    pointer-events: none;
    z-index: get-z-index('tooltip', 'location');
    transition: opacity ease-in-out 0.2s;

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
  }

  &__pin--button:hover &__tooltip {
    // hover only with bigger screens
    @include mq(s) {
      opacity: 1;
    }
  }

  &__image {
    grid-column: 5 / span 9;
    z-index: get-z-index('image', 'location');
    position: relative;
    margin-right: calc(-1 * #{map-get($containerPadding, 'default')});

    @include mq(l) {
      grid-row: 2;
      grid-column: 6 / span 3;
      margin-right: 0;
      margin-top: -50px;
      margin-left: -40px;
    }
  }
}
